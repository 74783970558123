import { styled } from 'styled-components';
import { t } from 'i18next';

interface SogeiStatusProps {
    status: number;
}
const StatusDiv = styled.div`
    display: flex;
    align-items: center;
`;

const SogeiStatus = (props: SogeiStatusProps) => {
    const { status } = props;

    const renderStatus = () => {
        switch (status) {
            case 7:
                return <>{t('sendFiles.sogeiSevenStatus')}</>;
            case 8:
                return <>8 - OK</>;
            case 9:
                return <>9 - ERR</>;
            default:
                return <></>;
        }
    };

    return <StatusDiv>{renderStatus()}</StatusDiv>;
};

export default SogeiStatus;
