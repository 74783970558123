import {} from '../OverviewTable/OverviewOrderTableDiab';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { IClmTotalsDiab, IOverviewDiab, IOverviewDiabPercentage, IPercTotalsDiab } from '../../model';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { getCurrentMonthAndYear } from '../../utils';
import { t } from 'i18next';

interface IExportExcelDiabProps {
    currentYearRecs: IOverviewDiab[];
    lastYearRecs: IOverviewDiab[];
    percIndexes: IOverviewDiabPercentage[];
    currentClmTotals: IClmTotalsDiab;
    lastYearClmTotals: IClmTotalsDiab;
    percClmTotals: IPercTotalsDiab;
    orderName: string;
}

const ExportExcelDiab = (props: IExportExcelDiabProps) => {
    const { currentYearRecs, lastYearRecs, percIndexes, currentClmTotals, lastYearClmTotals, percClmTotals, orderName } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = `DIAB-Overview-${orderName}`;

    const exportToExcel = () => {
        const emptyRow = () => {
            return {
                [t('genericOverview.clmYear')]: '',
                [t('genericOverview.hospital')]: '',
                [t('months.0')]: '',
                [t('months.1')]: '',
                [t('months.2')]: '',
                [t('months.3')]: '',
                [t('months.4')]: '',
                [t('months.5')]: '',
                [t('months.6')]: '',
                [t('months.7')]: '',
                [t('months.8')]: '',
                [t('months.9')]: '',
                [t('months.10')]: '',
                [t('months.11')]: '',
                [t('genericOverview.totalVisits')]: '',
                [t('genericOverview.numOfPatients')]: '',
                [t('genericOverview.avaPerPatient')]: '',
            };
        };

        const titleRow = () => {
            return {
                [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                [t('genericOverview.hospital')]: t('genericOverview.hospital'),
                [t('months.0')]: t('months.0'),
                [t('months.1')]: t('months.1'),
                [t('months.2')]: t('months.2'),
                [t('months.3')]: t('months.3'),
                [t('months.4')]: t('months.4'),
                [t('months.5')]: t('months.5'),
                [t('months.6')]: t('months.6'),
                [t('months.7')]: t('months.7'),
                [t('months.8')]: t('months.8'),
                [t('months.9')]: t('months.9'),
                [t('months.10')]: t('months.10'),
                [t('months.11')]: t('months.11'),
                [t('genericOverview.totalVisits')]: t('genericOverview.totalVisits'),
                [t('genericOverview.numOfPatients')]: t('genericOverview.numOfPatients'),
                [t('genericOverview.avaPerPatient')]: t('genericOverview.avaPerPatient'),
            };
        };

        const mapRow = (row: IOverviewDiab, year: string) => ({
            [t('genericOverview.clmYear')]: year,
            [t('genericOverview.hospital')]: row.institute,
            [t('months.0')]: row.jan,
            [t('months.1')]: row.feb,
            [t('months.2')]: row.mar,
            [t('months.3')]: row.apr,
            [t('months.4')]: row.may,
            [t('months.5')]: row.jun,
            [t('months.6')]: row.jul,
            [t('months.7')]: row.aug,
            [t('months.8')]: row.sep,
            [t('months.9')]: row.oct,
            [t('months.10')]: row.nov,
            [t('months.11')]: row.dec,
            [t('genericOverview.totalVisits')]: row.totaleVisite,
            [t('genericOverview.numOfPatients')]: row.numeroPazienti,
            [t('genericOverview.avaPerPatient')]: row.mediaVisitePaziente,
        });

        const finalRows = [
            ...currentYearRecs.map(item => mapRow(item, `${getCurrentMonthAndYear().currentYear}`)),
            {
                [t('genericOverview.clmYear')]: `${getCurrentMonthAndYear().currentYear}`,
                [t('genericOverview.hospital')]: t('generic.total'),
                [t('months.0')]: currentClmTotals.jan,
                [t('months.1')]: currentClmTotals.feb,
                [t('months.2')]: currentClmTotals.mar,
                [t('months.3')]: currentClmTotals.apr,
                [t('months.4')]: currentClmTotals.may,
                [t('months.5')]: currentClmTotals.jun,
                [t('months.6')]: currentClmTotals.jul,
                [t('months.7')]: currentClmTotals.aug,
                [t('months.8')]: currentClmTotals.sep,
                [t('months.9')]: currentClmTotals.oct,
                [t('months.10')]: currentClmTotals.nov,
                [t('months.11')]: currentClmTotals.dec,
                [t('genericOverview.totalVisits')]: currentClmTotals.totaleVisite,
                [t('genericOverview.numOfPatients')]: currentClmTotals.numeroPazienti,
                [t('genericOverview.avaPerPatient')]: currentClmTotals.mediaVisitePaziente,
            },
            emptyRow(),
            titleRow(),
            ...lastYearRecs.map(item => mapRow(item, `${getCurrentMonthAndYear().currentYear - 1}`)),
            {
                [t('genericOverview.clmYear')]: `${getCurrentMonthAndYear().currentYear - 1}`,
                [t('genericOverview.hospital')]: t('generic.total'),
                [t('months.0')]: lastYearClmTotals.jan,
                [t('months.1')]: lastYearClmTotals.feb,
                [t('months.2')]: lastYearClmTotals.mar,
                [t('months.3')]: lastYearClmTotals.apr,
                [t('months.4')]: lastYearClmTotals.may,
                [t('months.5')]: lastYearClmTotals.jun,
                [t('months.6')]: lastYearClmTotals.jul,
                [t('months.7')]: lastYearClmTotals.aug,
                [t('months.8')]: lastYearClmTotals.sep,
                [t('months.9')]: lastYearClmTotals.oct,
                [t('months.10')]: lastYearClmTotals.nov,
                [t('months.11')]: lastYearClmTotals.dec,
                [t('genericOverview.totalVisits')]: lastYearClmTotals.totaleVisite,
                [t('genericOverview.numOfPatients')]: lastYearClmTotals.numeroPazienti,
                [t('genericOverview.avaPerPatient')]: lastYearClmTotals.mediaVisitePaziente,
            },
            emptyRow(),
            titleRow(),
            ...percIndexes.map(row => ({
                [t('genericOverview.clmYear')]: `${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1}`,
                [t('genericOverview.hospital')]: row.institute,
                [t('months.0')]: row.jan,
                [t('months.1')]: row.feb,
                [t('months.2')]: row.mar,
                [t('months.3')]: row.apr,
                [t('months.4')]: row.may,
                [t('months.5')]: row.jun,
                [t('months.6')]: row.jul,
                [t('months.7')]: row.aug,
                [t('months.8')]: row.sep,
                [t('months.9')]: row.oct,
                [t('months.10')]: row.nov,
                [t('months.11')]: row.dec,
                [t('genericOverview.totalVisits')]: row.totaleVisite,
                [t('genericOverview.numOfPatients')]: row.numeroPazienti,
                [t('genericOverview.avaPerPatient')]: row.mediaVisitePaziente,
            })),
            {
                [t('genericOverview.clmYear')]: `${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1}`,
                [t('genericOverview.hospital')]: t('generic.total'),
                [t('months.0')]: percClmTotals.jan,
                [t('months.1')]: percClmTotals.feb,
                [t('months.2')]: percClmTotals.mar,
                [t('months.3')]: percClmTotals.apr,
                [t('months.4')]: percClmTotals.may,
                [t('months.5')]: percClmTotals.jun,
                [t('months.6')]: percClmTotals.jul,
                [t('months.7')]: percClmTotals.aug,
                [t('months.8')]: percClmTotals.sep,
                [t('months.9')]: percClmTotals.oct,
                [t('months.10')]: percClmTotals.nov,
                [t('months.11')]: percClmTotals.dec,
                [t('genericOverview.totalVisits')]: percClmTotals.totaleVisite,
                [t('genericOverview.numOfPatients')]: percClmTotals.numeroPazienti,
                [t('genericOverview.avaPerPatient')]: percClmTotals.mediaVisitePaziente,
            },
        ];

        const ws = XLSX.utils.json_to_sheet(finalRows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{`${t('generic.downloadExcel', { flow: 'DIAB' })} - ${orderName}`}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelDiab;
