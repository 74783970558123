import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ExcelSvg from '../Svg/ExcelSvg';
import { IOverviewFarm } from '../../model';
import { IconBtn } from '../Buttons/Buttons';
import { sortMeasureByInstitute } from '../../utils';
import { t } from 'i18next';

interface ExportExcelFarmProps {
    overviewTableRows: IOverviewFarm[];
}

const ExportExcelFarm = (props: ExportExcelFarmProps) => {
    const { overviewTableRows } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'FARM-Overview';

    const exportToExcel = () => {
        const emptyRow = () => {
            return {
                [t('genericOverview.clmYear')]: '',
                [t('generic.istitute')]: '',
                [t('genericOverview.measureOrder')]: '',
                [t('genericOverview.type')]: '',
                [t('genericOverview.monthTotal')]: '',
                [t('genericOverview.previousMonthsMean')]: '',
                [t('genericOverview.percentage')]: '',
            };
        };

        const titleRow = () => {
            return {
                [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                [t('generic.istitute')]: t('generic.istitute'),
                [t('genericOverview.measureOrder')]: t('genericOverview.measureOrder'),
                [t('genericOverview.type')]: t('genericOverview.type'),
                [t('genericOverview.monthTotal')]: t('genericOverview.monthTotal'),
                [t('genericOverview.previousMonthsMean')]: t('genericOverview.previousMonthsMean'),
                [t('genericOverview.percentage')]: t('genericOverview.percentage'),
            };
        };

        const finalRows = [
            ...overviewTableRows.map(row =>
                sortMeasureByInstitute(row.contatti).map(contatto => ({
                    [t('genericOverview.clmYear')]: row.year,
                    [t('generic.istitute')]: contatto.institute,
                    [t('genericOverview.measureOrder')]: t('genericOverview.contacts'),
                    [t('genericOverview.type')]: contatto.typeErogazione,
                    [t('genericOverview.monthTotal')]: contatto.totMese,
                    [t('genericOverview.previousMonthsMean')]: contatto.totMedioMesiPrecedenti,
                    [t('genericOverview.percentage')]: contatto.percentuale,
                })),
            ),
            emptyRow(),
            titleRow(),
            ...overviewTableRows.map(row =>
                sortMeasureByInstitute(row.confezioni).map(confezione => ({
                    [t('genericOverview.clmYear')]: row.year,
                    [t('generic.istitute')]: confezione.institute,
                    [t('genericOverview.measureOrder')]: t('genericOverview.packaging'),
                    [t('genericOverview.type')]: confezione.typeErogazione,
                    [t('genericOverview.monthTotal')]: confezione.totMese,
                    [t('genericOverview.previousMonthsMean')]: confezione.totMedioMesiPrecedenti,
                    [t('genericOverview.percentage')]: confezione.percentuale,
                })),
            ),
            emptyRow(),
            titleRow(),
            ...overviewTableRows.map(row =>
                sortMeasureByInstitute(row.spesa).map(spesa => ({
                    [t('genericOverview.clmYear')]: row.year,
                    [t('generic.istitute')]: spesa.institute,
                    [t('genericOverview.measureOrder')]: t('genericOverview.consume'),
                    [t('genericOverview.type')]: spesa.typeErogazione,
                    [t('genericOverview.monthTotal')]: spesa.totMese,
                    [t('genericOverview.previousMonthsMean')]: spesa.totMedioMesiPrecedenti,
                    [t('genericOverview.percentage')]: spesa.percentuale,
                })),
            ),
            emptyRow(),
            ...overviewTableRows.map(row => ({
                [t('genericOverview.clmYear')]: 'Numero di Prezzi Farmaco del mese caricati:',
                [t('generic.istitute')]: row.nrFarmaci,
                [t('genericOverview.measureOrder')]: '',
                [t('genericOverview.type')]: '',
                [t('genericOverview.monthTotal')]: '',
                [t('genericOverview.previousMonthsMean')]: '',
                [t('genericOverview.percentage')]: '',
            })),
        ];

        const ws = XLSX.utils.json_to_sheet(finalRows.flat());
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: 'FARM' })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelFarm;
