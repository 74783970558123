import { Container, ContainerNoGap, RightTd, RightTh, TableRow } from '../../style';
import { getCurrentMonthAndYear, sortMeasureByInstitute } from '../../utils';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelFarm from '../ExportExcel/ExportExcelFarm';
import GenericError from '../GenericError/GenericError';
import { IOverviewFarm } from '../../model';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { styled } from 'styled-components';
import { t } from 'i18next';
import { useContext } from 'react';
import { useFarmOverview } from '../../api/fetch';

const RightAlignedDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const OverviewFarm = () => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useFarmOverview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [] as IOverviewFarm[]);

    const filterObservationsCurrentAndLastYear = (obs: IOverviewFarm[]) => {
        if (Array.isArray(obs) && obs.length > 0) {
            const filtered = obs.filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear || Number(x.year) === getCurrentMonthAndYear().currentYear - 1);
            return filtered;
        }

        return [];
    };

    const currAndLastYearData = filterObservationsCurrentAndLastYear(overviewTableRows);

    const clms = [
        { name: t('genericOverview.clmYear'), id: 1 },
        { name: t('generic.istitute'), id: 2 },
        { name: t('genericOverview.measureOrder'), id: 3 },
        { name: t('genericOverview.type'), id: 4 },
        { name: t('genericOverview.monthTotal'), id: 5 },
        { name: t('genericOverview.previousMonthsMean'), id: 6 },
        { name: t('genericOverview.percentage'), id: 7 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-farm' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                <RightAlignedDiv>{overview.permissionSet.canExportOverview ? <ExportExcelFarm overviewTableRows={currAndLastYearData} /> : <></>}</RightAlignedDiv>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                currAndLastYearData.length > 0 &&
                                currAndLastYearData.map(row =>
                                    sortMeasureByInstitute(row.contatti).map((contatto, i) => (
                                        <TableRow key={i}>
                                            <td>{row.year}</td>
                                            <RightTd>{contatto.institute}</RightTd>
                                            <RightTd>{t('genericOverview.contacts')}</RightTd>
                                            <RightTd>{contatto.typeErogazione}</RightTd>
                                            <RightTd>{contatto.totMese.toLocaleString('DE-de')}</RightTd>
                                            <RightTd>{contatto.totMedioMesiPrecedenti.toLocaleString('DE-de')}</RightTd>
                                            <RightTd>{contatto.percentuale.toLocaleString('DE-de')}</RightTd>
                                        </TableRow>
                                    )),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <div className='tableFixHead'>
                <Table size='xl'>
                    <thead className='sticky'>
                        <tr className='sticky'>
                            {clms.map((item, index) => {
                                if (index === 0) {
                                    return (
                                        <th key={item.id} className={'table-dark'}>
                                            {item.name}
                                        </th>
                                    );
                                } else {
                                    return (
                                        <RightTh key={item.id} className={'table-dark'}>
                                            {item.name}
                                        </RightTh>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {!!overview &&
                            !isLoading &&
                            currAndLastYearData.length > 0 &&
                            currAndLastYearData.map(row =>
                                sortMeasureByInstitute(row.confezioni).map((confezione, i) => (
                                    <TableRow key={i}>
                                        <td>{row.year}</td>
                                        <RightTd>{confezione.institute}</RightTd>
                                        <RightTd>{t('genericOverview.packaging')}</RightTd>
                                        <RightTd>{confezione.typeErogazione}</RightTd>
                                        <RightTd>{confezione.totMese.toLocaleString('DE-de')}</RightTd>
                                        <RightTd>{confezione.totMedioMesiPrecedenti.toLocaleString('DE-de')}</RightTd>
                                        <RightTd>{confezione.percentuale.toLocaleString('DE-de')}</RightTd>
                                    </TableRow>
                                )),
                            )}
                    </tbody>
                </Table>
            </div>
            <div className='tableFixHead'>
                <Table size='xl'>
                    <thead className='sticky'>
                        <tr className='sticky'>
                            {clms.map((item, index) => {
                                if (index === 0) {
                                    return (
                                        <th key={item.id} className={'table-dark'}>
                                            {item.name}
                                        </th>
                                    );
                                } else {
                                    return (
                                        <RightTh key={item.id} className={'table-dark'}>
                                            {item.name}
                                        </RightTh>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {!!overview &&
                            !isLoading &&
                            currAndLastYearData.length > 0 &&
                            currAndLastYearData.map(row =>
                                sortMeasureByInstitute(row.spesa).map((spesa, i) => (
                                    <TableRow key={i}>
                                        <td>{row.year}</td>
                                        <RightTd>{spesa.institute}</RightTd>
                                        <RightTd>{t('genericOverview.consume')}</RightTd>
                                        <RightTd>{spesa.typeErogazione}</RightTd>
                                        <RightTd>{spesa.totMese.toLocaleString('DE-de')}</RightTd>
                                        <RightTd>{spesa.totMedioMesiPrecedenti.toLocaleString('DE-de')}</RightTd>
                                        <RightTd>{spesa.percentuale.toLocaleString('DE-de')}</RightTd>
                                    </TableRow>
                                )),
                            )}
                    </tbody>
                </Table>
            </div>
            {!!overview &&
                !isLoading &&
                currAndLastYearData.length > 0 &&
                currAndLastYearData.map((row, index) => (
                    <b key={index}>
                        Numero di Prezzi Farmaco anno {row.year} del mese caricati: {row.nrFarmaci}
                    </b>
                ))}
        </Container>
    );
};

export default OverviewFarm;
