import { AlertError, AlertSuccess } from '../../componets/Alert/Alert';
import { Button, Table } from 'react-bootstrap';
import { useCallback, useContext, useState } from 'react';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import CustomModal from '../../componets/Modal/CustomModal';
import CustomSvg from '../../componets/Svg/CustomSvg';
import { ELanguages } from '../../localization';
import GenericError from '../../componets/GenericError/GenericError';
import { IconBtn } from '../../componets/Buttons/Buttons';
import Loading from '../../componets/Loading/Loading';
import { LoggedUserContext } from '../../App';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { useStructures } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

interface IStructuresProps {}
const Structures = (props: IStructuresProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { loggedUser } = useContext(LoggedUserContext);
    const { structures, isLoading, isError, mutate, isValidating } = useStructures(loggedUser.userId);
    const { trigger: triggerDelete } = useSWRMutation(`Structures/Delete`, httpClient.postRequest);

    const [show, setShow] = useState<{ name: string; structureId: number | undefined }>({ name: '', structureId: undefined });
    const [loadingDelete, setLoadingDelete] = useState(false);

    const deleteStructure = useCallback(async () => {
        setLoadingDelete(true);
        try {
            await triggerDelete({
                structureId: show.structureId,
                structureNameIt: '',
                structureNameDe: '',
                structureCode: '',
            });
            mutate();
            setShow({ structureId: undefined, name: '' });
            AlertSuccess(t('structures.editOk'));
        } catch (error) {
            AlertError(t('structures.editKo'));
        } finally {
            setLoadingDelete(false);
        }
    }, [show.structureId]);

    const loadingFetchData = isLoading || isValidating;

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.structures'), url: route.structures }]} />
            <Button type='button' className='align-self-end' onClick={() => navigate(route.new)} variant='primary'>
                {t('structures.new')}
            </Button>
            {loadingFetchData && <Loading />}
            {isError && <GenericError />}
            {!loadingFetchData && structures && structures.length > 0 && (
                <Table size='xl' responsive='xl'>
                    <thead>
                        <tr>
                            {[
                                { name: t('structures.codClm'), id: 4 },
                                { name: t('structures.nameClm'), id: 1 },
                                { name: t('structures.refClm'), id: 5 },
                                { name: '', id: 11 },
                                { name: '', id: 12 },
                            ].map(data => (
                                <th key={data.id}>{data.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {structures.map(data => (
                            <tr key={data.structureId}>
                                <td>{data.structureCode}</td>
                                <td>{i18n.language === ELanguages.it ? data.structureNameIt : data.structureNameDe}</td>
                                <td>{data.users.map(u => u.userName + ' ' + u.userSurname).join(', ')}</td>
                                <td>
                                    <IconBtn withOutline onClick={() => navigate(`${data.structureId}`)}>
                                        <CustomSvg iconName='it-pencil' className='icon icon-primary' />
                                    </IconBtn>
                                </td>
                                <td>
                                    <IconBtn
                                        borderProps='1px solid #995c00'
                                        withOutline
                                        onClick={() => setShow({ structureId: data.structureId, name: i18n.language === ELanguages.it ? data.structureNameIt : data.structureNameDe })}>
                                        <CustomSvg iconName='it-delete' className='icon icon-warning' />
                                    </IconBtn>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <CustomModal
                key={`cancelModal ${show.structureId}`}
                title={t('modals.deleteTitle')}
                description={`${t('modals.deleteStructure', { name: show.name })}`}
                handleConfirm={deleteStructure}
                disabled={loadingDelete}
                show={!!show && !!show.structureId}
                handleClose={() => {
                    setShow({ structureId: undefined, name: '' });
                }}
            />
        </Container>
    );
};

export default Structures;
