import { Container, RightTd, RightTh, TableRow } from '../../style';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelFarmosp from '../ExportExcel/ExportExcelFarmosp';
import GenericError from '../GenericError/GenericError';
import { IOverviewFarmosp } from '../../model';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { roundToTwoDecimals } from '../../utils';
import { t } from 'i18next';
import { useContext } from 'react';
import { useFarmospOverview } from '../../api/fetch';

interface OverviewFarmospProps {}

const OverviewFarmosp = (props: OverviewFarmospProps) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useFarmospOverview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [] as IOverviewFarmosp[]);

    const sortByStructureCode = (arr: IOverviewFarmosp[]) => {
        const data = [...arr];
        return data.sort((a, b) => {
            return Number(a.structureCode) - Number(b.structureCode);
        });
    };

    const calculateRowTotals = (data: IOverviewFarmosp[]): IOverviewFarmosp[] => {
        const sortedData = sortByStructureCode(data);
        return sortedData.map(item => {
            const total = item.apr + item.aug + item.dec + item.feb + item.jan + item.jul + item.jun + item.mar + item.may + item.nov + item.oct + item.sep;

            return {
                ...item,
                tot: total,
            };
        });
    };

    const obsWithRowTotals = calculateRowTotals(overviewTableRows);

    const computeClmTotals = (data: IOverviewFarmosp[]) => {
        const totals = data.reduce(
            (acc, item) => {
                acc.apr = roundToTwoDecimals((acc.apr || 0) + item.apr);
                acc.aug = roundToTwoDecimals((acc.aug || 0) + item.aug);
                acc.dec = roundToTwoDecimals((acc.dec || 0) + item.dec);
                acc.feb = roundToTwoDecimals((acc.feb || 0) + item.feb);
                acc.jan = roundToTwoDecimals((acc.jan || 0) + item.jan);
                acc.jul = roundToTwoDecimals((acc.jul || 0) + item.jul);
                acc.jun = roundToTwoDecimals((acc.jun || 0) + item.jun);
                acc.mar = roundToTwoDecimals((acc.mar || 0) + item.mar);
                acc.may = roundToTwoDecimals((acc.may || 0) + item.may);
                acc.nov = roundToTwoDecimals((acc.nov || 0) + item.nov);
                acc.oct = roundToTwoDecimals((acc.oct || 0) + item.oct);
                acc.sep = roundToTwoDecimals((acc.sep || 0) + item.sep);
                acc.tot = roundToTwoDecimals((acc.tot || 0) + item.tot);
                return acc;
            },
            {
                structureCode: t('generic.total'),
                apr: 0,
                aug: 0,
                dec: 0,
                feb: 0,
                jan: 0,
                jul: 0,
                jun: 0,
                mar: 0,
                may: 0,
                nov: 0,
                oct: 0,
                sep: 0,
                tot: 0,
            },
        );

        data.push(totals);

        return data;
    };

    const obsWithClmAndRowTotals = computeClmTotals(obsWithRowTotals);

    const clms = [
        { name: t('genericOverview.healthCompany'), id: 1 },
        { name: t('months.0'), id: 2 },
        { name: t('months.1'), id: 3 },
        { name: t('months.2'), id: 4 },
        { name: t('months.3'), id: 5 },
        { name: t('months.4'), id: 6 },
        { name: t('months.5'), id: 7 },
        { name: t('months.6'), id: 8 },
        { name: t('months.7'), id: 9 },
        { name: t('months.8'), id: 10 },
        { name: t('months.9'), id: 11 },
        { name: t('months.10'), id: 12 },
        { name: t('months.11'), id: 13 },
        { name: t('generic.total'), id: 14 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-farmosp' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <div>
                {overview.permissionSet.canExportOverview ? <ExportExcelFarmosp overviewData={obsWithClmAndRowTotals} /> : <></>}
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                obsWithClmAndRowTotals.length > 0 &&
                                obsWithClmAndRowTotals.map((data, index) =>
                                    index !== obsWithClmAndRowTotals.length - 1 ? (
                                        <TableRow key={index}>
                                            <td>{data.structureCode}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr key={index} style={{ borderTop: '3px solid black' }}>
                                            <td>{data.structureCode}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Container>
    );
};

export default OverviewFarmosp;
