import BreadCrumb from '../Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import OverviewOrderTableDiab from '../OverviewTable/OverviewOrderTableDiab';
import { styled } from 'styled-components';
import { t } from 'i18next';
import { useContext } from 'react';
import { useDiabOverview } from '../../api/fetch';

const VerticalSpacingDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8rem;
`;

const OverviewDiab = () => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useDiabOverview(loggedUser.userId);

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-diab' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!!overview && !isLoading && (
                <VerticalSpacingDiv>
                    <OverviewOrderTableDiab overview={overview} order='order1' />
                    <OverviewOrderTableDiab overview={overview} order='order2' />
                    <OverviewOrderTableDiab overview={overview} order='order3' />
                    <OverviewOrderTableDiab overview={overview} order='order4' />
                </VerticalSpacingDiv>
            )}
        </Container>
    );
};

export default OverviewDiab;
