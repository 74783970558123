import BurgerNav from '../componets/BurgerNav/BurgerNav';
import LanguageBtn from '../componets/LanguageBtn/LanguageBtn';
import { Link } from 'react-router-dom';
import UserBtn from '../componets/UserBtn/UserBtn';
import { useTranslation } from 'react-i18next';

interface IHeaderProps {}

const Header = (props: IHeaderProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className='it-header-slim-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='it-header-slim-wrapper-content'>
                                <Link className='d-lg-block navbar-brand' to={'/'}>
                                    {t('generic.title')}
                                </Link>
                                <div className='it-header-slim-right-zone'>
                                    <LanguageBtn />
                                    <UserBtn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='it-header-center-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='it-header-center-content-wrapper'>
                                <div className='it-brand-wrapper d-flex'>
                                    <Link to={'/'}>
                                        <div className='it-brand-text'>
                                            <div className='it-brand-title'>{t('generic.appTitle')}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='it-right-zone'>
                                    <BurgerNav />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
