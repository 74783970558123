import { Container } from '../../style';
import { EFlowType } from '../../model/enum';
import { LoggedUserContext } from '../../App';
import OverviewTablePatology from '../OverviewTable/OverviewTablePatology';
import { useContext } from 'react';
import { usePatologyOverview } from '../../api/fetch';

interface OverviewPatologyProps {
    flow: EFlowType;
}

const OverviewPatology = (props: OverviewPatologyProps) => {
    const { flow } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = usePatologyOverview(loggedUser.userId, flow);

    return (
        <Container>
            <OverviewTablePatology flow={flow} isError={isError} isLoading={isLoading} overview={overview} />
        </Container>
    );
};

export default OverviewPatology;
