import CustomModal from '../Modal/CustomModal';
import CustomSvg from '../Svg/CustomSvg';
import { ELanguages } from '../../localization';
import { ICalendaringBe } from '../../model';
import { IconBtn } from '../Buttons/Buttons';
import Table from 'react-bootstrap/Table';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IActionTableProps {
    header: string;
    rows: ICalendaringBe[];
    deleteName?: string;
    update?: any;
}

const CustomTd = styled.td`
    vertical-align: middle;
`;

const CalendaringTable = (props: IActionTableProps) => {
    const { header, rows, update } = props;
    return (
        <Table size='xl' responsive='xl'>
            <thead>
                <tr>
                    <th style={{ width: '40px' }}></th>
                    <th>{header}</th>
                </tr>
            </thead>
            <tbody>{rows.length > 0 && rows.map((data, index) => <RowNested key={data.flowId} data={data} index={index} update={update}></RowNested>)}</tbody>
        </Table>
    );
};

const RowTr = styled.tr`
    border: none;
`;

const RowTd = styled.td`
    border: none;
    justify-content: space-between;
`;

const InnerDiv = styled.div`
    display: flex;
    padding: 0.5rem 0 0 0;
    border-bottom: 1px solid red;
`;

const InnerSpan = styled.span`
    width: 30%;
    padding: 0 0.5rem;
`;
const InnerSpanIcon = styled.span`
    width: 40%;
    display: flex;
    padding: 0 0.5rem;
    gap: 0.5rem;
    justify-content: flex-end;
`;
const RowNested = (props: { data: ICalendaringBe; index: number; update: any }) => {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState<{ id: number; recurrenceId: number } | undefined>(undefined);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { data, update } = props;
    let recurrence = get(data, 'recurrences', []);
    const { trigger } = useSWRMutation('Scheduler/Delete', httpClient.postRequest);

    return (
        <>
            <tr>
                <CustomTd>
                    <IconBtn
                        onClick={() => {
                            setOpen(!open);
                        }}>
                        {open ? <CustomSvg className='icon icon-primary' iconName='it-collapse' /> : <CustomSvg className='icon icon-primary' iconName='it-expand' />}
                    </IconBtn>
                </CustomTd>
                <CustomTd>{i18n.language === ELanguages.it ? data.flowNameIt : data.flowNameDe}</CustomTd>
            </tr>
            {open && recurrence.length > 0 && (
                <>
                    <tr>
                        <RowTd colSpan={2}>
                            <InnerDiv style={{ display: 'flex', borderBottom: '1px' }}>
                                <InnerSpan></InnerSpan>
                                <InnerSpan style={{ fontWeight: 'bold' }}>{t('calendaring.beginning')}</InnerSpan>
                            </InnerDiv>
                        </RowTd>
                    </tr>
                    {recurrence.map(r => (
                        <RowTr key={r.id}>
                            <RowTd colSpan={2}>
                                <InnerDiv style={{ display: 'flex', padding: '0.5rem', borderBottom: '1px' }}>
                                    <InnerSpan>{i18n.language === ELanguages.it ? r.reminderNameIt : r.reminderNameDe}</InnerSpan>
                                    <InnerSpan>{new Date(r.sendDate).toLocaleDateString()}</InnerSpan>
                                    <InnerSpanIcon>
                                        <IconBtn withOutline borderProps='1px solid #995c00' onClick={() => setShow({ id: data.flowId, recurrenceId: r.id })}>
                                            <CustomSvg iconName='it-delete' className='icon icon-warning' />
                                        </IconBtn>
                                        <IconBtn withOutline onClick={() => navigate(route.home + route.calendaring + route.home + r.id + route.home + data.flowId + route.home + data.roleId)}>
                                            <CustomSvg iconName='it-calendar' className='icon icon-primary' />
                                        </IconBtn>
                                    </InnerSpanIcon>
                                </InnerDiv>
                            </RowTd>
                        </RowTr>
                    ))}
                </>
            )}

            <CustomModal
                title={t('modals.deleteTitle')}
                description={t('modals.deleteDescription', { name: 'record' })}
                handleConfirm={() => {
                    toast.promise(
                        trigger({
                            flowId: show?.id,
                            firstRecurrenceId: show?.recurrenceId,
                        }),
                        {
                            loading: 'Loading',
                            success: data => {
                                if (update) update();
                                setShow(undefined);
                                return t('generic.success');
                            },
                            error: e => {
                                return t('generic.error');
                            },
                        },
                    );
                }}
                show={show !== undefined && !!show.id && !!show.recurrenceId}
                handleClose={() => setShow(undefined)}
            />
        </>
    );
};
export default CalendaringTable;
