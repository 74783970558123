import { IFilterSendFile } from '../model';

export const ITEMS_PER_PAGE = 10;

export const initialDataSendFile: IFilterSendFile = {
    userName: '',
    sped: '',
    status: -1,
    date: '',
};
