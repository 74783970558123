import { EFileNameResult, EFlowType } from '../model/enum';
import { allEqual, isValidDate, isValidDateShortFormat } from '.';

import { AlertError } from '../componets/Alert/Alert';
import { ChangeEvent } from 'react';
import { get } from 'lodash';
import i18n from '../localization';

export const checkFileNameSiar = (name: string): { status: EFileNameResult; msg: string } => {
    if (!name) return { status: EFileNameResult.error, msg: i18n.t('sendFiles.insertFile') };
    try {
        const fileName = name.split('.');
        let data = fileName[0].split('_');
        if (data.length === 6) {
            let dates = data[3];
            if (dates.length === 8) {
                let year = dates.slice(0, 4);
                let month = dates.slice(4, 6);
                let day = dates.slice(6, 8);
                let yearNum = /^\d+$/.test(year);
                if (!yearNum) {
                    return { status: EFileNameResult.error, msg: i18n.t('sendFiles.yearFile') };
                }
                let monthNum = /^\d+$/.test(month);
                let dayNum = /^\d+$/.test(day);
                if (!monthNum || !(monthNum && parseInt(month) > 0 && parseInt(month) < 13)) {
                    return { status: EFileNameResult.error, msg: i18n.t('sendFiles.monthErrorFile') };
                }
                if (!dayNum || !(dayNum && parseInt(day) > 0 && parseInt(day) < 32)) {
                    return { status: EFileNameResult.error, msg: i18n.t('sendFiles.dayErrorFile') };
                }
            } else {
                return { status: EFileNameResult.error, msg: i18n.t('sendFiles.dateErrorFile') };
            }
            if (data[4] === 'SIAR' && (data[5] === '1' || data[5] === '2' || data[5] === '3' || data[5] === '4' || data[5] === '5' || data[5] === '6'))
                return { status: EFileNameResult.success, msg: '' };
        }
        return { status: EFileNameResult.error, msg: i18n.t('sendFiles.fileFormatError') };
    } catch (err) {
        return { status: EFileNameResult.error, msg: i18n.t('sendFiles.emptyError') };
    }
};
export const checkFilesByFlowType = (flowType: string, first: File, sec: File, third: File, fourth: File, fifth: File, sixth: File): { status: EFileNameResult; msg: string }[] => {
    let sourceValues: string[] = [];
    const t = i18n.t;
    const splittedFirst = first.name.split('.')[0].split('_');
    const splittedSec = sec.name.split('.')[0].split('_');
    const splittedThird = third.name.split('.')[0].split('_');
    const splittedFourth = fourth.name.split('.')[0].split('_');
    const splittedFifth = fifth.name.split('.')[0].split('_');
    const splittedSixth = sixth.name.split('.')[0].split('_');

    let firstFileName = `${splittedFirst[4]}_${first.name.split('.')[0].split('_')[5]}`;
    sourceValues.push(firstFileName);
    let secondFileName = `${splittedSec[4]}_${sec.name.split('.')[0].split('_')[5]}`;
    sourceValues.push(secondFileName);
    let thirdFileName = `${splittedThird[4]}_${third.name.split('.')[0].split('_')[5]}`;
    sourceValues.push(thirdFileName);
    let fourthFileName = `${splittedFourth[4]}_${fourth.name.split('.')[0].split('_')[5]}`;
    sourceValues.push(fourthFileName);
    let fifthFileName = `${splittedFifth[4]}_${fifth.name.split('.')[0].split('_')[5]}`;
    sourceValues.push(fifthFileName);
    let sixthFileName = `${splittedSixth[4]}_${sixth.name.split('.')[0].split('_')[5]}`;
    sourceValues.push(sixthFileName);

    const arraysContainSameElements = (sourceArr: string[]): boolean => {
        const targetValues: string[] = [`${flowType}_1`, `${flowType}_2`, `${flowType}_3`, `${flowType}_4`, `${flowType}_5`, `${flowType}_6`];
        if (sourceArr.length !== targetValues.length) {
            return false;
        }

        const sortedArr = [...sourceArr].sort();
        const sortedTarget = [...targetValues].sort();

        for (let i = 0; i < sortedArr.length; i++) {
            if (sortedArr[i] !== sortedTarget[i]) {
                return false;
            }
        }

        return true;
    };

    let otherFieldMatch: boolean = false;

    if (
        splittedFirst.length === splittedSec.length &&
        splittedSec.length === splittedThird.length &&
        splittedThird.length === splittedFourth.length &&
        splittedFourth.length === splittedFifth.length &&
        splittedFifth.length === splittedSixth.length
    ) {
        for (let i = 0; i < splittedFirst.length - 1; i++) {
            if (
                splittedFirst[i] === splittedSec[i] &&
                splittedSec[i] === splittedThird[i] &&
                splittedThird[i] === splittedFourth[i] &&
                splittedFourth[i] === splittedFifth[i] &&
                splittedFifth[i] === splittedSixth[i]
            ) {
                otherFieldMatch = true;
            } else {
                otherFieldMatch = false;
                break;
            }
        }
    }

    if (arraysContainSameElements(sourceValues) && otherFieldMatch)
        return [
            checkFileNameSiar(first.name),
            checkFileNameSiar(sec.name),
            checkFileNameSiar(third.name),
            checkFileNameSiar(fourth.name),
            checkFileNameSiar(fifth.name),
            checkFileNameSiar(sixth.name),
        ];
    return [
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
    ];
};

const createRegexFromPattern = (pattern: string) => {
    const escapedPattern = pattern
        // 2 lettere maiuscole
        .replace(/AA/g, '[A-Z]{2}')
        // 0410 seguito da 2 cifre
        .replace(/0410xx/g, '0410\\d{2}')
        // Data nel formato yyyymmdd
        .replace(/yyyymmdd/g, '\\d{8}')
        // Escapa i punti
        .replace(/\./g, '\\.')
        // Permetti qualsiasi stringa
        .replace(/\*/g, '.*');
    return new RegExp(`^${escapedPattern}$`);
};

const checkFilesName = (files: string[]) => {
    const expectedUnderscores = 4;

    const containsExactUnderscores = files.every(file => (file.match(/_/g) || []).length === expectedUnderscores);
    if (!containsExactUnderscores) {
        return false;
    }

    const segments = files.map(file => file.split('_'));
    const [anagElementsArray, codeElementsArray, dateElementsArray] = [0, 1, 2].map(i => segments.map(seg => seg[i]));

    return allEqual(anagElementsArray) && allEqual(codeElementsArray) && dateElementsArray.every(isValidDate);
};

const onChangeSiar = (event: ChangeEvent<HTMLInputElement>, flowType: EFlowType, setFieldValue: Function): void => {
    const files = get(event, 'target.files', []);

    if (files.length === 6) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let second = filesArray[1];
        let third = filesArray[2];
        let fourth = filesArray[3];
        let fifth = filesArray[4];
        let sixth = filesArray[5];
        const control = checkFilesByFlowType(flowType, first, second, third, fourth, fifth, sixth);

        const allFilesValid = control.every((file: { status: EFileNameResult; msg: string }) => file.status === 'success');

        if (allFilesValid) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.filesFormatErrorSiar'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectSiar'));
    }
    event.target.value = '';
};

const onChangeCedp = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 2) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let second = filesArray[1];
        let firstFileName = first.name.split('.');
        let secondFileName = second.name.split('.');
        let firstData = firstFileName[0].split('_');
        let secondData = secondFileName[0].split('_');
        let machFormat = (firstData[3] === 'CAPM01A' && secondData[3] === 'CAPM01B') || (firstData[3] === 'CAPM01B' && secondData[3] === 'CAPM01A');
        let otherFieldMatch = firstData[0] === secondData[0] && firstData[1] === secondData[1] && isValidDate(firstData[2]) && isValidDate(secondData[2]);

        if (otherFieldMatch && machFormat) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.filesFormatError'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelect'));
    }
    event.target.value = '';
};

const onChangeDM = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 1) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let firstFileName = first.name.split('.');
        let firstData = firstFileName[0].split('_');
        let machFormat = firstData[3] === 'CNR' || firstData[3] === 'CNS';
        let otherFieldMatch = isValidDateShortFormat(firstData[1]) && firstData[2] === 'DM';

        if (otherFieldMatch && machFormat) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.fileFormatError'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectOneFile'));
    }
    event.target.value = '';
};

const onChangeFARM = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 1) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let firstFileName = first.name.split('.');
        let firstData = firstFileName[0].split('_');
        let machFormat = firstData[3] === 'FARMCONTAR';
        let otherFieldMatch = isValidDate(firstData[2]);

        if (otherFieldMatch && machFormat) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.fileFormatError'));
        }
    } else if (files.length === 3) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let second = filesArray[1];
        let third = filesArray[2];
        let firstFileName = first.name.split('.');
        let secondFileName = second.name.split('.');
        let thirdFileName = third.name.split('.');
        let firstData = firstFileName[0].split('_');
        let secondData = secondFileName[0].split('_');
        let thirdData = thirdFileName[0].split('_');
        let otherFieldMatch =
            firstData[0] === secondData[0] &&
            firstData[0] === thirdData[0] &&
            firstData[1] === secondData[1] &&
            firstData[1] === thirdData[1] &&
            firstData[3].substring(0, firstData[3].length - 1) === secondData[3].substring(0, secondData[3].length - 1) &&
            secondData[3].substring(0, secondData[3].length - 1) === thirdData[3].substring(0, thirdData[3].length - 1) &&
            (firstData[3].substring(0, firstData[3].length - 1) === 'FARMDIR01' || firstData[3].substring(0, firstData[3].length - 1) === 'FARMCON01') &&
            isValidDate(firstData[2]) &&
            isValidDate(secondData[2]) &&
            isValidDate(thirdData[2]);

        let machFormat = [firstData[3][firstData[3].length - 1], secondData[3][secondData[3].length - 1], thirdData[3][thirdData[3].length - 1]];

        let validCombinations = [
            ['A', 'B', 'C'],
            ['A', 'C', 'B'],
            ['B', 'A', 'C'],
            ['B', 'C', 'A'],
            ['C', 'A', 'B'],
            ['C', 'B', 'A'],
        ];
        let isValid = validCombinations.some(combination => combination.every((value, index) => value === machFormat[index]));

        if (otherFieldMatch && isValid) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.fileFormatError'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectOneOrThreeFile'));
    }
    event.target.value = '';
};

const onChangeFARMTER = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 3) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let second = filesArray[1];
        let third = filesArray[2];
        let firstFileName = first.name.split('.');
        let secondFileName = second.name.split('.');
        let thirdFileName = third.name.split('.');
        let firstData = firstFileName[0].split('_');
        let secondData = secondFileName[0].split('_');
        let thirdData = thirdFileName[0].split('_');
        let otherFieldMatch =
            firstData[0] === secondData[0] &&
            firstData[0] === thirdData[0] &&
            firstData[1] === secondData[1] &&
            firstData[1] === thirdData[1] &&
            isValidDateShortFormat(firstData[2]) &&
            isValidDateShortFormat(secondData[2]) &&
            isValidDateShortFormat(secondData[2]) &&
            firstData[3] === 'FARMTER' &&
            firstData[3] === secondData[3] &&
            firstData[3] === thirdData[3];

        let machFormat = [firstData[4], secondData[4], thirdData[4]];

        let validCombinations = [
            ['T4', 'T5', 'T6'],
            ['T4', 'T6', 'T5'],
            ['T5', 'T4', 'T6'],
            ['T5', 'T6', 'T4'],
            ['T6', 'T4', 'T5'],
            ['T6', 'T5', 'T4'],
        ];
        let isValid = validCombinations.some(combination => combination.every((value, index) => value === machFormat[index]));

        if (otherFieldMatch && isValid) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.filesFormatErrorSiar'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectThreeFile'));
    }
    event.target.value = '';
};

const onChangeGenericCLIANA = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function, check: string, cli = 'CLI', ana = 'ANA'): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 2) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let second = filesArray[1];
        let firstFileName = first.name.split('.');
        let secondFileName = second.name.split('.');
        let firstData = firstFileName[0].split('_');
        let secondData = secondFileName[0].split('_');
        let machFormat = (firstData[4] === ana && secondData[4] === cli) || (firstData[4] === cli && secondData[4] === ana);

        let otherFieldMatch =
            firstData[0] === secondData[0] && firstData[1] === secondData[1] && firstData[3] === secondData[3] && firstData[3] === check && isValidDate(firstData[2]) && isValidDate(secondData[2]);

        if (otherFieldMatch && machFormat) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.filesFormatError'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelect'));
    }
    event.target.value = '';
};

const onChangeGenericCLI = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function, check: string, cli = 'CLI'): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 1) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let fileName = first.name.split('.');
        let fileData = fileName[0].split('_');
        let machFormat = fileData[4] === cli;
        let otherFieldMatch = fileData[3] === check && isValidDate(fileData[2]);

        if (otherFieldMatch && machFormat) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.fileFormatError'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectOneFile'));
    }
    event.target.value = '';
};

const onChange118 = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function): void => {
    const files = get(event, 'target.files', []);
    if (files.length === 3) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let second = filesArray[1];
        let third = filesArray[2];
        let firstFileName = first.name.split('.');
        let secondFileName = second.name.split('.');
        let thirdFileName = third.name.split('.');
        let firstData = firstFileName[0].split('_');
        let secondData = secondFileName[0].split('_');
        let thirdData = thirdFileName[0].split('_');

        let machFormat = [firstData[4], secondData[4], thirdData[4]];

        let validCombinations = [
            ['A', 'B', 'C'],
            ['A', 'C', 'B'],
            ['B', 'A', 'C'],
            ['B', 'C', 'A'],
            ['C', 'A', 'B'],
            ['C', 'B', 'A'],
        ];
        let isValid = validCombinations.some(combination => combination.every((value, index) => value === machFormat[index]));

        let otherFieldMatch =
            firstData[0] === secondData[0] &&
            firstData[0] === thirdData[0] &&
            firstData[1] === secondData[1] &&
            firstData[1] === thirdData[1] &&
            isValidDate(firstData[2]) &&
            isValidDate(secondData[2]) &&
            isValidDate(thirdData[2]) &&
            firstData[3] === secondData[3] &&
            firstData[3] === thirdData[3];

        if (otherFieldMatch && isValid) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.filesFormatErrorSiar'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectThreeFile'));
    }
    event.target.value = '';
};

const onChangeGenericSingleFile = (event: ChangeEvent<HTMLInputElement>, setFieldValue: Function, check: string): void => {
    const files = get(event, 'target.files', []);

    if (files.length === 1) {
        const filesArray = Array.from(files);
        let first = filesArray[0];
        let firstFileName = first.name.split('.');
        let firstData = firstFileName[0].split('_');
        let machFormat = firstData[3] === check;
        //TODO add regex for 0 and 1 code
        let otherFieldMatch = isValidDate(firstData[2]);

        if (otherFieldMatch && machFormat) {
            const filesArray = Array.from(files).map(file => ({
                file: file,
                id: crypto.randomUUID(),
            }));

            setFieldValue('files', filesArray);
        } else {
            AlertError(i18n.t('sendFiles.fileFormatError'));
        }
    } else {
        AlertError(i18n.t('sendFiles.errorSelectOneFile'));
    }
    event.target.value = '';
};

export const onChangeFilesByGenericType = (event: ChangeEvent<HTMLInputElement>, type: EFlowType, setFieldValue: Function): void => {
    switch (type.toUpperCase()) {
        case EFlowType.Fim.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'FIM');
            break;
        case EFlowType.Siar.toUpperCase():
            onChangeSiar(event, type, setFieldValue);
            break;
        case EFlowType.Cedp.toUpperCase():
            onChangeCedp(event, setFieldValue);
            break;
        case EFlowType.Spa.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'SPE');
            break;
        case EFlowType.Sps.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'SPS');
            break;
        case EFlowType.Sderia.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'SDERIA');
            break;
        case EFlowType.Diab.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'DIAB');
            break;
        case EFlowType.Hosp.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'HOSP');
            break;
        case EFlowType.X.toUpperCase():
            onChange118(event, setFieldValue);
            break;
        case EFlowType.Farmosp.toUpperCase():
            onChangeGenericSingleFile(event, setFieldValue, 'FARMOSP');
            break;
        case EFlowType.Sind.toUpperCase():
            onChangeGenericSingleFile(event, setFieldValue, 'FARMDIR01C');
            break;
        case EFlowType.Labo.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'LABO');
            break;
        case EFlowType.Spi.toUpperCase():
            onChangeGenericCLIANA(event, setFieldValue, 'SPI');
            break;
        case EFlowType.Dm.toUpperCase():
            onChangeDM(event, setFieldValue);
            break;
        case EFlowType.Farm.toUpperCase():
            onChangeFARM(event, setFieldValue);
            break;
        case EFlowType.Rpa.toUpperCase():
            onChangeGenericCLI(event, setFieldValue, 'RPA');
            break;
        case EFlowType.Rpg.toUpperCase():
            onChangeGenericCLI(event, setFieldValue, 'RPG');
            break;
        case EFlowType.Farmter.toUpperCase():
            onChangeFARMTER(event, setFieldValue);
            break;
        default:
            break;
    }
};
