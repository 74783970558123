import CustomModal from '../Modal/CustomModal';
import CustomSvg from '../Svg/CustomSvg';
import { ELanguages } from '../../localization';
import { IconButton } from '../../style/buttons/buttons';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IActionTableProps {
    header: string;
    rows: any[];
    deleteName?: string;
    withEye?: boolean;
}

const Cell = styled.td`
    display: flex;
    gap: 1rem;
`;
const CustomTd = styled.td`
    vertical-align: middle;
`;

const ActionTable = (props: IActionTableProps) => {
    const { header, rows, deleteName, withEye } = props;
    const [show, setShow] = useState<number | undefined>(undefined);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <Table size='xl' responsive='xl'>
            <thead>
                <tr>
                    <th>{header}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows.length > 0 &&
                    rows.map((data, index) => (
                        <tr key={`${data.id}`}>
                            <CustomTd>{i18n.language === ELanguages.it ? data.nameIt : data.nameDe}</CustomTd>
                            <Cell className='d-flex flex-row-reverse'>
                                <IconButton title={t('generic.tooltipEdit')} onClick={() => navigate(`${data.id}`)}>
                                    <CustomSvg className='icon icon-sm icon-primary' iconName={withEye ? 'it-password-visible' : 'it-pencil'} />
                                </IconButton>
                            </Cell>
                        </tr>
                    ))}
            </tbody>
            <CustomModal
                title={t('modals.deleteTitle')}
                description={t('modals.deleteDescription', { name: deleteName ? deleteName : 'record' })}
                handleConfirm={() => {}}
                show={show !== undefined && show >= 0}
                handleClose={() => setShow(undefined)}
            />
        </Table>
    );
};

export default ActionTable;
