import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';
import { IClmTotalsDiab, IOrderObject, IOverviewDiab, IOverviewDiabPercentage, IOverviewDiabWithPermissions, IPercTotalsDiab } from '../../model';

import ExportExcelDiab from '../ExportExcel/ExportExcelDiab';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { getCurrentMonthAndYear } from '../../utils';
import { styled } from 'styled-components';
import { t } from 'i18next';

interface OverviewOrderTableDiabProps {
    overview: IOverviewDiabWithPermissions;
    order: string;
}

const CenteredTitle = styled.h1`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
`;

const OverviewOrderTableDiab = (props: OverviewOrderTableDiabProps) => {
    const { overview, order } = props;

    const sortByInstitute = (array: IOverviewDiab[]): IOverviewDiab[] => {
        const nextArray = [...array];
        return nextArray
            .filter(item => item.institute != null)
            .sort((a, b) => {
                const nameA = a.institute.split(' ')[0].toUpperCase();
                const nameB = b.institute.split(' ')[0].toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
    };

    const overviewTableRows = get(overview, 'overviewTableRows', {} as IOrderObject);
    const currentYearRecs = sortByInstitute(overviewTableRows[`${order}`].orderList.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear));
    const lastYearRecs = sortByInstitute(overviewTableRows[`${order}`].orderList.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear - 1));

    const computePercIndexes = (curYearRecs: IOverviewDiab[], pastYearRecs: IOverviewDiab[]) => {
        let arr: IOverviewDiabPercentage[] = [];

        curYearRecs.forEach(cur => {
            pastYearRecs.forEach(past => {
                if (cur.institute === past.institute) {
                    let institute = cur.institute;
                    let jan = past.jan !== 0 ? ((cur.jan - past.jan) / past.jan) * 100 : 0;
                    let feb = past.feb !== 0 ? ((cur.feb - past.feb) / past.feb) * 100 : 0;
                    let mar = past.mar !== 0 ? ((cur.mar - past.mar) / past.mar) * 100 : 0;
                    let apr = past.apr !== 0 ? ((cur.apr - past.apr) / past.apr) * 100 : 0;
                    let may = past.may !== 0 ? ((cur.may - past.may) / past.may) * 100 : 0;
                    let jul = past.jul !== 0 ? ((cur.jul - past.jul) / past.jul) * 100 : 0;
                    let jun = past.jun !== 0 ? ((cur.jun - past.jun) / past.jun) * 100 : 0;
                    let aug = past.aug !== 0 ? ((cur.aug - past.aug) / past.aug) * 100 : 0;
                    let sep = past.sep !== 0 ? ((cur.sep - past.sep) / past.sep) * 100 : 0;
                    let oct = past.oct !== 0 ? ((cur.oct - past.oct) / past.oct) * 100 : 0;
                    let nov = past.nov !== 0 ? ((cur.nov - past.nov) / past.nov) * 100 : 0;
                    let dec = past.dec !== 0 ? ((cur.dec - past.dec) / past.dec) * 100 : 0;
                    let totaleVisite = past.totaleVisite !== 0 ? ((cur.totaleVisite - past.totaleVisite) / past.totaleVisite) * 100 : 0;
                    let numeroPazienti = past.numeroPazienti !== 0 ? ((cur.numeroPazienti - past.numeroPazienti) / past.numeroPazienti) * 100 : 0;
                    let mediaVisitePaziente = past.mediaVisitePaziente !== 0 ? ((cur.mediaVisitePaziente - past.mediaVisitePaziente) / past.mediaVisitePaziente) * 100 : 0;

                    let nextRow: IOverviewDiabPercentage = {
                        institute: institute,
                        year: '',
                        jan: `${Math.round(jan)} %`,
                        feb: `${Math.round(feb)} %`,
                        mar: `${Math.round(mar)} %`,
                        apr: `${Math.round(apr)} %`,
                        may: `${Math.round(may)} %`,
                        jul: `${Math.round(jul)} %`,
                        jun: `${Math.round(jun)} %`,
                        aug: `${Math.round(aug)} %`,
                        sep: `${Math.round(sep)} %`,
                        oct: `${Math.round(oct)} %`,
                        nov: `${Math.round(nov)} %`,
                        dec: `${Math.round(dec)} %`,
                        totaleVisite: `${Math.round(totaleVisite)} %`,
                        numeroPazienti: `${Math.round(numeroPazienti)} %`,
                        mediaVisitePaziente: `${Math.round(mediaVisitePaziente)} %`,
                    };
                    arr = [...arr, nextRow];
                }
            });
        });
        return arr;
    };

    const computeClmsTotals = (observations: IOverviewDiab[]) => {
        const initialTotals = {
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
            totaleVisite: 0,
            numeroPazienti: 0,
            mediaVisitePaziente: 0,
        };

        const totals = observations.reduce((accumulator, current) => {
            return {
                jan: accumulator.jan + current.jan,
                feb: accumulator.feb + current.feb,
                mar: accumulator.mar + current.mar,
                apr: accumulator.apr + current.apr,
                may: accumulator.may + current.may,
                jun: accumulator.jun + current.jun,
                jul: accumulator.jul + current.jul,
                aug: accumulator.aug + current.aug,
                sep: accumulator.sep + current.sep,
                oct: accumulator.oct + current.oct,
                nov: accumulator.nov + current.nov,
                dec: accumulator.dec + current.dec,
                totaleVisite: accumulator.totaleVisite + current.totaleVisite,
                numeroPazienti: accumulator.numeroPazienti + current.numeroPazienti,
                mediaVisitePaziente: accumulator.mediaVisitePaziente + current.mediaVisitePaziente,
            };
        }, initialTotals);

        return totals;
    };

    const percIndexes = computePercIndexes(currentYearRecs, lastYearRecs);
    const currentClmTotals = computeClmsTotals(currentYearRecs);
    const lastYearClmTotals = computeClmsTotals(lastYearRecs);

    const computePercClmTot = (currYearTotal: IClmTotalsDiab, lastYearTotal: IClmTotalsDiab) => {
        let percClmTot: IPercTotalsDiab = {
            ['jan']: '',
            ['feb']: '',
            ['mar']: '',
            ['apr']: '',
            ['may']: '',
            ['jul']: '',
            ['jun']: '',
            ['aug']: '',
            ['sep']: '',
            ['oct']: '',
            ['nov']: '',
            ['dec']: '',
            ['totaleVisite']: '',
            ['numeroPazienti']: '',
            ['mediaVisitePaziente']: '',
        };

        for (const [firstKey, firstValue] of Object.entries(currYearTotal)) {
            for (const [secondKey, secondValue] of Object.entries(lastYearTotal)) {
                if (firstKey === secondKey) {
                    percClmTot[`${firstKey}`] = `${Math.round(secondValue !== 0 ? ((firstValue - secondValue) / secondValue) * 100 : 0)} %`;
                }
            }
        }
        return percClmTot;
    };

    const percClmTotals = computePercClmTot(currentClmTotals, lastYearClmTotals);

    const clms = [
        { name: t('genericOverview.hospital'), id: 1 },
        { name: t('months.0'), id: 2 },
        { name: t('months.1'), id: 3 },
        { name: t('months.2'), id: 4 },
        { name: t('months.3'), id: 5 },
        { name: t('months.4'), id: 6 },
        { name: t('months.5'), id: 7 },
        { name: t('months.6'), id: 8 },
        { name: t('months.7'), id: 9 },
        { name: t('months.8'), id: 10 },
        { name: t('months.9'), id: 11 },
        { name: t('months.10'), id: 12 },
        { name: t('months.11'), id: 13 },
        { name: t('genericOverview.totalVisits'), id: 14 },
        { name: t('genericOverview.numOfPatients'), id: 15 },
        { name: t('genericOverview.avaPerPatient'), id: 16 },
    ];

    const orderName = overviewTableRows[`${order}`].name;

    return (
        <Container>
            <CenteredTitle>{orderName}</CenteredTitle>
            <ContainerNoGap>
                <CustomDivOverview>
                    <b>{`${getCurrentMonthAndYear().currentYear} - ${orderName}`}</b>
                    {overview.permissionSet.canExportOverview ? (
                        <ExportExcelDiab
                            currentYearRecs={currentYearRecs}
                            lastYearRecs={lastYearRecs}
                            percIndexes={percIndexes}
                            currentClmTotals={currentClmTotals}
                            lastYearClmTotals={lastYearClmTotals}
                            percClmTotals={percClmTotals}
                            orderName={orderName}
                        />
                    ) : (
                        <></>
                    )}
                </CustomDivOverview>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {currentYearRecs.map((data, index) => (
                                <TableRow key={index}>
                                    <td>{data.institute}</td>
                                    <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.totaleVisite.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.numeroPazienti.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.mediaVisitePaziente.toLocaleString('de-DE')}</RightTd>
                                </TableRow>
                            ))}
                            <tr style={{ borderTop: '3px solid black' }}>
                                <td>{t('generic.total')}</td>
                                {Object.entries(currentClmTotals).map(([item, value]) => (
                                    <RightTd key={item}>{value.toLocaleString('de-DE')}</RightTd>
                                ))}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{`${getCurrentMonthAndYear().currentYear - 1} - ${orderName}`}</b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {lastYearRecs.map((data, index) => (
                                <TableRow key={index}>
                                    <td>{data.institute}</td>
                                    <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.totaleVisite.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.numeroPazienti.toLocaleString('de-DE')}</RightTd>
                                    <RightTd>{data.mediaVisitePaziente.toLocaleString('de-DE')}</RightTd>
                                </TableRow>
                            ))}
                            <tr style={{ borderTop: '3px solid black' }}>
                                <td>{t('generic.total')}</td>
                                {Object.entries(lastYearClmTotals).map(([item, value]) => (
                                    <RightTd key={item}>{value.toLocaleString('de-DE')}</RightTd>
                                ))}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{`${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1} - ${orderName}`} </b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {percIndexes.map((data, index) => (
                                <TableRow key={index}>
                                    <td>{data.institute}</td>
                                    <RightTd>{data.jan}</RightTd>
                                    <RightTd>{data.feb}</RightTd>
                                    <RightTd>{data.mar}</RightTd>
                                    <RightTd>{data.apr}</RightTd>
                                    <RightTd>{data.may}</RightTd>
                                    <RightTd>{data.jun}</RightTd>
                                    <RightTd>{data.jul}</RightTd>
                                    <RightTd>{data.aug}</RightTd>
                                    <RightTd>{data.sep}</RightTd>
                                    <RightTd>{data.oct}</RightTd>
                                    <RightTd>{data.nov}</RightTd>
                                    <RightTd>{data.dec}</RightTd>
                                    <RightTd>{data.totaleVisite}</RightTd>
                                    <RightTd>{data.numeroPazienti}</RightTd>
                                    <RightTd>{data.mediaVisitePaziente}</RightTd>
                                </TableRow>
                            ))}
                            <tr style={{ borderTop: '3px solid black' }}>
                                <td>{t('generic.total')}</td>
                                {Object.entries(percClmTotals).map(([item, value]) => (
                                    <RightTd key={item}>{value}</RightTd>
                                ))}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Container>
    );
};

export default OverviewOrderTableDiab;
