import { ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';
import { IOverviewPatology, IOverviewPatologyWithPermissions } from '../../model';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import { EFlowType } from '../../model/enum';
import ExportExcelPatology from '../ExportExcel/ExportExcelPatology';
import { Fragment } from 'react';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { getCurrentMonthAndYear } from '../../utils';
import { useTranslation } from 'react-i18next';

interface OverviewTablePatologyProps {
    overview: IOverviewPatologyWithPermissions;
    isLoading: boolean;
    isError: any;
    flow: EFlowType;
}

const OverviewTablePatology = (props: OverviewTablePatologyProps) => {
    const { overview, isError, isLoading, flow } = props;
    const { t } = useTranslation();

    const sortByInstitute = (arr: IOverviewPatology[]) => {
        const dataToBeSorted = [...arr];
        if (dataToBeSorted.length > 0) {
            return arr.sort((a, b) => {
                const nameA = a.institute.toUpperCase();
                const nameB = b.institute.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        }

        return [];
    };

    const computeClmsTotals = (observations: IOverviewPatology[]) => {
        const initialTotals: IOverviewPatology = {
            institute: '',
            year: '',
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
            tot: 0,
        };

        if (observations.length > 0) {
            const totals = observations.reduce((accumulator, current) => {
                return {
                    institute: t('generic.total'),
                    year: current.year,
                    jan: accumulator.jan + current.jan,
                    feb: accumulator.feb + current.feb,
                    mar: accumulator.mar + current.mar,
                    apr: accumulator.apr + current.apr,
                    may: accumulator.may + current.may,
                    jun: accumulator.jun + current.jun,
                    jul: accumulator.jul + current.jul,
                    aug: accumulator.aug + current.aug,
                    sep: accumulator.sep + current.sep,
                    oct: accumulator.oct + current.oct,
                    nov: accumulator.nov + current.nov,
                    dec: accumulator.dec + current.dec,
                    tot: accumulator.tot + current.tot,
                };
            }, initialTotals);

            return [...observations, totals];
        }

        return [];
    };

    const overviewTables = get(overview, 'overviewTableRows', [[]] as IOverviewPatology[][]);

    const currentYearRecs = sortByInstitute(overviewTables.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear));
    const lastYearRecs = sortByInstitute(overviewTables.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear - 1));
    const curYearObsWithClmTotals = computeClmsTotals(currentYearRecs);
    const lastYearObsWithClmTotals = computeClmsTotals(lastYearRecs);

    const clms = [
        { name: t('generic.istitute'), id: 1 },
        { name: t('months.0'), id: 2 },
        { name: t('months.1'), id: 3 },
        { name: t('months.2'), id: 4 },
        { name: t('months.3'), id: 5 },
        { name: t('months.4'), id: 6 },
        { name: t('months.5'), id: 7 },
        { name: t('months.6'), id: 8 },
        { name: t('months.7'), id: 9 },
        { name: t('months.8'), id: 10 },
        { name: t('months.9'), id: 11 },
        { name: t('months.10'), id: 12 },
        { name: t('months.11'), id: 13 },
        { name: t('generic.recoverySdoFlow', { flow }), id: 14 },
    ];

    return (
        <Fragment>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: `overview-${flow}` }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                <CustomDivOverview>
                    <b>{getCurrentMonthAndYear().currentYear} </b>
                    {overview.permissionSet.canExportOverview ? <ExportExcelPatology currentYearRecs={curYearObsWithClmTotals} lastYearRecs={lastYearObsWithClmTotals} flow={flow} /> : <></>}
                </CustomDivOverview>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                curYearObsWithClmTotals.map((data, index) =>
                                    index !== curYearObsWithClmTotals.length - 1 ? (
                                        <TableRow key={index}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr key={index} style={{ borderTop: '3px solid black' }}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{getCurrentMonthAndYear().currentYear - 1}</b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                lastYearObsWithClmTotals.map((data, index) =>
                                    index !== lastYearObsWithClmTotals.length - 1 ? (
                                        <TableRow key={index}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr key={index} style={{ borderTop: '3px solid black' }}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Fragment>
    );
};

export default OverviewTablePatology;
