import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelDm from '../ExportExcel/ExportExcelDm';
import GenericError from '../GenericError/GenericError';
import { IOverviewDm } from '../../model';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { getCurrentMonthAndYear } from '../../utils';
import { t } from 'i18next';
import { useContext } from 'react';
import { useDmOverview } from '../../api/fetch';

interface IOverviewDmProps {}

const OverviewDm = (props: IOverviewDmProps) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useDmOverview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [[]] as IOverviewDm[][]);

    const sortByInstitute = (observations: IOverviewDm[]) => {
        const dataToBeSorted = [...observations];
        if (!!dataToBeSorted && dataToBeSorted.length > 0) {
            return dataToBeSorted.sort((a, b) => {
                const nameA = a.institute.toUpperCase();
                const nameB = b.institute.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        }
        return dataToBeSorted;
    };

    const currentYearRecs = sortByInstitute(overviewTableRows.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear));
    const lastYearRecs = sortByInstitute(overviewTableRows.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear - 1));

    const clms = [
        { name: t('genericOverview.healthCompany'), id: 1 },
        { name: t('genericOverview.measureDescription'), id: 2 },
        { name: t('months.0'), id: 3 },
        { name: t('months.1'), id: 4 },
        { name: t('months.2'), id: 5 },
        { name: t('months.3'), id: 6 },
        { name: t('months.4'), id: 7 },
        { name: t('months.5'), id: 8 },
        { name: t('months.6'), id: 9 },
        { name: t('months.7'), id: 10 },
        { name: t('months.8'), id: 11 },
        { name: t('months.9'), id: 12 },
        { name: t('months.10'), id: 13 },
        { name: t('months.11'), id: 14 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-dm' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                <CustomDivOverview>
                    <b>{getCurrentMonthAndYear().currentYear} </b>
                    {overview.permissionSet.canExportOverview ? <ExportExcelDm currentYearRecs={currentYearRecs} lastYearRecs={lastYearRecs} /> : <></>}
                </CustomDivOverview>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                currentYearRecs.length > 0 &&
                                currentYearRecs.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.consumptionCost')}</RightTd>
                                        <RightTd>{data.jan.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                            {!!overview &&
                                !isLoading &&
                                currentYearRecs.length > 0 &&
                                currentYearRecs.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.numberOfContracts')}</RightTd>
                                        <RightTd>{data.jan.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.nrContratti.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{getCurrentMonthAndYear().currentYear - 1}</b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                lastYearRecs.length > 0 &&
                                lastYearRecs.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.consumptionCost')}</RightTd>
                                        <RightTd>{data.jan.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.costoConsumi.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                            {!!overview &&
                                !isLoading &&
                                lastYearRecs.length > 0 &&
                                lastYearRecs.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{t('genericOverview.numberOfContracts')}</RightTd>
                                        <RightTd>{data.jan.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.nrContratti.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.nrContratti.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Container>
    );
};

export default OverviewDm;
