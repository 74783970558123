import { ChangeEventHandler, useId } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

interface ICustomCheckboxProps {
    label?: string;
    defaultChecked?: boolean;
    value?: boolean;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CustomCheckbox = (props: ICustomCheckboxProps) => {
    const { label, value, defaultChecked, disabled, onChange } = props;
    const id = useId();
    return (
        <CheckboxContainer>
            <InputGroup.Checkbox aria-label='Checkbox' id={id} checked={value} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange} />
            {label && <label htmlFor={id}>{label}</label>}
        </CheckboxContainer>
    );
};

export default CustomCheckbox;
