import CustomSvg from '../Svg/CustomSvg';
import Loading from '../Loading/Loading';
import { ToastWrapper } from '../../style';
import { t } from 'i18next';

interface LogInErrorProps {
    code: number;
}

const LogInError = (props: LogInErrorProps) => {
    const { code } = props;
    const depictMsg = (codeNumber: number) => {
        switch (codeNumber) {
            case 403:
                return t('generic.userNotRegistered');
            case 404:
                return t('generic.userNotFound');
            default:
                return 'Error';
        }
    };

    return (
        <>
            {code !== 404 && code !== 403 ? (
                <Loading />
            ) : (
                <>
                    <ToastWrapper>
                        <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
                    </ToastWrapper>
                    <ToastWrapper>
                        <blockquote className='blockquote'>
                            <p className='mb-0'>{depictMsg(code)}</p>
                        </blockquote>
                    </ToastWrapper>
                </>
            )}
        </>
    );
};

export default LogInError;
