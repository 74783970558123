import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export interface ILoadingProps {
    msg?: string;
}
const GenericError = (props: ILoadingProps) => {
    const { msg } = props;
    const { t } = useTranslation();
    return <ErrorContainer>{!!msg ? <p>{msg}</p> : <p>{t('generic.error')}</p>}</ErrorContainer>;
};

export default GenericError;
