import { ReactElement, memo } from 'react';

import { get } from 'lodash';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

interface ICustomSelect {
    name: string;
    parentOnChange: (value: any) => void;
    options: { value: string | number; label: string | number | ReactElement; id: string | number }[];
    required?: boolean;
    label?: string;
    firstOption?: string;
    firstOptionDisabled?: boolean;
}

const StyledSelect = styled.select`
    height: auto;
    width: 100%;
`;
const CustomSelect = memo((props: ICustomSelect) => {
    const { setFieldValue, values } = useFormikContext();
    const { t } = useTranslation();
    const { name, parentOnChange, options, label, required, firstOption, firstOptionDisabled } = props;
    const dValue = get(values, `[${name}]`);
    const dValueObj = get(values, `[${name}].value`);
    const id = crypto.randomUUID();

    return (
        <div className='select-wrapper'>
            {label && <label htmlFor={id}>{label}</label>}
            <StyledSelect
                id={id}
                name={name}
                required={required}
                value={!!dValueObj ? dValueObj : !!dValue ? dValue : ''}
                onInvalid={(event: React.InvalidEvent<HTMLSelectElement>) => {
                    event.target.setCustomValidity(t('generic.invalidSelect'));
                }}
                onInput={(event: React.FormEvent<HTMLSelectElement>) => {
                    event.currentTarget.setCustomValidity('');
                }}
                onChange={event => {
                    setFieldValue(name, event.target.value);
                    if (!!parentOnChange) parentOnChange(event.target.value);
                }}>
                <option value='' disabled={firstOptionDisabled}>
                    {firstOption ? firstOption : t('generic.selectOption')}
                </option>
                {options.map(data => (
                    <option key={data.id} value={data.value}>
                        {data.label}
                    </option>
                ))}
            </StyledSelect>
        </div>
    );
});
export default CustomSelect;
