import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import NewStructures from './NewStructures';
import { useParams } from 'react-router-dom';
import { useStructuresById } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

interface IEditStructureProps {}

const EditStructure = (props: IEditStructureProps) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { structure, isError, isLoading, isValidating } = useStructuresById(id);

    return (
        <Container>
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!isLoading && !isError && !!structure && !isValidating && <NewStructures structureToEdit={structure} />}
        </Container>
    );
};

export default EditStructure;
