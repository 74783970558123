import { Fragment, useEffect, useState } from 'react';
import { IAllowedFlows, IPermissionBe } from '../../model';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

import CustomSvg from '../Svg/CustomSvg';
import { IconBtn } from './Buttons';
import ModalProgress from '../Indicators/ModalProgress';
import { OverlayChild } from '../../style';
import { downloadFile } from '../../utils';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { useLogFile } from '../../api/fetch';

interface ILogFileButtonProps {
    id: number;
    loggedUser: {
        userId: number;
        cf: string;
        msgs: {
            messageIt: string;
            messageDe: string;
            messageId: string;
        }[];
        role: string;
        allowedFlows: IAllowedFlows[];
    };
    refreshShippings: () => void;
    permissions: IPermissionBe;
    logFileName: string;
    successRatio: number;
    isSdo: boolean;
}

const LogFileButton = (props: ILogFileButtonProps) => {
    const { id, loggedUser, refreshShippings, permissions, logFileName, isSdo, successRatio } = props;
    const [startFetch, setStartFetch] = useState<number | undefined>(undefined);
    const [viewModal, setViewModal] = useState<boolean>(false);
    const { isLoading, log, logError, progressData, isValidating } = useLogFile(startFetch, loggedUser.userId, isSdo);

    useEffect(() => {
        if (logError) {
            toast.error(t('generic.error'));
            setStartFetch(undefined);
        }
    }, [logError]);

    useEffect(() => {
        if (!!log) {
            refreshShippings();
            downloadFile(log);
            setStartFetch(undefined);
            setViewModal(false);
        }
    }, [log]);

    useEffect(() => {
        if (!log && Array.isArray(progressData)) {
            setViewModal(true);
        }
    }, [log, progressData]);

    const handleClose = () => {
        setStartFetch(undefined);
        setViewModal(false);
    };

    return (
        <Fragment>
            {!!permissions &&
                successRatio < 100 &&
                (permissions.canDownloadFiles || permissions.canDowloadLogSynthetic) &&
                ((isLoading || isValidating) && !viewModal ? (
                    <div id='centeredLoadButton'>
                        <Spinner />
                    </div>
                ) : (
                    <Fragment>
                        <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{logFileName}</Tooltip>}>
                            <OverlayChild>
                                <IconBtn
                                    withOutline
                                    onClick={() => {
                                        setStartFetch(id);
                                    }}>
                                    <CustomSvg iconName='it-file' className='icon icon-primary' />
                                </IconBtn>
                            </OverlayChild>
                        </OverlayTrigger>
                        <ModalProgress onClose={handleClose} vieModal={viewModal} progressData={progressData} />
                    </Fragment>
                ))}
        </Fragment>
    );
};
export default LogFileButton;
