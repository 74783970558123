import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import NewUser from './NewUser';
import { route } from '../../route';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../api/fetch';

interface IEditUserProps {}

const EditUser = (props: IEditUserProps) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { user, isLoading, isError, isValidating } = useUser(id);
    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.users'), url: '/' + route.users },
                    { nameToShow: t('users.edit'), url: '' },
                ]}
            />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!!user && !isValidating && <NewUser userToEdit={{ ...user, userEmail: user.userEmail ? user.userEmail : '' }} />}
        </Container>
    );
};

export default EditUser;
