import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import CustomSvg from '../Svg/CustomSvg';
import { InfoStyledBtn } from '../../style';

interface InfoButtonProps {
    shippingId?: number;
    filesNames: string[];
}

const InfoButton = ({ shippingId, filesNames }: InfoButtonProps) => (
    <OverlayTrigger
        key={shippingId}
        placement='top'
        transition={false}
        i18nIsDynamicList
        overlay={
            <Tooltip>
                {filesNames.map((name, index) => (
                    <div key={name + index}>{name}</div>
                ))}
            </Tooltip>
        }>
        <InfoStyledBtn>
            <CustomSvg height='1.5rem' width='1.5rem' iconName='it-info-circle' className='icon-primary' />
        </InfoStyledBtn>
    </OverlayTrigger>
);

export default InfoButton;
