import { EFlowType, FileType } from '../../model/enum';

import CustomSvg from '../Svg/CustomSvg';
import { IShippingErrors } from '../../model';
import { IconBtn } from '../Buttons/Buttons';
import { ToastWrapper } from '../../style';
import { displayFileName } from '../../utils';
import styled from 'styled-components';
import { t } from 'i18next';
import toast from 'react-hot-toast';

const ToastBtnWrapper = styled.div`
    display: flex;
    justify-content: right;
    align-items: flex-start;
    min-height: 3rem;
    width: 100%;
    position: relative;

    @media screen and (max-width: 800px) {
        justify-content: right;
        min-height: 2.5rem;
    }
`;

const DivToastContainer = styled.div`
    padding: 1rem 0.5rem 2rem 1rem !important;
    @media screen and (max-width: 800px) {
        padding: 0.5rem 0.25rem 1rem 0.5rem !important;
    }
`;

const DivToastMsg = styled.div`
    display: flex !important;
    padding-right: 0.5rem;
    @media screen and (max-width: 800px) {
        flex-direction: row;
        padding-right: 0;
    }
`;

const BlockquoteToast = styled.blockquote`
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 8px;
    margin-left: 0.5rem;
    /* border-left: 8px solid #0bd9d2; */
    padding: 0.25rem;
    @media screen and (max-width: 800px) {
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 4px;
        margin-left: 0.25rem;
    }
`;

const ScrollingMessages = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 6rem;
    overflow-y: auto;
    row-gap: 4px !important;
    @media screen and (max-width: 800px) {
        flex-wrap: nowrap;
        max-height: 4rem;
        row-gap: 2px !important;
    }
`;

const TopLeftComponent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    color: #006eff;
    font-weight: bold;
    padding: 10px;
    @media screen and (max-width: 800px) {
        padding: 5px; /* Adjust as needed */
        font-size: 0.875rem;
    }
`;

export const displayMsgErrors = (shippingWithError: IShippingErrors[], flow: EFlowType) => {
    toast.custom(
        to => (
            <div className={`${to.visible ? 'animate-enter' : 'animate-leave'} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto d-flex ring-1 ring-black ring-opacity-5`}>
                <div className='flex-1 w-0 '>
                    <DivToastContainer>
                        <ToastBtnWrapper>
                            {flow === EFlowType.Sdo && <TopLeftComponent>SDO</TopLeftComponent>}
                            {flow === EFlowType.Sdor && <TopLeftComponent>SDO-R</TopLeftComponent>}
                            <IconBtn
                                type='button'
                                onClick={() => {
                                    toast.dismiss(to.id);
                                }}>
                                <CustomSvg iconName='it-close-circle' className='icon icon-primary' />
                            </IconBtn>
                        </ToastBtnWrapper>
                        <DivToastMsg>
                            <ToastWrapper>
                                <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
                            </ToastWrapper>
                            <ToastWrapper>
                                <ScrollingMessages>
                                    {shippingWithError.map(x => (
                                        <div key={x.id}>
                                            {x.fileErrorsSummary.map(y => (
                                                <BlockquoteToast key={x.id + y.fileName}>
                                                    <p className='mb-0'>
                                                        {t('generic.toastErrorMsg', {
                                                            shippingId: x.id,
                                                            errors: y.totalErrors,
                                                            rows: y.totalRows,
                                                            fileName: displayFileName(y.fileName),
                                                        })}
                                                    </p>
                                                </BlockquoteToast>
                                            ))}
                                        </div>
                                    ))}
                                </ScrollingMessages>
                            </ToastWrapper>
                        </DivToastMsg>
                    </DivToastContainer>
                </div>
            </div>
        ),
        { duration: 1000000 },
    );
};
