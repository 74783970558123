import { SVGProps } from 'react';
import Sprite from 'bootstrap-italia/dist/svg/sprites.svg';

interface ICustomSvgProps extends SVGProps<SVGSVGElement> {
    iconName: string;
    className?: string;
}
const CustomSvg = ({ iconName, className = 'icon d-none d-lg-block', ...other }: ICustomSvgProps) => {
    return (
        <svg className={className} {...other}>
            <use href={`${Sprite}#${iconName}`}></use>
        </svg>
    );
};

export default CustomSvg;
