import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';
import { Fragment, useContext } from 'react';
import { IDataObject, IMonthlyData, IOverview118 } from '../../model';
import { getCurrentMonthAndYear, getMonthNameByApi } from '../../utils';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcel118 from '../ExportExcel/ExportExcel118';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { t } from 'i18next';
import { use118Overview } from '../../api/fetch';

interface IOverview118Props {}
const Overview118 = (props: IOverview118Props) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = use118Overview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [] as IOverview118[]);
    const currentYearRecs = overviewTableRows.filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear);
    const lastYearRecs = overviewTableRows.filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear - 1);
    const curData = get(currentYearRecs, '[0].data', {} as IDataObject);
    const lastYearData = get(lastYearRecs, '[0].data', {} as IDataObject);

    const computeDifferences = (currentData: IDataObject, lastData: IDataObject) => {
        let differences: IDataObject = {
            jan: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            feb: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            mar: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            apr: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            may: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            jun: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            jul: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            aug: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            sep: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            oct: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            nov: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            dec: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
            tot: { chiamate: 0, interventi: 0, missioni: 0, assistiti: 0 },
        };
        if (!!currentData && !!lastData) {
            Object.entries(currentData).forEach(([currKey, currValue]) => {
                Object.entries(lastData).forEach(([lastKey, lastValue]) => {
                    if (currKey === lastKey) {
                        differences[currKey] = {
                            chiamate: currValue.chiamate - lastValue.chiamate,
                            interventi: currValue.interventi - lastValue.interventi,
                            missioni: currValue.missioni - lastValue.missioni,
                            assistiti: currValue.assistiti - lastValue.assistiti,
                        };
                    }
                });
            });
        }

        return differences;
    };

    const differences = computeDifferences(curData, lastYearData);

    const clms = [
        { name: t('genericOverview.clmMonth'), id: 1 },
        { name: t('genericOverview.calls'), id: 2 },
        { name: t('genericOverview.interventions'), id: 3 },
        { name: t('genericOverview.missions'), id: 4 },
        { name: t('genericOverview.assisted'), id: 5 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-118' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                <CustomDivOverview>
                    <b>{getCurrentMonthAndYear().currentYear}</b>
                    {overview.permissionSet.canExportOverview ? <ExportExcel118 currentYearRecs={currentYearRecs} lastYearRecs={lastYearRecs} differences={differences} /> : <></>}
                </CustomDivOverview>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                currentYearRecs.length > 0 &&
                                currentYearRecs.map(data =>
                                    Object.entries(data.data).map(([monthName, monthData]: [string, IMonthlyData], monthIndex) =>
                                        Object.entries(data.data).length - 1 !== monthIndex ? (
                                            <TableRow key={`${monthIndex}-${monthName}-${monthData.chiamate}-${monthData.interventi}-${monthData.missioni}-${monthData.assistiti}`}>
                                                <td>{t(getMonthNameByApi(monthName))}</td>
                                                <RightTd>{monthData.chiamate.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.interventi.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.missioni.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.assistiti.toLocaleString('de-DE')}</RightTd>
                                            </TableRow>
                                        ) : (
                                            <tr
                                                style={{ borderTop: '3px solid black' }}
                                                key={`${monthIndex}-${monthName}-${monthData.chiamate}-${monthData.interventi}-${monthData.missioni}-${monthData.assistiti}`}>
                                                <td>{t(getMonthNameByApi(monthName))}</td>
                                                <RightTd>{monthData.chiamate.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.interventi.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.missioni.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.assistiti.toLocaleString('de-DE')}</RightTd>
                                            </tr>
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{getCurrentMonthAndYear().currentYear - 1}</b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                lastYearRecs.length > 0 &&
                                lastYearRecs.map(data =>
                                    Object.entries(data.data).map(([monthName, monthData]: [string, IMonthlyData], monthIndex) =>
                                        Object.entries(data.data).length - 1 !== monthIndex ? (
                                            <TableRow key={`${monthIndex}-${monthName}-${monthData.chiamate}-${monthData.interventi}-${monthData.missioni}-${monthData.assistiti}`}>
                                                <td>{t(getMonthNameByApi(monthName))}</td>
                                                <RightTd>{monthData.chiamate.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.interventi.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.missioni.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.assistiti.toLocaleString('de-DE')}</RightTd>
                                            </TableRow>
                                        ) : (
                                            <tr
                                                style={{ borderTop: '3px solid black' }}
                                                key={`${monthIndex}-${monthName}-${monthData.chiamate}-${monthData.interventi}-${monthData.missioni}-${monthData.assistiti}`}>
                                                <td>{t(getMonthNameByApi(monthName))}</td>
                                                <RightTd>{monthData.chiamate.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.interventi.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.missioni.toLocaleString('de-DE')}</RightTd>
                                                <RightTd>{monthData.assistiti.toLocaleString('de-DE')}</RightTd>
                                            </tr>
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{`${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1}`} </b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                Object.entries(differences).map(([monthName, monthData]: [string, IMonthlyData], monthIndex) =>
                                    Object.entries(differences).length - 1 !== monthIndex ? (
                                        <TableRow key={`${monthIndex}-${monthName}-${monthData.chiamate}-${monthData.interventi}-${monthData.missioni}-${monthData.assistiti}`}>
                                            <td>{t(getMonthNameByApi(monthName))}</td>
                                            <RightTd>{monthData.chiamate.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{monthData.interventi.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{monthData.missioni.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{monthData.assistiti.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr
                                            style={{ borderTop: '3px solid black' }}
                                            key={`${monthIndex}-${monthName}-${monthData.chiamate}-${monthData.interventi}-${monthData.missioni}-${monthData.assistiti}`}>
                                            <td>{t(getMonthNameByApi(monthName))}</td>
                                            <RightTd>{monthData.chiamate.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{monthData.interventi.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{monthData.missioni.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{monthData.assistiti.toLocaleString('de-DE')}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Container>
    );
};

export default Overview118;
