import { IconButtonNoStyle, IconButtonUnderlined, IconButtonUnderlinedLink } from '../../style/buttons/buttons';

import { forwardRef } from 'react';

interface IBtn extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string;
    withOutline?: boolean;
    borderProps?: string;
}
export const Btn = ({ className, text, type, ...args }: IBtn) => (
    <button {...args} className={className ? className : 'btn btn-primary'} type={type ? type : 'button'}>
        {text}
    </button>
);

export const BtnOutLine = ({ className, text, type, ...args }: IBtn) => (
    <button {...args} className={className ? className : 'btn btn-outline-primary'} type={type ? type : 'button'}>
        {text}
    </button>
);
export const UnderlinedBtn = ({ className, text, type, ...args }: IBtn) => (
    <IconButtonUnderlined {...args} className={className ? className : ''} type={type ? type : 'button'}>
        {text}
    </IconButtonUnderlined>
);

export const IconBtn = forwardRef(({ className, type, children, withOutline, borderProps, ...args }: IBtn, _ref: any) => (
    <IconButtonNoStyle {...args} type={type ? type : 'button'} $border={withOutline ? true : false} $borderProps={borderProps}>
        {children}
    </IconButtonNoStyle>
));

export const UnderlinedBtnLink = ({ className, text, type, ...args }: IBtn) => (
    <IconButtonUnderlinedLink {...args} className={className ? className : ''} type={type ? type : 'button'}>
        {text}
    </IconButtonUnderlinedLink>
);
