import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';
import { Fragment, useContext } from 'react';
import { IOverviewSpa, IOverviewSpaData } from '../../model';
import { getCurrentMonthAndYear, getMonthNameByApi } from '../../utils';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelSpa from '../ExportExcel/ExportExcelSpa';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { t } from 'i18next';
import { useSpaOverview } from '../../api/fetch';

interface IOverviewSpaProps {}
const OverviewSpa = (props: IOverviewSpaProps) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useSpaOverview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [] as IOverviewSpa[]);

    const currentYearRecs = overviewTableRows.filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear);
    const lastYearRecs = overviewTableRows.filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear - 1);
    const currYearData = get(currentYearRecs, '[0].data', [] as IOverviewSpaData[]);
    const lastYearData = get(lastYearRecs, '[0].data', [] as IOverviewSpaData[]);
    const currYear = get(currentYearRecs, '[0].year', '');
    const lastYear = get(lastYearRecs, '[0].year', '');

    const sortByInstitute = (a: IOverviewSpaData, b: IOverviewSpaData): number => {
        if (a.institute < b.institute) {
            return -1;
        }
        if (a.institute > b.institute) {
            return 1;
        }
        return 0;
    };

    const computeDifferences = (currentData: IOverviewSpaData[], lastData: IOverviewSpaData[]) => {
        let differences: IOverviewSpaData[] = [];

        if (!!currentData && !!lastData && currentData.length > 0 && lastData.length > 0) {
            currentData.sort(sortByInstitute);
            lastData.sort(sortByInstitute);
            currentData.forEach(currData => {
                const matchingLastData = lastData.find(last => last.institute === currData.institute);

                if (matchingLastData) {
                    let nextInstituteDifference: IOverviewSpaData = {
                        institute: currData.institute,
                        jan: {
                            bianca: currData.jan.bianca - matchingLastData.jan.bianca,
                            rossa: currData.jan.rossa - matchingLastData.jan.rossa,
                            elettronica: currData.jan.elettronica - matchingLastData.jan.elettronica,
                            totale: currData.jan.totale - matchingLastData.jan.totale,
                        },
                        feb: {
                            bianca: currData.feb.bianca - matchingLastData.feb.bianca,
                            rossa: currData.feb.rossa - matchingLastData.feb.rossa,
                            elettronica: currData.feb.elettronica - matchingLastData.feb.elettronica,
                            totale: currData.feb.totale - matchingLastData.feb.totale,
                        },
                        mar: {
                            bianca: currData.mar.bianca - matchingLastData.mar.bianca,
                            rossa: currData.mar.rossa - matchingLastData.mar.rossa,
                            elettronica: currData.mar.elettronica - matchingLastData.mar.elettronica,
                            totale: currData.mar.totale - matchingLastData.mar.totale,
                        },
                        apr: {
                            bianca: currData.apr.bianca - matchingLastData.apr.bianca,
                            rossa: currData.apr.rossa - matchingLastData.apr.rossa,
                            elettronica: currData.apr.elettronica - matchingLastData.apr.elettronica,
                            totale: currData.apr.totale - matchingLastData.apr.totale,
                        },
                        may: {
                            bianca: currData.may.bianca - matchingLastData.may.bianca,
                            rossa: currData.may.rossa - matchingLastData.may.rossa,
                            elettronica: currData.may.elettronica - matchingLastData.may.elettronica,
                            totale: currData.may.totale - matchingLastData.may.totale,
                        },
                        jun: {
                            bianca: currData.jun.bianca - matchingLastData.jun.bianca,
                            rossa: currData.jun.rossa - matchingLastData.jun.rossa,
                            elettronica: currData.jun.elettronica - matchingLastData.jun.elettronica,
                            totale: currData.jun.totale - matchingLastData.jun.totale,
                        },
                        jul: {
                            bianca: currData.jul.bianca - matchingLastData.jul.bianca,
                            rossa: currData.jul.rossa - matchingLastData.jul.rossa,
                            elettronica: currData.jul.elettronica - matchingLastData.jul.elettronica,
                            totale: currData.jul.totale - matchingLastData.jul.totale,
                        },
                        aug: {
                            bianca: currData.aug.bianca - matchingLastData.aug.bianca,
                            rossa: currData.aug.rossa - matchingLastData.aug.rossa,
                            elettronica: currData.aug.elettronica - matchingLastData.aug.elettronica,
                            totale: currData.aug.totale - matchingLastData.aug.totale,
                        },
                        sep: {
                            bianca: currData.sep.bianca - matchingLastData.sep.bianca,
                            rossa: currData.sep.rossa - matchingLastData.sep.rossa,
                            elettronica: currData.sep.elettronica - matchingLastData.sep.elettronica,
                            totale: currData.sep.totale - matchingLastData.sep.totale,
                        },
                        oct: {
                            bianca: currData.oct.bianca - matchingLastData.oct.bianca,
                            rossa: currData.oct.rossa - matchingLastData.oct.rossa,
                            elettronica: currData.oct.elettronica - matchingLastData.oct.elettronica,
                            totale: currData.oct.totale - matchingLastData.oct.totale,
                        },
                        nov: {
                            bianca: currData.nov.bianca - matchingLastData.nov.bianca,
                            rossa: currData.nov.rossa - matchingLastData.nov.rossa,
                            elettronica: currData.nov.elettronica - matchingLastData.nov.elettronica,
                            totale: currData.nov.totale - matchingLastData.nov.totale,
                        },
                        dec: {
                            bianca: currData.dec.bianca - matchingLastData.dec.bianca,
                            rossa: currData.dec.rossa - matchingLastData.dec.rossa,
                            elettronica: currData.dec.elettronica - matchingLastData.dec.elettronica,
                            totale: currData.dec.totale - matchingLastData.dec.totale,
                        },
                        tot: {
                            bianca: currData.tot.bianca - matchingLastData.tot.bianca,
                            rossa: currData.tot.rossa - matchingLastData.tot.rossa,
                            elettronica: currData.tot.elettronica - matchingLastData.tot.elettronica,
                            totale: currData.tot.totale - matchingLastData.tot.totale,
                        },
                    };

                    differences = [...differences, nextInstituteDifference];
                }
            });
        }

        return differences;
    };

    const differences = computeDifferences(currYearData, lastYearData);

    const clms = [
        { name: t('genericOverview.institute'), id: 1 },
        { name: t('genericOverview.clmMonth'), id: 2 },
        { name: t('genericOverview.white'), id: 3 },
        { name: t('genericOverview.red'), id: 4 },
        { name: t('genericOverview.electric'), id: 5 },
        { name: t('generic.total'), id: 6 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-spa' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                <CustomDivOverview>
                    <b>{currYear}</b>
                    {overview.permissionSet.canExportOverview ? (
                        <ExportExcelSpa currentYearRecs={{ data: currYearData, year: currYear }} lastYearRecs={{ data: lastYearData, year: lastYear }} differences={differences} />
                    ) : (
                        <></>
                    )}
                </CustomDivOverview>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                currentYearRecs.length > 0 &&
                                currentYearRecs.map(year =>
                                    year.data.map(instituteData =>
                                        Object.entries(instituteData).map(([key, value], index) =>
                                            key !== 'institute' ? (
                                                <TableRow key={`${instituteData.institute}-${key}-${index}`}>
                                                    <td>{instituteData.institute}</td>
                                                    <RightTd>{t(getMonthNameByApi(key))}</RightTd>
                                                    <RightTd>{value.bianca.toLocaleString('de-DE')}</RightTd>
                                                    <RightTd>{value.rossa.toLocaleString('de-DE')} </RightTd>
                                                    <RightTd>{value.elettronica.toLocaleString('de-DE')}</RightTd>
                                                    <RightTd>{value.totale.toLocaleString('de-DE')}</RightTd>
                                                </TableRow>
                                            ) : null,
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{lastYear}</b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                lastYearRecs.length > 0 &&
                                lastYearRecs.map((year, yearIndex) => (
                                    <Fragment key={yearIndex}>
                                        {year.data.map((instituteData, instituteDataIndex) => (
                                            <Fragment key={`${instituteData.institute}-${instituteDataIndex}`}>
                                                {Object.entries(instituteData).map(([key, value], index) =>
                                                    key !== 'institute' ? (
                                                        <TableRow key={`${instituteData.institute}-${key}-${index}`}>
                                                            <td>{instituteData.institute}</td>
                                                            <RightTd>{t(getMonthNameByApi(key))}</RightTd>
                                                            <RightTd>{value.bianca.toLocaleString('de-DE')}</RightTd>
                                                            <RightTd>{value.rossa.toLocaleString('de-DE')} </RightTd>
                                                            <RightTd>{value.elettronica.toLocaleString('de-DE')}</RightTd>
                                                            <RightTd>{value.totale.toLocaleString('de-DE')}</RightTd>
                                                        </TableRow>
                                                    ) : null,
                                                )}
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                {!!overview && !isLoading && differences.length > 0 && <b>{`${currYear + '/' + lastYear}`}</b>}
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                differences.length > 0 &&
                                differences.map((instituteData, index) => (
                                    <Fragment key={`${instituteData.institute}-${index}`}>
                                        {Object.entries(instituteData).map(([key, value], entryIndex) =>
                                            key !== 'institute' ? (
                                                <TableRow key={`${instituteData.institute}-${key}-${entryIndex}`}>
                                                    <td>{instituteData.institute}</td>
                                                    <RightTd>{t(getMonthNameByApi(key))}</RightTd>
                                                    <RightTd>{value.bianca.toLocaleString('de-DE')}</RightTd>
                                                    <RightTd>{value.rossa.toLocaleString('de-DE')} </RightTd>
                                                    <RightTd>{value.elettronica.toLocaleString('de-DE')}</RightTd>
                                                    <RightTd>{value.totale.toLocaleString('de-DE')}</RightTd>
                                                </TableRow>
                                            ) : null,
                                        )}
                                    </Fragment>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Container>
    );
};

export default OverviewSpa;
