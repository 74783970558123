import { Btn } from '../componets/Buttons/Buttons';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

interface ILandingProps {
    loading: boolean;
}

const LandingContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #06c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 200px;
    width: 200px;
    border-radius: 4px;
    align-items: center;
    gap: 16px;
    opacity: 1;
`;
const ImgContainer = styled.div`
    position: absolute;
    top: 80px;
    left: 50%;
`;
const Landing = (props: ILandingProps) => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const { loading } = props;

    const login = async () => {
        await instance.handleRedirectPromise();
        await instance.loginRedirect({ scopes: [process.env.REACT_APP_SCOPE ?? ''], extraQueryParameters: { spidl: '2', authLogin: 'spidpf,cie,cns,aad' } });
    };
    useEffect(() => {
        login();
    }, []);

    return (
        <LandingContainer>
            {/* <ImgContainer>
                <img src='' />
            </ImgContainer> */}
            <BodyContainer>
                <Btn text='Login' onClick={() => instance.loginRedirect({ scopes: [process.env.REACT_APP_SCOPE ?? ''], extraQueryParameters: { spidl: '2', authLogin: 'spidpf,cie,cns,aad' } })}></Btn>
            </BodyContainer>
        </LandingContainer>
    );
};

export default Landing;
