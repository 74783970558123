import { Container, ContainerAccordion, CustomAccordionBody, PageTitle } from '../../style';
import { useContext, useEffect, useState } from 'react';

import { Accordion } from 'react-bootstrap';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { LoggedUserContext } from '../../App';
import SendFilesSdo from '../../componets/SendFilesSection/SendFilesSdo';
import SendFilesSdor from '../../componets/SendFilesSection/SendFilesSdor';
import { isSiag } from '../../utils';
import { route } from '../../route';
import { t } from 'i18next';

const SendFiles = () => {
    const { loggedUser } = useContext(LoggedUserContext);
    const isSiagUser = isSiag(loggedUser.role);
    const [viewSdo, setViewSdo] = useState<boolean>(false);
    const [viewSdoR, setViewSdoR] = useState<boolean>(false);

    useEffect(() => {
        if (!isSiagUser) {
            const sdoFlow = loggedUser.allowedFlows.find(x => x.name === 'SDO');
            const sdoRFlow = loggedUser.allowedFlows.find(x => x.name === 'SDO-R');
            setViewSdo(sdoFlow ? sdoFlow.isAllowed : false);
            setViewSdoR(sdoRFlow ? sdoRFlow.isAllowed : false);
        } else {
            setViewSdo(true);
            setViewSdoR(true);
        }
    }, []);

    return (
        <>
            {viewSdo && viewSdoR && (
                <>
                    <BreadCrumb paths={[{ nameToShow: t('routes.sendFile'), url: route.sendFile }]} />
                    <ContainerAccordion>
                        <Accordion defaultActiveKey='2' flush>
                            <Accordion.Item eventKey='2'>
                                <Accordion.Header>{t('routes.sdo')}</Accordion.Header>
                                <CustomAccordionBody>
                                    <SendFilesSdo />
                                </CustomAccordionBody>
                            </Accordion.Item>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>{t('routes.sdoR')}</Accordion.Header>
                                <CustomAccordionBody>
                                    <SendFilesSdor />
                                </CustomAccordionBody>
                            </Accordion.Item>
                        </Accordion>
                    </ContainerAccordion>
                </>
            )}
            {viewSdo && !viewSdoR && (
                <Container>
                    <BreadCrumb paths={[{ nameToShow: t('routes.sendFile'), url: route.sendFile }]} />
                    <PageTitle>{t('routes.sdo')}</PageTitle>
                    <SendFilesSdo />
                </Container>
            )}
            {!viewSdo && viewSdoR && (
                <Container>
                    <BreadCrumb paths={[{ nameToShow: t('routes.sendFile'), url: route.sendFile }]} />
                    <PageTitle>{t('routes.sdoR')}</PageTitle>
                    <SendFilesSdor />
                </Container>
            )}
        </>
    );
};

export default SendFiles;
