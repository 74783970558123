import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';
import { IOverviewSps, IOverviewSpsPercentage } from '../../model';
import { getCurrentMonthAndYear, sortByInstitute } from '../../utils';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelSps from '../ExportExcel/ExportExcelSps';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { t } from 'i18next';
import { useContext } from 'react';
import { useSpsOverview } from '../../api/fetch';

interface OverviewSpsProps {}

const OverviewSps = (props: OverviewSpsProps) => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useSpsOverview(loggedUser.userId);

    const overviewTableRows = get(overview, 'overviewTableRows', [] as IOverviewSps[][]);

    const currentYearRecs = overviewTableRows.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear);
    const lastYearRecs = overviewTableRows.flat().filter(x => Number(x.year) === getCurrentMonthAndYear().currentYear - 1);

    const computeClmsTotals = (observations: IOverviewSps[]) => {
        if (!!observations && observations.length > 0) {
            const initialTotals: IOverviewSps = {
                year: '',
                institute: '',
                jan: 0,
                feb: 0,
                mar: 0,
                apr: 0,
                may: 0,
                jun: 0,
                jul: 0,
                aug: 0,
                sep: 0,
                oct: 0,
                nov: 0,
                dec: 0,
                tot: 0,
            };

            const totals = observations.reduce((accumulator, current) => {
                return {
                    year: current.year,
                    institute: t('generic.total'),
                    jan: accumulator.jan + current.jan,
                    feb: accumulator.feb + current.feb,
                    mar: accumulator.mar + current.mar,
                    apr: accumulator.apr + current.apr,
                    may: accumulator.may + current.may,
                    jun: accumulator.jun + current.jun,
                    jul: accumulator.jul + current.jul,
                    aug: accumulator.aug + current.aug,
                    sep: accumulator.sep + current.sep,
                    oct: accumulator.oct + current.oct,
                    nov: accumulator.nov + current.nov,
                    dec: accumulator.dec + current.dec,
                    tot: accumulator.tot + current.tot,
                };
            }, initialTotals);

            return [...observations, totals];
        }
        return [];
    };

    const curYearObsWithClmTotals = computeClmsTotals(currentYearRecs);
    const lastYearObsWithClmTotals = computeClmsTotals(lastYearRecs);

    const computePercIndexes = (curYearRecs: IOverviewSps[], pastYearRecs: IOverviewSps[]) => {
        let arr: IOverviewSpsPercentage[] = [];

        if (!!curYearRecs && !!pastYearRecs && curYearRecs.length > 0 && pastYearRecs.length > 0) {
            sortByInstitute(curYearRecs);
            sortByInstitute(pastYearRecs);
            curYearRecs.forEach(cur => {
                pastYearRecs.forEach(past => {
                    if (cur.institute === past.institute) {
                        let institute = cur.institute;
                        let jan = past.jan !== 0 ? ((cur.jan - past.jan) / past.jan) * 100 : 0;
                        let feb = past.feb !== 0 ? ((cur.feb - past.feb) / past.feb) * 100 : 0;
                        let mar = past.mar !== 0 ? ((cur.mar - past.mar) / past.mar) * 100 : 0;
                        let apr = past.apr !== 0 ? ((cur.apr - past.apr) / past.apr) * 100 : 0;
                        let may = past.may !== 0 ? ((cur.may - past.may) / past.may) * 100 : 0;
                        let jul = past.jul !== 0 ? ((cur.jul - past.jul) / past.jul) * 100 : 0;
                        let jun = past.jun !== 0 ? ((cur.jun - past.jun) / past.jun) * 100 : 0;
                        let aug = past.aug !== 0 ? ((cur.aug - past.aug) / past.aug) * 100 : 0;
                        let sep = past.sep !== 0 ? ((cur.sep - past.sep) / past.sep) * 100 : 0;
                        let oct = past.oct !== 0 ? ((cur.oct - past.oct) / past.oct) * 100 : 0;
                        let nov = past.nov !== 0 ? ((cur.nov - past.nov) / past.nov) * 100 : 0;
                        let dec = past.dec !== 0 ? ((cur.dec - past.dec) / past.dec) * 100 : 0;
                        let tot = past.tot !== 0 ? ((cur.tot - past.tot) / past.tot) * 100 : 0;

                        let nextRow: IOverviewSpsPercentage = {
                            institute: institute,
                            year: `${cur.year + '/' + past.year}`,
                            jan: `${Math.round(jan)} %`,
                            feb: `${Math.round(feb)} %`,
                            mar: `${Math.round(mar)} %`,
                            apr: `${Math.round(apr)} %`,
                            may: `${Math.round(may)} %`,
                            jul: `${Math.round(jul)} %`,
                            jun: `${Math.round(jun)} %`,
                            aug: `${Math.round(aug)} %`,
                            sep: `${Math.round(sep)} %`,
                            oct: `${Math.round(oct)} %`,
                            nov: `${Math.round(nov)} %`,
                            dec: `${Math.round(dec)} %`,
                            tot: `${Math.round(tot)} %`,
                        };
                        arr = [...arr, nextRow];
                    }
                });
            });
        }
        return arr;
    };

    const percIndexesWithTotals = computePercIndexes(curYearObsWithClmTotals, lastYearObsWithClmTotals);

    const clms = [
        { name: t('generic.istitute'), id: 1 },
        { name: t('months.0'), id: 2 },
        { name: t('months.1'), id: 3 },
        { name: t('months.2'), id: 4 },
        { name: t('months.3'), id: 5 },
        { name: t('months.4'), id: 6 },
        { name: t('months.5'), id: 7 },
        { name: t('months.6'), id: 8 },
        { name: t('months.7'), id: 9 },
        { name: t('months.8'), id: 10 },
        { name: t('months.9'), id: 11 },
        { name: t('months.10'), id: 12 },
        { name: t('months.11'), id: 13 },
        { name: t('generic.total'), id: 14 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-sps' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                <CustomDivOverview>
                    <b>{getCurrentMonthAndYear().currentYear} </b>
                    {overview.permissionSet.canExportOverview ? (
                        <ExportExcelSps currentYearRecs={curYearObsWithClmTotals} lastYearRecs={lastYearObsWithClmTotals} percIndexes={percIndexesWithTotals} />
                    ) : (
                        <></>
                    )}
                </CustomDivOverview>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                curYearObsWithClmTotals.length > 0 &&
                                curYearObsWithClmTotals.map((data, index) =>
                                    curYearObsWithClmTotals.length - 1 !== index ? (
                                        <TableRow key={index}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr key={index} style={{ borderTop: '3px solid black' }}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{getCurrentMonthAndYear().currentYear - 1}</b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                lastYearObsWithClmTotals.length > 0 &&
                                lastYearObsWithClmTotals.map((data, index) =>
                                    lastYearObsWithClmTotals.length - 1 !== index ? (
                                        <TableRow key={index}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr key={index} style={{ borderTop: '3px solid black' }}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                            <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                <b>{`${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1}`} </b>
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                percIndexesWithTotals.length > 0 &&
                                percIndexesWithTotals.map((data, index) =>
                                    percIndexesWithTotals.length - 1 !== index ? (
                                        <TableRow key={index}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan}</RightTd>
                                            <RightTd>{data.feb}</RightTd>
                                            <RightTd>{data.mar}</RightTd>
                                            <RightTd>{data.apr}</RightTd>
                                            <RightTd>{data.may}</RightTd>
                                            <RightTd>{data.jun}</RightTd>
                                            <RightTd>{data.jul}</RightTd>
                                            <RightTd>{data.aug}</RightTd>
                                            <RightTd>{data.sep}</RightTd>
                                            <RightTd>{data.oct}</RightTd>
                                            <RightTd>{data.nov}</RightTd>
                                            <RightTd>{data.dec}</RightTd>
                                            <RightTd>{data.tot}</RightTd>
                                        </TableRow>
                                    ) : (
                                        <tr key={index} style={{ borderTop: '3px solid black' }}>
                                            <td>{data.institute}</td>
                                            <RightTd>{data.jan}</RightTd>
                                            <RightTd>{data.feb}</RightTd>
                                            <RightTd>{data.mar}</RightTd>
                                            <RightTd>{data.apr}</RightTd>
                                            <RightTd>{data.may}</RightTd>
                                            <RightTd>{data.jun}</RightTd>
                                            <RightTd>{data.jul}</RightTd>
                                            <RightTd>{data.aug}</RightTd>
                                            <RightTd>{data.sep}</RightTd>
                                            <RightTd>{data.oct}</RightTd>
                                            <RightTd>{data.nov}</RightTd>
                                            <RightTd>{data.dec}</RightTd>
                                            <RightTd>{data.tot}</RightTd>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Container>
    );
};

export default OverviewSps;
