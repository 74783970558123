export enum UserRoles {
    admin = 'Admin',
    manager = 'Manager',
    normal = 'User',
}
export enum EFrequency {
    monthly = 1,
    quarterly = 2,
    yearly = 3,
    weekly = 4,
}

export enum EFileNameResult {
    error = 'error',
    success = 'success',
}
export enum EProcessingStatus {
    check = 'check',
    success = 'uploaded',
    cancelled = 'cancelled',
}

export enum EShippingStatus {
    ShippingLoaded = 0,
    CheckControlsPerformed = 1,
    ImportRequest = 2,
    AdminAuthorizesImport = 3,
    ShippingImportedInFinal = 4,
    CancellationRequest = 5,
    ShippingCancelled = 6,
    ShippingSentToSogei = 7,
    ShippingOKSogei = 8,
    ShippingWrongSogei = 9,
    BlockingErrorsDuringControls = 99,
}

export enum EFlowType {
    Sdo = 'Sdo',
    Sdor = 'Sdor',
    Siar = 'Siar',
    Fim = 'Fim',
    Cedp = 'Cedp',
    Spa = 'Spa',
    Sps = 'Sps',
    Sderia = 'Sderia',
    Diab = 'Diab',
    Hosp = 'Hosp',
    X = '118',
    Farmosp = 'Farmosp',
    Sind = 'Sind',
    Labo = 'Labo',
    Spi = 'Spi',
    Dm = 'Dm',
    Farmter = 'Farmter',
    Farm = 'Farm',
    Rpa = 'Rpa',
    Rpg = 'Rpg',
}

export enum FileType {
    Anag = 'anagrafic',
    Clin = 'clinical',
    Riab = 'rehabilitative',
    _ = '',
}

export enum FlowStatus {
    Disabled = 0,
    Remade = 1,
    OracleManaged = 2,
}

export enum Months {
    JAN = 'jan',
    FEB = 'feb',
    MAR = 'mar',
    APR = 'apr',
    MAY = 'may',
    JUN = 'jun',
    JUL = 'jul',
    AUG = 'aug',
    SEP = 'sep',
    OCT = 'oct',
    NOV = 'nov',
    DEC = 'dec',
    TOT = 'tot',
}

export const MonthsByApi = {
    [Months.JAN]: 'months.0',
    [Months.FEB]: 'months.1',
    [Months.MAR]: 'months.2',
    [Months.APR]: 'months.3',
    [Months.MAY]: 'months.4',
    [Months.JUN]: 'months.5',
    [Months.JUL]: 'months.6',
    [Months.AUG]: 'months.7',
    [Months.SEP]: 'months.8',
    [Months.OCT]: 'months.9',
    [Months.NOV]: 'months.10',
    [Months.DEC]: 'months.11',
    [Months.TOT]: 'generic.total',
};
