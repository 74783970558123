import { EFlowType, FileType } from '../../model/enum';
import { IOverviewBE, IOverviewRecoveryWithPermissions } from '../../model';
import { OverlayChild, TableRow } from '../../style';
import { OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { downloadFile, getCurrentMonthAndYear, isAxiosError } from '../../utils';

import { AlertError } from '../Alert/Alert';
import { AxiosError } from 'axios';
import CustomSvg from '../Svg/CustomSvg';
import GenericError from '../GenericError/GenericError';
import { IconBtn } from '../Buttons/Buttons';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import httpClient from '../../api/httpClient';
import { useTranslation } from 'react-i18next';

interface IXmlTable {
    overview: IOverviewRecoveryWithPermissions;
    isLoading: boolean;
    isError: any;
    flow: EFlowType;
}

interface ICurrentOverview {
    hospitalizations: number;
    month: string;
    year: number;
}

interface IDate {
    anag: {
        year: number | undefined;
        month: number | undefined;
    };
    clin: {
        year: number | undefined;
        month: number | undefined;
    };
    riab: {
        year: number | undefined;
        month: number | undefined;
    };
}

interface ILoading {
    anag: boolean;
    clin: boolean;
    riab: boolean;
}

const XmlTable = (props: IXmlTable) => {
    const { t, i18n } = useTranslation();
    const { overview, isError, isLoading, flow } = props;
    const { permissionSet, overviewTableRows } = overview;
    const [currentRecPerMonth, setCurrentRecPerMonth] = useState<ICurrentOverview[]>([]);
    const [date, setDate] = useState<IDate>({ anag: { year: undefined, month: undefined }, clin: { year: undefined, month: undefined }, riab: { year: undefined, month: undefined } });
    const [loadingXml, setLoadingXml] = useState<ILoading>({ anag: false, clin: false, riab: false });
    const { loggedUser } = useContext(LoggedUserContext);

    const clm =
        flow === EFlowType.Sdo
            ? [
                  { name: t('calendaring.month'), id: 1 },
                  { name: t('sdoOverview.clmYear'), id: 2 },
                  { name: t('ministry.clmrec'), id: 3 },
                  { name: t('ministry.xmlAna'), id: 4 },
                  { name: t('ministry.xmlCli'), id: 5 },
              ]
            : [
                  { name: t('calendaring.month'), id: 1 },
                  { name: t('sdoOverview.clmYear'), id: 2 },
                  { name: t('ministry.clmrec'), id: 3 },
                  { name: t('ministry.xmlRia'), id: 4 },
              ];

    const getMonthRecs = (overviewData: IOverviewBE[] | undefined): ICurrentOverview[] => {
        const { currentMonth, currentYear } = getCurrentMonthAndYear();
        if (!overviewData) return [];
        let currentOverview: ICurrentOverview[] = [];
        // Current Year
        let hospsJanCurrent: number = 0;
        let hospsFebCurrent: number = 0;
        let hospsMarCurrent: number = 0;
        let hospsAprCurrent: number = 0;
        let hospsMayCurrent: number = 0;
        let hospsJunCurrent: number = 0;
        let hospsJulCurrent: number = 0;
        let hospsAugCurrent: number = 0;
        let hospsSepCurrent: number = 0;
        let hospsOctCurrent: number = 0;
        let hospsNovCurrent: number = 0;
        let hospsDecCurrent: number = 0;
        // Last Year
        let hospsJanLast: number = 0;
        let hospsFebLast: number = 0;
        let hospsMarLast: number = 0;
        let hospsAprLast: number = 0;
        let hospsMayLast: number = 0;
        let hospsJunLast: number = 0;
        let hospsJulLast: number = 0;
        let hospsAugLast: number = 0;
        let hospsSepLast: number = 0;
        let hospsOctLast: number = 0;
        let hospsNovLast: number = 0;
        let hospsDecLast: number = 0;

        if (currentMonth > 5) {
            overviewData?.forEach(item => {
                // Current Year
                hospsJanCurrent += item.currentYearRow.jan;
                hospsFebCurrent += item.currentYearRow.feb;
                hospsMarCurrent += item.currentYearRow.mar;
                hospsAprCurrent += item.currentYearRow.apr;
                hospsMayCurrent += item.currentYearRow.may;
                hospsJunCurrent += item.currentYearRow.jun;
                hospsJulCurrent += item.currentYearRow.jul;
                hospsAugCurrent += item.currentYearRow.aug;
                hospsSepCurrent += item.currentYearRow.sep;
                hospsOctCurrent += item.currentYearRow.oct;
                hospsNovCurrent += item.currentYearRow.nov;
                hospsDecCurrent += item.currentYearRow.dec;
            });
            // Current Year
            currentOverview?.push({ hospitalizations: hospsJanCurrent, month: t('months.0'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsFebCurrent, month: t('months.1'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsMarCurrent, month: t('months.2'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsAprCurrent, month: t('months.3'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsMayCurrent, month: t('months.4'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsJunCurrent, month: t('months.5'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsJulCurrent, month: t('months.6'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsAugCurrent, month: t('months.7'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsSepCurrent, month: t('months.8'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsOctCurrent, month: t('months.9'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsNovCurrent, month: t('months.10'), year: currentYear });
            currentOverview?.push({ hospitalizations: hospsDecCurrent, month: t('months.11'), year: currentYear });
            return currentOverview?.slice(0, currentMonth).reverse();
        }

        overviewData?.forEach(item => {
            // LastYear
            hospsJanLast += item.lastYearRow.jan;
            hospsFebLast += item.lastYearRow.feb;
            hospsMarLast += item.lastYearRow.mar;
            hospsAprLast += item.lastYearRow.apr;
            hospsMayLast += item.lastYearRow.may;
            hospsJunLast += item.lastYearRow.jun;
            hospsJulLast += item.lastYearRow.jul;
            hospsAugLast += item.lastYearRow.aug;
            hospsSepLast += item.lastYearRow.sep;
            hospsOctLast += item.lastYearRow.oct;
            hospsNovLast += item.lastYearRow.nov;
            hospsDecLast += item.lastYearRow.dec;
            // Current Year
            hospsJanCurrent += item.currentYearRow.jan;
            hospsFebCurrent += item.currentYearRow.feb;
            hospsMarCurrent += item.currentYearRow.mar;
            hospsAprCurrent += item.currentYearRow.apr;
            hospsMayCurrent += item.currentYearRow.may;
        });
        // LastYear
        currentOverview?.push({ hospitalizations: hospsJanLast, month: t('months.0'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsFebLast, month: t('months.1'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsMarLast, month: t('months.2'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsAprLast, month: t('months.3'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsMayLast, month: t('months.4'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsJunLast, month: t('months.5'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsJulLast, month: t('months.6'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsAugLast, month: t('months.7'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsSepLast, month: t('months.8'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsOctLast, month: t('months.9'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsNovLast, month: t('months.10'), year: currentYear - 1 });
        currentOverview?.push({ hospitalizations: hospsDecLast, month: t('months.11'), year: currentYear - 1 });
        //Current Year
        currentOverview?.push({ hospitalizations: hospsJanCurrent, month: t('months.0'), year: currentYear });
        currentOverview?.push({ hospitalizations: hospsFebCurrent, month: t('months.1'), year: currentYear });
        currentOverview?.push({ hospitalizations: hospsMarCurrent, month: t('months.2'), year: currentYear });
        currentOverview?.push({ hospitalizations: hospsAprCurrent, month: t('months.3'), year: currentYear });
        currentOverview?.push({ hospitalizations: hospsMayCurrent, month: t('months.4'), year: currentYear });

        return currentOverview.slice(0, currentMonth + 12).reverse();
    };

    const getMonthNumber = (month: string): number => {
        const monthMap: { [key: string]: number } = {
            [t('months.0')]: 1,
            [t('months.1')]: 2,
            [t('months.2')]: 3,
            [t('months.3')]: 4,
            [t('months.4')]: 5,
            [t('months.5')]: 6,
            [t('months.6')]: 7,
            [t('months.7')]: 8,
            [t('months.8')]: 9,
            [t('months.9')]: 10,
            [t('months.10')]: 11,
            [t('months.11')]: 12,
        };
        return monthMap[month];
    };

    const fetchDataForDownload = async (year: number, month: number, fileType: FileType) => {
        let queryParam;
        switch (fileType) {
            case FileType.Anag:
                queryParam = 'GetAnagraficXml';
                break;
            case FileType.Clin:
                queryParam = 'GetClinicalXml';
                break;
            case FileType.Riab:
                queryParam = 'GetRehabXml';
                break;
        }
        try {
            const responseUrl = await httpClient.fetcher(`Xml/${queryParam}?userId=${loggedUser.userId}&year=${year}&month=${month}`);
            downloadFile(responseUrl);
        } catch (error) {
            if (isAxiosError(error)) {
                const axiosError = error as AxiosError;
                const errorMessage = axiosError.response?.data ?? axiosError.message;

                if (`${errorMessage}`.includes('data found for the period')) {
                    AlertError(t('ministry.notFoundXML'));
                } else if (`${errorMessage}`.includes('MATR_CRYPT is null')) {
                    AlertError(t('ministry.formatErr'));
                } else {
                    AlertError(t('generic.errorDownload'));
                }
            } else {
                AlertError(t('generic.errorDownload'));
            }
        } finally {
            setLoadingXml({ anag: false, clin: false, riab: false });
        }
    };

    useEffect(() => {
        if (!isLoading) {
            setCurrentRecPerMonth(getMonthRecs(overviewTableRows));
        }
    }, [i18n.language, isLoading]);

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {overview && (
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clm.map(data => (
                                    <th key={data.id} className={'table-dark'}>
                                        {data.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {!!currentRecPerMonth &&
                                currentRecPerMonth.map(item => (
                                    <TableRow key={item.year + item.month + item.hospitalizations}>
                                        <td>
                                            <b>{item.month}</b>
                                        </td>
                                        <td>{item.year}</td>
                                        <td>{item.hospitalizations}</td>
                                        {flow === EFlowType.Sdo && (
                                            <td>
                                                {loadingXml.anag && date.anag.year === item.year && date.anag.month === getMonthNumber(item.month) ? (
                                                    <div id='centeredLoadButton'>
                                                        <Spinner />
                                                    </div>
                                                ) : (
                                                    <OverlayTrigger
                                                        transition={false}
                                                        i18nIsDynamicList
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip>
                                                                {t('ministry.downloadAnag')} {item.month} {item.year}
                                                            </Tooltip>
                                                        }>
                                                        {item.hospitalizations > 0 && permissionSet.canSend ? (
                                                            <OverlayChild>
                                                                <IconBtn
                                                                    withOutline
                                                                    onClick={async () => {
                                                                        if (!loadingXml.clin && !loadingXml.anag) {
                                                                            setDate({
                                                                                anag: { year: item.year, month: getMonthNumber(item.month) },
                                                                                clin: { year: undefined, month: undefined },
                                                                                riab: { year: undefined, month: undefined },
                                                                            });
                                                                            setLoadingXml({ anag: true, clin: false, riab: false });
                                                                            await fetchDataForDownload(item.year, getMonthNumber(item.month), FileType.Anag);
                                                                        }
                                                                    }}>
                                                                    <CustomSvg iconName='it-file-xml' className='icon icon-primary' />
                                                                </IconBtn>
                                                            </OverlayChild>
                                                        ) : (
                                                            <IconBtn disabled={true} borderProps='1px solid #272728' withOutline>
                                                                <CustomSvg iconName='it-file-xml' className='icon icon-secondary' />
                                                            </IconBtn>
                                                        )}
                                                    </OverlayTrigger>
                                                )}
                                            </td>
                                        )}

                                        {flow === EFlowType.Sdo && (
                                            <td>
                                                {flow === EFlowType.Sdo && loadingXml.clin && date.clin.year === item.year && date.clin.month === getMonthNumber(item.month) ? (
                                                    <div id='centeredLoadButton'>
                                                        <Spinner />
                                                    </div>
                                                ) : (
                                                    <OverlayTrigger
                                                        transition={false}
                                                        i18nIsDynamicList
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip>
                                                                {t('ministry.downloadClin')} {item.month} {item.year}
                                                            </Tooltip>
                                                        }>
                                                        {item.hospitalizations > 0 && permissionSet.canSend ? (
                                                            <OverlayChild>
                                                                <IconBtn
                                                                    withOutline
                                                                    onClick={async () => {
                                                                        if (!loadingXml.clin && !loadingXml.anag) {
                                                                            setDate({
                                                                                anag: { year: undefined, month: undefined },
                                                                                clin: { year: item.year, month: getMonthNumber(item.month) },
                                                                                riab: { year: undefined, month: undefined },
                                                                            });
                                                                            setLoadingXml({ anag: false, clin: true, riab: false });
                                                                            await fetchDataForDownload(item.year, getMonthNumber(item.month), FileType.Clin);
                                                                        }
                                                                    }}>
                                                                    <CustomSvg iconName='it-file-xml' className='icon icon-primary' />
                                                                </IconBtn>
                                                            </OverlayChild>
                                                        ) : (
                                                            <IconBtn disabled={true} borderProps='1px solid #272728' withOutline>
                                                                <CustomSvg iconName='it-file-xml' className='icon icon-secondary' />
                                                            </IconBtn>
                                                        )}
                                                    </OverlayTrigger>
                                                )}
                                            </td>
                                        )}
                                        {flow === EFlowType.Sdor && (
                                            <td>
                                                {loadingXml.riab && date.riab.year === item.year && date.riab.month === getMonthNumber(item.month) ? (
                                                    <div id='centeredLoadButton'>
                                                        <Spinner />
                                                    </div>
                                                ) : (
                                                    <OverlayTrigger
                                                        transition={false}
                                                        i18nIsDynamicList
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip>
                                                                {t('ministry.downloadRiab')} {item.month} {item.year}
                                                            </Tooltip>
                                                        }>
                                                        {item.hospitalizations > 0 && permissionSet.canSend ? (
                                                            <OverlayChild>
                                                                <IconBtn
                                                                    withOutline
                                                                    onClick={async () => {
                                                                        if (!loadingXml.clin && !loadingXml.anag) {
                                                                            setDate({
                                                                                anag: { year: undefined, month: undefined },
                                                                                clin: { year: undefined, month: undefined },
                                                                                riab: { year: item.year, month: getMonthNumber(item.month) },
                                                                            });
                                                                            setLoadingXml({ anag: false, clin: false, riab: true });
                                                                            await fetchDataForDownload(item.year, getMonthNumber(item.month), FileType.Riab);
                                                                        }
                                                                    }}>
                                                                    <CustomSvg iconName='it-file-xml' className='icon icon-primary' />
                                                                </IconBtn>
                                                            </OverlayChild>
                                                        ) : (
                                                            <IconBtn disabled={true} borderProps='1px solid #272728' withOutline>
                                                                <CustomSvg iconName='it-file-xml' className='icon icon-secondary' />
                                                            </IconBtn>
                                                        )}
                                                    </OverlayTrigger>
                                                )}
                                            </td>
                                        )}
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </React.Fragment>
    );
};

export default XmlTable;
