import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { IDataObject, IOverview118 } from '../../model';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getCurrentMonthAndYear, getMonthNameByApi } from '../../utils';

import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { t } from 'i18next';

interface IExportExcel118Props {
    currentYearRecs: IOverview118[];
    lastYearRecs: IOverview118[];
    differences: IDataObject;
}

const ExportExcel118 = (props: IExportExcel118Props) => {
    const { currentYearRecs, lastYearRecs, differences } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = '118-Overview';

    const exportToExcel = () => {
        if (!!currentYearRecs && !!lastYearRecs && !!differences) {
            const titleRow = () => {
                return {
                    [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                    [t('genericOverview.clmMonth')]: t('genericOverview.clmMonth'),
                    [t('genericOverview.calls')]: t('genericOverview.calls'),
                    [t('genericOverview.interventions')]: t('genericOverview.interventions'),
                    [t('genericOverview.missions')]: t('genericOverview.missions'),
                    [t('genericOverview.assisted')]: t('genericOverview.assisted'),
                };
            };
            const emptyRow = () => {
                return {
                    [t('genericOverview.clmYear')]: '',
                    [t('genericOverview.clmMonth')]: '',
                    [t('genericOverview.calls')]: '',
                    [t('genericOverview.interventions')]: '',
                    [t('genericOverview.missions')]: '',
                    [t('genericOverview.assisted')]: '',
                };
            };

            const finalRows = [
                ...currentYearRecs.map(data => {
                    return Object.entries(data.data || ({} as IDataObject)).map(([monthName, monthValue]) => {
                        return {
                            [t('genericOverview.clmYear')]: data.year,
                            [t('genericOverview.clmMonth')]: t(getMonthNameByApi(monthName)),
                            [t('genericOverview.calls')]: monthValue.chiamate,
                            [t('genericOverview.interventions')]: monthValue.interventi,
                            [t('genericOverview.missions')]: monthValue.missioni,
                            [t('genericOverview.assisted')]: monthValue.assistiti,
                        };
                    });
                }),
                emptyRow(),
                titleRow(),
                ...lastYearRecs.map(data => {
                    return Object.entries(data.data || ({} as IDataObject)).map(([monthName, monthValue]) => {
                        return {
                            [t('genericOverview.clmYear')]: data.year,
                            [t('genericOverview.clmMonth')]: t(getMonthNameByApi(monthName)),
                            [t('genericOverview.calls')]: monthValue.chiamate,
                            [t('genericOverview.interventions')]: monthValue.interventi,
                            [t('genericOverview.missions')]: monthValue.missioni,
                            [t('genericOverview.assisted')]: monthValue.assistiti,
                        };
                    });
                }),
                emptyRow(),
                titleRow(),
                Object.entries(differences || ({} as IDataObject)).map(([monthName, monthValue]) => {
                    return {
                        [t('genericOverview.clmYear')]: `${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1}`,
                        [t('genericOverview.clmMonth')]: t(getMonthNameByApi(monthName)),
                        [t('genericOverview.calls')]: monthValue.chiamate,
                        [t('genericOverview.interventions')]: monthValue.interventi,
                        [t('genericOverview.missions')]: monthValue.missioni,
                        [t('genericOverview.assisted')]: monthValue.assistiti,
                    };
                }),
            ];

            const ws = XLSX.utils.json_to_sheet(finalRows.flat());
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} placement='top' overlay={<Tooltip>{`${t('generic.downloadExcel', { flow: '118' })}`}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcel118;
