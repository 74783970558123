import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { IOverviewLabo, OverviewData } from '../../model';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { t } from 'i18next';

interface IExportExcel118Props {
    currentYearRecs: IOverviewLabo;
    lastYearRecs: IOverviewLabo;
    differences: IOverviewLabo;
}

const ExportExcelLabo = (props: IExportExcel118Props) => {
    const { currentYearRecs, lastYearRecs, differences } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'LABO-Overview';

    const exportToExcel = () => {
        if (!!currentYearRecs && !!lastYearRecs && !!differences) {
            const titleRow = () => {
                return {
                    [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                    [t('genericOverview.institute')]: t('genericOverview.institute'),
                    [t('months.0')]: t('months.0'),
                    [t('months.1')]: t('months.1'),
                    [t('months.2')]: t('months.2'),
                    [t('months.3')]: t('months.3'),
                    [t('months.4')]: t('months.4'),
                    [t('months.5')]: t('months.5'),
                    [t('months.6')]: t('months.6'),
                    [t('months.7')]: t('months.7'),
                    [t('months.8')]: t('months.8'),
                    [t('months.9')]: t('months.9'),
                    [t('months.10')]: t('months.10'),
                    [t('months.11')]: t('months.11'),
                    [t('genericOverview.total')]: t('genericOverview.total'),
                };
            };

            const emptyRow = () => {
                return {
                    [t('genericOverview.clmYear')]: '',
                    [t('genericOverview.institute')]: '',
                    [t('months.0')]: '',
                    [t('months.1')]: '',
                    [t('months.2')]: '',
                    [t('months.3')]: '',
                    [t('months.4')]: '',
                    [t('months.5')]: '',
                    [t('months.6')]: '',
                    [t('months.7')]: '',
                    [t('months.8')]: '',
                    [t('months.9')]: '',
                    [t('months.10')]: '',
                    [t('months.11')]: '',
                    [t('genericOverview.total')]: '',
                };
            };

            const finalRows = [
                ...currentYearRecs.data.map(data => ({
                    [t('genericOverview.clmYear')]: currentYearRecs.year,
                    [t('genericOverview.institute')]: data.institute,
                    [t('months.0')]: data.jan,
                    [t('months.1')]: data.feb,
                    [t('months.2')]: data.mar,
                    [t('months.3')]: data.apr,
                    [t('months.4')]: data.may,
                    [t('months.5')]: data.jun,
                    [t('months.6')]: data.jul,
                    [t('months.7')]: data.aug,
                    [t('months.8')]: data.sep,
                    [t('months.9')]: data.oct,
                    [t('months.10')]: data.nov,
                    [t('months.11')]: data.dec,
                    [t('genericOverview.total')]: data.tot,
                })),
                emptyRow(),
                titleRow(),
                ...lastYearRecs.data.map(data => ({
                    [t('genericOverview.clmYear')]: lastYearRecs.year,
                    [t('genericOverview.institute')]: data.institute,
                    [t('months.0')]: data.jan,
                    [t('months.1')]: data.feb,
                    [t('months.2')]: data.mar,
                    [t('months.3')]: data.apr,
                    [t('months.4')]: data.may,
                    [t('months.5')]: data.jun,
                    [t('months.6')]: data.jul,
                    [t('months.7')]: data.aug,
                    [t('months.8')]: data.sep,
                    [t('months.9')]: data.oct,
                    [t('months.10')]: data.nov,
                    [t('months.11')]: data.dec,
                    [t('genericOverview.total')]: data.tot,
                })),
                emptyRow(),
                titleRow(),
                ...differences.data.map(data => ({
                    [t('genericOverview.clmYear')]: differences.year,
                    [t('genericOverview.institute')]: data.institute,
                    [t('months.0')]: data.jan,
                    [t('months.1')]: data.feb,
                    [t('months.2')]: data.mar,
                    [t('months.3')]: data.apr,
                    [t('months.4')]: data.may,
                    [t('months.5')]: data.jun,
                    [t('months.6')]: data.jul,
                    [t('months.7')]: data.aug,
                    [t('months.8')]: data.sep,
                    [t('months.9')]: data.oct,
                    [t('months.10')]: data.nov,
                    [t('months.11')]: data.dec,
                    [t('genericOverview.total')]: data.tot,
                })),
            ];

            const ws = XLSX.utils.json_to_sheet(finalRows);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} placement='top' overlay={<Tooltip>{`${t('generic.downloadExcel', { flow: 'LABO' })}`}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelLabo;
