import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Button } from 'react-bootstrap';
import CalendaringTable from '../../componets/Table/CalendaringTable';
import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import { LoggedUserContext } from '../../App';
import { route } from '../../route';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScheduler } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

const Calendaring = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { loggedUser } = useContext(LoggedUserContext);
    const { data, isLoading, isError, mutate } = useScheduler(loggedUser.userId);
    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.calendaring'), url: route.calendaring }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!!data && (
                <>
                    <Button type='button' className='align-self-end' onClick={() => navigate(route.new)} variant='primary'>
                        {t('calendaring.new')}
                    </Button>
                    <CalendaringTable header={t('calendaring.flowName')} rows={data} update={mutate} />
                </>
            )}
        </Container>
    );
};

export default Calendaring;
