import { Container, PageDescription, PageTitle } from '../../style';
import { IActionRoleBE, IActionValueBE, IFlowsWithActionBE, IRoleByIdBE, IRowsWithActionBE } from '../../model';
import { useEffect, useMemo, useState } from 'react';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Btn } from '../../componets/Buttons/Buttons';
import CheckboxTable from '../../componets/CustomTable/CheckboxTable';
import CustomCheckbox from '../../componets/CustomCheckbox/CustomCheckbox';
import { ELanguages } from '../../localization';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import Table from 'react-bootstrap/Table';
import { get } from 'lodash';
import { route } from '../../route';
import { useFlowsById } from '../../api/fetch';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IEditFlowProps {}

const EditFLow = (props: IEditFlowProps) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const { flow, isLoading, isError } = useFlowsById(id);
    const [loadingCheckbox, setLoadingCheckbox] = useState<boolean>(true);

    let roles: IRowsWithActionBE[] = useMemo(() => {
        return !loadingCheckbox ? get(flow, 'rowsWithActions', []) : [];
    }, [flow, loadingCheckbox]);

    let actions = get(flow, 'actions', []);

    useEffect(() => {
        setLoadingCheckbox(true);
        setTimeout(() => setLoadingCheckbox(false), 1000);
    }, []);
    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.flows'), url: '/' + route.flows },
                    { nameToShow: t('flows.edit'), url: '' },
                ]}
            />
            {(isLoading || loadingCheckbox) && <Loading />}
            {isError && <GenericError />}
            {flow && !loadingCheckbox && (
                <>
                    <PageDescription>
                        {t('flows.description')} {i18n.language === ELanguages.it ? flow.flowNameIt : flow.flowNameDe}
                    </PageDescription>
                    <Table size='xl' responsive='xl'>
                        <thead>
                            <tr>
                                <th>{t('routes.roles')}</th>
                                {actions.map((a: IActionRoleBE) => (
                                    <th key={a.actionId}>{i18n.language === ELanguages.it ? a.actionNameIt : a.actionNameDe}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map((data: IRowsWithActionBE, index) => (
                                <tr key={`${data.roleId}`}>
                                    <td>{i18n.language === ELanguages.it ? data.roleNameIt : data.roleNameDe}</td>
                                    {data.actionValues.map((av: IActionValueBE) => (
                                        <td key={av.actionId}>
                                            <CustomCheckbox defaultChecked={av.isSelected} disabled />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        </Container>
    );
};

export default EditFLow;
