import './index.css';

import { useContext, useId } from 'react';

import { Accordion } from 'react-bootstrap';
import { Btn } from '../Buttons/Buttons';
import CustomSelect from '../Select/CustomSelect';
import { FilterSeparatorForm } from '../../style';
import { Formik } from 'formik';
import { GenericFlowContext } from '../GenericFlow/GenericFlow';
import { IFilterSendFile } from '../../model';
import { TextInput } from '../Input/Input';
import { checkFiltersGeneric } from '../../utils';
import { initialDataSendFile } from '../../utils/constants';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IFilterProps {}

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const FilterSeparator = styled.div`
    display: flex;
    gap: 0.5rem;
    & > div {
        width: 50%;
    }
`;

const GenericFlowFilters = (props: IFilterProps) => {
    const { t } = useTranslation();
    const { setPage, setItemOffset, freezedShippings: allShippings, setShippings, setFilter } = useContext(GenericFlowContext);

    const filter = (values: IFilterSendFile, setFieldValue: any) => {
        const { date, sped, status, userName } = values;
        setPage(0);
        setItemOffset(0);
        setFilter(values);
        setShippings(allShippings.filter(data => checkFiltersGeneric(data, sped, date, status, userName)));
    };
    const id = useId();

    return (
        <FilterContainer>
            <Accordion>
                <Accordion.Item eventKey={id}>
                    <Accordion.Header>{t('sdoOverview.spedFilter')}</Accordion.Header>
                    <Accordion.Body>
                        <Formik
                            initialValues={initialDataSendFile}
                            enableReinitialize={true}
                            onSubmit={(values, setFieldValue) => {
                                filter(values, setFieldValue);
                            }}>
                            {({ handleSubmit, setFieldValue, resetForm }) => (
                                <FilterContainer>
                                    <FilterSeparatorForm onSubmit={handleSubmit}>
                                        <FilterSeparator>
                                            <CustomSelect
                                                name='status'
                                                firstOption={t('generic.workingState')}
                                                parentOnChange={(value: string) => {
                                                    setFieldValue('status', parseInt(value));
                                                }}
                                                options={[
                                                    { label: t('sendFiles.onlyElab'), value: 2, id: 'white' },
                                                    { label: t('sendFiles.elabOk'), value: 1, id: 'yellow' },
                                                    { label: t('sendFiles.def'), value: 3, id: 'green' },
                                                    { label: t('sendFiles.elabKo'), value: 4, id: 'red' },
                                                ]}
                                            />
                                            <TextInput name='sped' label={t('sdoOverview.spedNameFilter')} />
                                        </FilterSeparator>
                                        <FilterSeparator>
                                            <TextInput type={'date'} name='date' label={t('sdoOverview.dateFilter')} />
                                            <TextInput name='userName' label={t('sdoOverview.usernameFilter')} />
                                        </FilterSeparator>
                                        <FilterSeparator>
                                            <Btn text={t('btns.filter')} type='submit' />
                                            <Btn
                                                text={t('btns.cleanFilter')}
                                                onClick={() => {
                                                    resetForm();
                                                    setFilter(initialDataSendFile);
                                                    setShippings([...allShippings]);
                                                    setItemOffset(0);
                                                    setPage(0);
                                                }}
                                            />
                                        </FilterSeparator>
                                    </FilterSeparatorForm>
                                </FilterContainer>
                            )}
                        </Formik>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </FilterContainer>
    );
};

export default GenericFlowFilters;
