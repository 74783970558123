import { AlertError, AlertPromise } from '../Alert/Alert';
import { EFileNameResult, EFlowType } from '../../model/enum';
import { FieldArray, Formik } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StyledFilesList, StyledLabel, StyledLi, StyledUpload } from '../../style';
import { useContext, useRef, useState } from 'react';

import CustomSvg from '../Svg/CustomSvg';
import { GenericFlowContext } from '../GenericFlow/GenericFlow';
import { IconBtn } from '../Buttons/Buttons';
import InfoBoxFactory from '../InfoBoxFactory/InfoBoxFactory';
import { LoggedUserContext } from '../../App';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import { onChangeFilesByGenericType } from '../../utils/genericCheckFile';
import { removeUnderscore } from '../../utils';
import { useMsal } from '@azure/msal-react';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IFormSendFile {
    files: { file: File; id: string }[];
}
const initialFormData: IFormSendFile = { files: [] };
interface ISendFilesSectionProps {
    handleClose?: () => void;
    updateCall?: (files: string[]) => void;
}
const GenericFlowUpload = (props: ISendFilesSectionProps) => {
    const { t } = useTranslation();
    const { flowType } = useContext(GenericFlowContext);
    const { handleClose, updateCall } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const refInput = useRef<HTMLInputElement | null>(null);
    const { trigger } = useSWRMutation(`StartAllControls`, httpClient.sendFileRequestOld);
    const [loading, setLoading] = useState<boolean>(false);
    const { instance } = useMsal();

    const displayDescription = () => {
        switch (flowType.toUpperCase()) {
            case EFlowType.Siar.toUpperCase():
                return t('sendFiles.genericFlowDescription');
            case EFlowType.Farm.toUpperCase():
                return t('sendFiles.genericDescription');
            case EFlowType.Cedp.toUpperCase():
            case EFlowType.Fim.toUpperCase():
            case EFlowType.Sderia.toUpperCase():
            case EFlowType.Sps.toUpperCase():
            case EFlowType.Hosp.toUpperCase():
            case EFlowType.Labo.toUpperCase():
            case EFlowType.Diab.toUpperCase():
            case EFlowType.Spa.toUpperCase():
                return t('sendFiles.description');
            case EFlowType.X.toUpperCase():
            case EFlowType.Farmter.toUpperCase():
                return t('sendFiles.descriptionGenericThree');
            case EFlowType.Farmosp.toUpperCase():
            case EFlowType.Rpa.toUpperCase():
            case EFlowType.Rpg.toUpperCase():
            case EFlowType.Dm.toUpperCase():
                return t('sendFiles.unique');
            default:
                return '';
        }
    };

    const isDisabledBtn = (numbOfFiles: number): boolean => {
        const fileRequirements: Record<string, number[]> = {
            [EFlowType.Siar.toUpperCase()]: [6],
            [EFlowType.Fim.toUpperCase()]: [2],
            [EFlowType.Sps.toUpperCase()]: [2],
            [EFlowType.Farmosp.toUpperCase()]: [1],
            [EFlowType.Rpa.toUpperCase()]: [1],
            [EFlowType.Rpg.toUpperCase()]: [1],
            [EFlowType.Cedp.toUpperCase()]: [2],
            [EFlowType.Sderia.toUpperCase()]: [2],
            [EFlowType.Hosp.toUpperCase()]: [2],
            [EFlowType.Labo.toUpperCase()]: [2],
            [EFlowType.X.toUpperCase()]: [3],
            [EFlowType.Diab.toUpperCase()]: [2],
            [EFlowType.Dm.toUpperCase()]: [1],
            [EFlowType.Spa.toUpperCase()]: [2],
            [EFlowType.Farm.toUpperCase()]: [1, 3],
            [EFlowType.Farmter.toUpperCase()]: [3],
        };

        const requiredFiles = fileRequirements[flowType.toUpperCase()];
        return requiredFiles !== undefined ? !requiredFiles.includes(numbOfFiles) : false;
    };

    return (
        <>
            <p>{displayDescription()}</p>
            <Formik
                initialValues={initialFormData}
                onSubmit={(values, { setFieldValue }) => {
                    let files = values.files.map(f => f.file);
                    const now = new Date();
                    const hh = now.getHours();
                    const min = now.getMinutes();
                    const sec = now.getSeconds();
                    now.setDate(now.getDate());
                    const lastName = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.family_name', 'username'));
                    const name = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.given_name', 'username'));
                    let stringDate = ('0' + now.getDate()).slice(-2) + '' + ('0' + (now.getMonth() + 1)).slice(-2) + '' + now.getFullYear();
                    let hoursDate = ('0' + hh).slice(-2) + '' + ('0' + min).slice(-2) + '' + ('0' + sec).slice(-2);
                    const formData = new FormData();
                    files?.forEach((file: any) => formData.append(`files`, file, `${name.charAt(0) + lastName}_${stringDate}_${hoursDate}_${file.name}`));
                    setFieldValue('files', []);
                    setLoading(true);
                    formData.append('userId', `${loggedUser.userId}`);
                    formData.append('flowName', `${flowType}`);
                    AlertPromise('', trigger(formData), 'Success', 'Error', () => {
                        if (!!handleClose) handleClose();
                        setFieldValue('files', []);
                        if (!!updateCall) updateCall(files?.map((file: any) => `${name.charAt(0) + lastName}_${stringDate}_${hoursDate}_${file.name}`));
                    })
                        .catch(error => {
                            const message = get(error, 'response.data', 'error');
                            console.log(message);
                        })
                        .finally(() => setLoading(false));
                }}>
                {({ handleSubmit, values, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit} id='form2'>
                            <input
                                form='form2'
                                type='file'
                                title='files'
                                name='files'
                                id='files'
                                className='upload'
                                ref={refInput}
                                multiple
                                accept='.txt'
                                onChange={event => onChangeFilesByGenericType(event, flowType as EFlowType, setFieldValue)}
                            />

                            <StyledLabel htmlFor='files'>
                                <CustomSvg iconName='it-file' aria-hidden='true' className='icon icon-md' />
                                <span>{t('btns.select')}</span>
                            </StyledLabel>
                            <div className='upload-file-list'>
                                {!!values.files && (
                                    <FieldArray
                                        name='files'
                                        render={arrayHelpers => (
                                            <>
                                                {!!values.files && Array.from(values.files).length > 0 && (
                                                    <OverlayTrigger key={'delete'} placement='top' transition={false} i18nIsDynamicList overlay={<Tooltip>{t('generic.tooltipDelete')}</Tooltip>}>
                                                        <div style={{ width: 'fit-content' }}>
                                                            <IconBtn
                                                                style={{ width: 'auto', padding: 0 }}
                                                                onClick={() => {
                                                                    if (refInput.current) {
                                                                        refInput.current.value = '';
                                                                        setFieldValue('files', []);
                                                                    }
                                                                }}>
                                                                <CustomSvg iconName='it-delete' className='icon icon-sm icon-danger' />
                                                            </IconBtn>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                                {!!values.files &&
                                                    Array.from(values.files).map((obj, index) => {
                                                        const { name, size } = obj.file;
                                                        const sizeToShow = (size / (1024 * 1024)).toFixed(2);
                                                        return (
                                                            <StyledFilesList key={obj.id}>
                                                                <StyledLi className={`upload-file ${EFileNameResult.success}`}>
                                                                    <CustomSvg iconName='it-file' aria-hidden='true' className='icon icon-sm' />
                                                                    <p>
                                                                        {name} <b className='upload-file-weight'>{sizeToShow} MB</b>
                                                                    </p>
                                                                </StyledLi>
                                                            </StyledFilesList>
                                                        );
                                                    })}
                                            </>
                                        )}
                                    />
                                )}
                            </div>
                            <StyledUpload type='submit' className='btn btn-primary btn-icon' disabled={loading || isDisabledBtn(values.files.length)}>
                                <CustomSvg iconName='it-upload' aria-hidden='true' className='icon icon-sm icon-white' />
                                <span>{t('btns.upload')}</span>
                            </StyledUpload>
                        </form>
                    );
                }}
            </Formik>
            <InfoBoxFactory type={flowType as EFlowType} />
        </>
    );
};

export default GenericFlowUpload;
