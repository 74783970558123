import ActionTable from '../../componets/CustomTable/ActionTable';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import { route } from '../../route';
import { useAction } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

interface IActionsProps {}
const Actions = (props: IActionsProps) => {
    const { t } = useTranslation();
    const { actions, isError, isLoading } = useAction();
    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.actions'), url: route.actions }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!!actions && actions.length > 0 && <ActionTable header={t('actions.tableName')} rows={actions} />}
        </Container>
    );
};

export default Actions;
