import { Modal, ProgressBar } from 'react-bootstrap';

import { Fragment } from 'react';
import { IProgressData } from '../../model';
import InfoButton from '../Buttons/InfoButton';
import { ToastWrapper } from '../../style';
import { displayFileName } from '../../utils';
import { get } from 'lodash';
import styled from 'styled-components';
import { t } from 'i18next';

const ModalBody = styled.div`
    padding: 0 1.5rem 1.5rem 1.5rem;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Span = styled.div`
    display: block;
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
`;

const StyledLi = styled.li`
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    list-style: disc inside;
`;

const LiWrapper = styled.div`
    margin-left: 2rem;
`;

const StyledModal = styled(Modal)`
    .modal-content {
        display: flex;
        flex-direction: column;
        width: 90%;
        max-width: 500px;
        pointer-events: auto;
        outline: 0;
        margin-left: auto;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
`;

const StyledProgressBar = styled(ProgressBar)`
    height: 1rem;
    .bg-info {
        background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
    }
`;

interface IModalProgress {
    progressData?: IProgressData[];
    vieModal: boolean;
    onClose: () => void;
}

const ModalProgress = (props: IModalProgress) => {
    const { progressData, vieModal, onClose } = props;

    const computeRatio = () => {
        let totalA = get(progressData, '[0].total', 0);
        let totalB = get(progressData, '[1].total', 0);
        let processedA = get(progressData, '[0].processed', 0);
        let processedB = get(progressData, '[1].processed', 0);
        const total = totalA + totalB;

        switch (true) {
            case total === 0:
                return 2;
            case (totalA === 0 || totalB === 0) && total !== 0:
                return (((processedA + processedB) / total) * 100) / 2;
            default:
                return ((processedA + processedB) / total) * 100;
        }
    };

    return (
        <StyledModal
            show={vieModal}
            onShow={computeRatio}
            onHide={() => {
                onClose();
            }}>
            <Modal.Header closeButton />
            <FlexContainer>
                <ModalBody>
                    {Array.isArray(progressData) &&
                        progressData.map(item => (
                            <Fragment key={crypto.randomUUID()}>
                                <ToastWrapper>
                                    <InfoButton filesNames={Array(item.fileName)}></InfoButton>
                                    <Span>{`File ${displayFileName(item.fileName)}`}</Span>
                                </ToastWrapper>
                                <LiWrapper>
                                    <StyledLi>{`${t('generic.processedRows')}: ${item.processed}`}</StyledLi>
                                    <StyledLi>{`${t('generic.totalRows')}: ${item.total}`}</StyledLi>
                                </LiWrapper>
                            </Fragment>
                        ))}
                </ModalBody>
            </FlexContainer>
            <StyledProgressBar animated now={computeRatio()} striped variant='info' />
        </StyledModal>
    );
};

export default ModalProgress;
