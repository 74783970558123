import { Container, ContainerNoGap, CustomDivOverview, RightTd, RightTh, TableRow } from '../../style';
import { IOverviewLabo, OverviewData } from '../../model';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import ExportExcelLabo from '../ExportExcel/ExportExcelLabo';
import GenericError from '../GenericError/GenericError';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { t } from 'i18next';
import { useContext } from 'react';
import { useLaboOverview } from '../../api/fetch';

const OverviewLabo = () => {
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useLaboOverview(loggedUser.userId);

    const sortByInstitute = (data: OverviewData[]) => {
        const dataToBeSorted = [...data];
        if (dataToBeSorted.length > 0) {
            return dataToBeSorted.sort((a, b) => {
                if (a.institute < b.institute) {
                    return -1;
                }
                if (a.institute > b.institute) {
                    return 1;
                }
                return 0;
            });
        }
        return dataToBeSorted;
    };

    const computeDifferences = (currentData: OverviewData[], lastData: OverviewData[]) => {
        let nextDifference: OverviewData = {
            institute: '',
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
            tot: 0,
        };
        let nextDifferences: OverviewData[] = [];
        if (!!currentData && !!lastData) {
            currentData.forEach(currData => {
                lastData.forEach(lastData => {
                    if (currData.institute === lastData.institute) {
                        nextDifference = {
                            institute: currData.institute,
                            jan: currData.jan - lastData.jan,
                            feb: currData.feb - lastData.feb,
                            mar: currData.mar - lastData.mar,
                            apr: currData.apr - lastData.apr,
                            may: currData.may - lastData.may,
                            jun: currData.jun - lastData.jun,
                            jul: currData.jul - lastData.jul,
                            aug: currData.aug - lastData.aug,
                            sep: currData.sep - lastData.sep,
                            oct: currData.oct - lastData.oct,
                            nov: currData.nov - lastData.nov,
                            dec: currData.dec - lastData.dec,
                            tot: currData.tot - lastData.tot,
                        };

                        nextDifferences = [...nextDifferences, nextDifference];
                    }
                });
            });
        }

        return nextDifferences;
    };

    const currentOverview = get(overview, 'overviewTableRows[1]', [] as IOverviewLabo[]);
    const lastOverview = get(overview, 'overviewTableRows[0]', [] as IOverviewLabo[]);
    const currentYear = get(currentOverview, 'year', '');
    const lastYear = get(lastOverview, 'year', '');
    const currentYearData = sortByInstitute(get(currentOverview, 'data', [] as OverviewData[]));
    const lastYearData = sortByInstitute(get(lastOverview, 'data', [] as OverviewData[]));
    const differences = computeDifferences(currentYearData, lastYearData);

    const clms = [
        { name: t('genericOverview.institute'), id: 2 },
        { name: t('months.0'), id: 3 },
        { name: t('months.1'), id: 4 },
        { name: t('months.2'), id: 5 },
        { name: t('months.3'), id: 6 },
        { name: t('months.4'), id: 7 },
        { name: t('months.5'), id: 8 },
        { name: t('months.6'), id: 9 },
        { name: t('months.7'), id: 10 },
        { name: t('months.8'), id: 11 },
        { name: t('months.9'), id: 12 },
        { name: t('months.10'), id: 13 },
        { name: t('months.11'), id: 14 },
        { name: t('genericOverview.total'), id: 15 },
    ];

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: 'overview-labo' }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            <ContainerNoGap>
                {!!overview && !isLoading && (
                    <CustomDivOverview>
                        <b>{currentYear}</b>
                        {overview.permissionSet.canExportOverview ? (
                            <ExportExcelLabo
                                currentYearRecs={{ year: currentYear, data: currentYearData }}
                                lastYearRecs={{ year: lastYear, data: lastYearData }}
                                differences={{ year: `${currentYear}/${lastYear}`, data: differences }}
                            />
                        ) : (
                            <></>
                        )}
                    </CustomDivOverview>
                )}
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                currentYearData.length > 0 &&
                                currentYearData.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                {!!overview && !isLoading && <b>{lastYear}</b>}
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                lastYearData.length > 0 &&
                                lastYearData.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
            <ContainerNoGap>
                {!!overview && !isLoading && <b>{`${currentYear} / ${lastYear}`}</b>}
                <div className='tableFixHead'>
                    <Table size='xl'>
                        <thead className='sticky'>
                            <tr className='sticky'>
                                {clms.map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <RightTh key={item.id} className={'table-dark'}>
                                                {item.name}
                                            </RightTh>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!!overview &&
                                !isLoading &&
                                differences.length > 0 &&
                                differences.map((data, index) => (
                                    <TableRow key={index}>
                                        <td>{data.institute}</td>
                                        <RightTd>{data.jan.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.feb.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.mar.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.apr.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.may.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jun.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.jul.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.aug.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.sep.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.oct.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.nov.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.dec.toLocaleString('de-DE')}</RightTd>
                                        <RightTd>{data.tot.toLocaleString('de-DE')}</RightTd>
                                    </TableRow>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </ContainerNoGap>
        </Container>
    );
};

export default OverviewLabo;
