import { PageTitle, ToastWrapper } from '../style';
import { useContext, useEffect } from 'react';

import { BELL } from '../utils';
import { Container } from 'react-bootstrap';
import CustomSvg from '../componets/Svg/CustomSvg';
import { ELanguages } from '../localization';
import { LoggedUserContext } from '../App';
import { useTranslation } from 'react-i18next';

interface IMsgProps {}
const Msg = (props: IMsgProps) => {
    const { t, i18n } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);

    useEffect(() => {
        localStorage.setItem(BELL, new Date().toISOString().split('T')[0]);
    }, []);

    return (
        <Container>
            <PageTitle>{t('routes.notifications')}</PageTitle>
            <div>
                {loggedUser.msgs.length > 0 &&
                    loggedUser.msgs.map((data: { messageIt: string; messageDe: string; messageId: string }, index) => (
                        <div className='flex-1 w-0 p-4' key={`${data.messageId}${index}`}>
                            <div className='d-flex items-start'>
                                <ToastWrapper>
                                    <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
                                </ToastWrapper>
                                <ToastWrapper>
                                    <blockquote className='blockquote'>
                                        <p className='mb-0'>{i18n.language === ELanguages.it ? data.messageIt : data.messageDe}</p>
                                    </blockquote>
                                </ToastWrapper>
                            </div>
                        </div>
                    ))}
            </div>
        </Container>
    );
};

export default Msg;
