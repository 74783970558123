import { Container, PageTitle } from '../../style';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { route } from '../../route';
import { useTranslation } from 'react-i18next';

interface INewFlowProps {}

const NewFlow = (props: INewFlowProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.flows'), url: '/' + route.flows },
                    { nameToShow: t('flows.new'), url: '' },
                ]}
            />

            <PageTitle>{t('flows.new')}</PageTitle>
        </Container>
    );
};

export default NewFlow;
