import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { EShippingStatus } from '../../model/enum';
import { get } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const StatusCircle = styled.div<{ $color?: string }>`
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: ${props => (props.$color ? props.$color : 'none')};
    border: 1px solid black;
`;
export const DetailStatus = (props: { status?: number; isQuequed?: boolean }) => {
    const { status, isQuequed } = props;
    const { t } = useTranslation();
    if (isQuequed) return <ProcessingShippingStatus status={status} />;
    let color = {
        [EShippingStatus.ShippingLoaded]: 'white',
        [EShippingStatus.CheckControlsPerformed]: 'yellow',
        [EShippingStatus.ImportRequest]: 'yellow',
        [EShippingStatus.AdminAuthorizesImport]: 'yellow',
        [EShippingStatus.ShippingImportedInFinal]: 'green',
        [EShippingStatus.ShippingSentToSogei]: 'green',
        [EShippingStatus.ShippingOKSogei]: 'green',
        [EShippingStatus.ShippingWrongSogei]: 'green',
        [EShippingStatus.CancellationRequest]: 'yellow',
        [EShippingStatus.ShippingCancelled]: 'red',
        [EShippingStatus.BlockingErrorsDuringControls]: 'red',
    };
    let textByColor = {
        [EShippingStatus.ShippingLoaded]: t('sendFiles.elab'),
        [EShippingStatus.CheckControlsPerformed]: t('sendFiles.elabOk'),
        [EShippingStatus.ImportRequest]: t('sendFiles.elabOk'),
        [EShippingStatus.AdminAuthorizesImport]: t('sendFiles.elabOk'),
        [EShippingStatus.ShippingImportedInFinal]: t('sendFiles.def'),
        [EShippingStatus.ShippingSentToSogei]: t('sendFiles.def'),
        [EShippingStatus.ShippingOKSogei]: t('sendFiles.def'),
        [EShippingStatus.ShippingWrongSogei]: t('sendFiles.def'),
        [EShippingStatus.CancellationRequest]: t('sendFiles.requestCancellation'),
        [EShippingStatus.ShippingCancelled]: t('sendFiles.deleted'),
        [EShippingStatus.BlockingErrorsDuringControls]: t('sendFiles.errorsDuringControls'),
    };
    return (
        <OverlayTrigger transition={false} placement='top' overlay={<Tooltip>{get(textByColor, `[${status}]`, '')}</Tooltip>}>
            <StatusCircle $color={get(color, `[${status}]`, 'red')} />
        </OverlayTrigger>
    );
};

const StatusSpan = styled.span<{ $color: string }>`
    font-size: 1rem;
    font-weight: 600;
    color: ${props => props.$color};
`;

const ProcessingShippingStatus = (props: any) => {
    const { status } = props;
    const { t } = useTranslation();
    switch (status) {
        case EShippingStatus.CheckControlsPerformed:
            return (
                <>
                    <StatusSpan $color='#191919'>{t('sendFiles.check')}</StatusSpan>
                    {/* <StatusBtn>Richiedi cancellazione</StatusBtn> */}
                </>
            );
        case EShippingStatus.CancellationRequest:
            return <StatusSpan $color='#CC334C'>{t('sendFiles.cancelled')}</StatusSpan>;
        case EShippingStatus.ShippingLoaded:
        default:
            return (
                <>
                    <StatusSpan $color='#c0681f'>{t('sendFiles.sent')}</StatusSpan>
                    {/* <StatusBtn>Richiedi cancellazione</StatusBtn> */}
                </>
            );
    }
};
