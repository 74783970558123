import axios, { AxiosHeaderValue, AxiosHeaders, AxiosRequestConfig, AxiosResponseHeaders, Method } from 'axios';
import { cryptData, decryptData } from '../utils';

import { _msal } from '../msal/AdHelper';
import { get } from 'lodash';
import { loginRequest } from '../msal/azureAd';

export interface IRequest<T = any> {
    url: string;
    baseURL: string;
    data?: T;
}

export interface IResponse<D = any> {
    data: D;
    status: number;
    statusText: string;
    headers: TResponseHeaders;
    config: IRequestConfig;
    request?: any;
}

export type TRequestHeaders = Record<string, AxiosHeaderValue | AxiosHeaders>;

interface IRequestConfig extends AxiosRequestConfig {}

type TResponseHeaders = AxiosResponseHeaders;

type TMethod = Method;

const httpClient = () => {
    const URL = process.env.REACT_APP_NEW_API_URL;
    const URL_upload = process.env.REACT_APP_API_URL;

    const _instance = axios.create({
        timeout: parseInt(process.env.TIMEOUT_HTTPCLIENT as string),
    });

    const getToken = () => {
        return `Bearer ${localStorage.getItem('token')}`;
    };

    _instance.interceptors.response.use(
        res => res,
        async error => {
            console.log(error);
            // Do something with response error
            const originalRequest = error.config;
            if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
                originalRequest._retry = true;
                let account = _msal.getActiveAccount();
                if (!!account) {
                    const access_token = await _msal.acquireTokenSilent({ account, ...loginRequest });
                    localStorage.setItem('token', access_token.accessToken);
                    originalRequest.headers['Authorization'] = 'Bearer ' + access_token.accessToken;
                } else {
                    _msal.loginRedirect();
                    window.location.reload();
                }

                return _instance(originalRequest);
            }

            return Promise.reject(error);
        },
    );

    const fixUrl = (url: string): string => {
        let newUrl = `Sdo/GetShippingDetailFiltered?userId=123&year=345&month=6`;
        const splittedUrl = url.split('?');
        const defaultUrl = get(splittedUrl, '[0]', '');
        const queryString = get(splittedUrl, '[1]', '');
        console.log(defaultUrl);
        console.log(queryString);

        const cript = cryptData(queryString);
        let decrip = decryptData(cript);
        console.log(cryptData(queryString));
        console.log(decrip);
        if (!!queryString) {
            return `${defaultUrl}?${cryptData(queryString)}`;
        }

        return defaultUrl;
    };

    const fetcher = (url: string) => _instance.get(URL + url, { headers: { Authorization: getToken() } }).then(res => res.data);
    const fetcherNoCache = (url: string) => _instance.get(URL + url, { headers: { Authorization: getToken(), 'Clear-Site-Data': 'cache' } }).then(res => res.data);
    const postRequest = (url: string, { arg }: { arg: any }) => _instance.post(URL + url, arg, { headers: { Authorization: getToken() } }).then(res => res.data);
    const sendFileRequest = (url: string, { arg }: { arg: FormData }) =>
        _instance.post(URL + url, arg, { headers: { Authorization: getToken(), 'Content-Type': 'multipart/form-data' } }).then(res => res.data);
    const sendFileRequestOld = (url: string, { arg }: { arg: FormData }) =>
        _instance.post(URL_upload + url, arg, { headers: { Authorization: getToken(), 'Content-Type': 'multipart/form-data' } }).then(res => res.data);
    const putRequest = (url: string, { arg }: { arg: any }) => _instance.put(URL + url, arg, { headers: { Authorization: getToken() } }).then(res => res.data);

    return {
        fetcher,
        fetcherNoCache,
        postRequest,
        putRequest,
        sendFileRequest,
        sendFileRequestOld,
    };
};

export default httpClient();
