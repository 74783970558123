import BreadCrumb from '../Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import { EFlowType } from '../../model/enum';
import { IShowModal } from '../../model';
import { LoggedUserContext } from '../../App';
import OverviewTableRecovery from '../OverviewTable/OverviewTableRecovery';
import { t } from 'i18next';
import { useContext } from 'react';
import { useRecoveryOverview } from '../../api/fetch';

export const emptyModal: IShowModal = {
    show: false,
    year: 0,
    month: 0,
    cancelled: false,
};
interface IOverviewGenericProps {
    flow: EFlowType;
}
const OverviewRecovery = (props: IOverviewGenericProps) => {
    const { flow } = props;
    const { loggedUser } = useContext(LoggedUserContext);
    const { overview, isError, isLoading } = useRecoveryOverview(loggedUser.userId, flow);

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.overview'), url: `overview-${flow}` }]} />
            <OverviewTableRecovery flow={flow} isError={isError} isLoading={isLoading} overview={overview} />
        </Container>
    );
};

export default OverviewRecovery;
