import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { IOverviewSpa, IOverviewSpaData, MonthlyDataSpa } from '../../model';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getCurrentMonthAndYear, getMonthNameByApi } from '../../utils';

import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { t } from 'i18next';

interface IExportExcelSpaProps {
    currentYearRecs: IOverviewSpa;
    lastYearRecs: IOverviewSpa;
    differences: IOverviewSpaData[];
}

const ExportExcelSpa = (props: IExportExcelSpaProps) => {
    const { currentYearRecs, lastYearRecs, differences } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'SPA-Overview';

    const mapMonthlyData = (instituteData: IOverviewSpaData, year: string) => {
        return Object.entries(instituteData)
            .map(([key, value]) => {
                if (key !== 'institute' && value) {
                    return {
                        [t('genericOverview.clmYear')]: year,
                        [t('genericOverview.institute')]: instituteData.institute,
                        [t('genericOverview.clmMonth')]: t(getMonthNameByApi(key)),
                        [t('genericOverview.white')]: value.bianca,
                        [t('genericOverview.red')]: value.rossa,
                        [t('genericOverview.electric')]: value.elettronica,
                        [t('genericOverview.total')]: value.totale,
                    };
                }
                return null;
            })
            .filter(row => row !== null);
    };

    const exportToExcel = () => {
        if (currentYearRecs && lastYearRecs && differences) {
            const titleRow = {
                [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                [t('genericOverview.institute')]: t('genericOverview.institute'),
                [t('genericOverview.clmMonth')]: t('genericOverview.clmMonth'),
                [t('genericOverview.white')]: t('genericOverview.white'),
                [t('genericOverview.red')]: t('genericOverview.red'),
                [t('genericOverview.electric')]: t('genericOverview.electric'),
                [t('genericOverview.total')]: t('genericOverview.total'),
            };

            const emptyRow = {
                [t('genericOverview.clmYear')]: '',
                [t('genericOverview.institute')]: '',
                [t('genericOverview.clmMonth')]: '',
                [t('genericOverview.white')]: '',
                [t('genericOverview.red')]: '',
                [t('genericOverview.electric')]: '',
                [t('genericOverview.total')]: '',
            };

            const finalRows = [
                ...currentYearRecs.data.map(instituteData => mapMonthlyData(instituteData, getCurrentMonthAndYear().currentYear.toString())),
                emptyRow,
                titleRow,
                ...lastYearRecs.data.map(instituteData => mapMonthlyData(instituteData, (getCurrentMonthAndYear().currentYear - 1).toString())),
                emptyRow,
                titleRow,
                ...differences.map(instituteData => mapMonthlyData(instituteData, `${getCurrentMonthAndYear().currentYear}/${getCurrentMonthAndYear().currentYear - 1}`)),
            ];

            const ws = XLSX.utils.json_to_sheet(finalRows.flat());
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} placement='top' overlay={<Tooltip>{`${t('generic.downloadExcel', { flow: 'SPA' })}`}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelSpa;
