import { EFileNameResult, EFrequency, EShippingStatus, Months, MonthsByApi } from '../model/enum';
import {
    IMeasure,
    IOverviewBE,
    IOverviewFarmosp,
    IOverviewFim,
    IOverviewRecoveryWithPermissions,
    IOverviewSps,
    IRecurrenceById,
    IShippingDetailGeneric,
    ShippingDetail,
    ShippingDetailSdor,
} from '../model';

import CryptoJS from 'crypto-js';
import { format } from 'date-fns';
import { get } from 'lodash';
import { t } from 'i18next';

export const checkFiles = (first: File, sec: File): { status: EFileNameResult; msg: string }[] => {
    let firstFileName = first.name.split('.');
    let secondFileName = sec.name.split('.');
    let firstData = firstFileName[0].split('_');
    let secondData = secondFileName[0].split('_');
    let isAnaAndCli = (firstData[5] === 'ANA' && secondData[5] === 'CLI') || (firstData[5] === 'CLI' && secondData[5] === 'ANA');
    let otherFieldMatch = firstData[0] === secondData[0] && firstData[1] === secondData[1] && firstData[2] === secondData[2] && firstData[3] === secondData[3] && firstData[4] === secondData[4];
    if (isAnaAndCli && otherFieldMatch) return [checkFileName(first.name), checkFileName(sec.name)];
    return [
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
        { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') },
    ];
};
export const checkFileSdor = (file: File): { status: EFileNameResult; msg: string }[] => {
    let fileName = file.name.split('.');
    let data = fileName[0].split('_');
    let isRia = data[5] === 'RIA';
    if (isRia) return [checkFileNameSdor(file.name)];
    return [{ status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') }];
};

export const checkFileNameSdor = (name: string): { status: EFileNameResult; msg: string } => {
    if (!name) return { status: EFileNameResult.error, msg: t('sendFiles.insertFile') };
    try {
        const fileName = name.split('.');
        let data = fileName[0].split('_');
        if (data.length === 6) {
            let dates = data[3];
            if (dates.length === 8) {
                let year = dates.slice(0, 4);
                let month = dates.slice(4, 6);
                let day = dates.slice(6, 8);
                let yearNum = /^\d+$/.test(year);

                if (!yearNum) {
                    return { status: EFileNameResult.error, msg: t('sendFiles.yearFile') };
                }
                let monthNum = /^\d+$/.test(month);
                let dayNum = /^\d+$/.test(day);
                if (!monthNum || !(monthNum && parseInt(month) > 0 && parseInt(month) < 13)) {
                    return { status: EFileNameResult.error, msg: t('sendFiles.monthErrorFile') };
                }
                if (!dayNum || !(dayNum && parseInt(day) > 0 && parseInt(day) < 32)) {
                    return { status: EFileNameResult.error, msg: t('sendFiles.dayErrorFile') };
                }
            } else {
                return { status: EFileNameResult.error, msg: t('sendFiles.dateErrorFile') };
            }
            if (data[4] === 'SDO' && data[5] === 'RIA') return { status: EFileNameResult.success, msg: '' };
        }
        return { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') };
    } catch (err) {
        return { status: EFileNameResult.error, msg: t('sendFiles.emptyError') };
    }
};

export const checkFileName = (name: string): { status: EFileNameResult; msg: string } => {
    if (!name) return { status: EFileNameResult.error, msg: t('sendFiles.insertFile') };
    try {
        const fileName = name.split('.');
        let data = fileName[0].split('_');
        if (data.length === 6) {
            let dates = data[3];
            if (dates.length === 8) {
                let year = dates.slice(0, 4);
                let month = dates.slice(4, 6);
                let day = dates.slice(6, 8);
                let yearNum = /^\d+$/.test(year);

                if (!yearNum) {
                    return { status: EFileNameResult.error, msg: t('sendFiles.yearFile') };
                }
                let monthNum = /^\d+$/.test(month);
                let dayNum = /^\d+$/.test(day);
                if (!monthNum || !(monthNum && parseInt(month) > 0 && parseInt(month) < 13)) {
                    return { status: EFileNameResult.error, msg: t('sendFiles.monthErrorFile') };
                }
                if (!dayNum || !(dayNum && parseInt(day) > 0 && parseInt(day) < 32)) {
                    return { status: EFileNameResult.error, msg: t('sendFiles.dayErrorFile') };
                }
            } else {
                return { status: EFileNameResult.error, msg: t('sendFiles.dateErrorFile') };
            }
            if (data[4] === 'SDO' && (data[5] === 'ANA' || data[5] === 'CLI')) return { status: EFileNameResult.success, msg: '' };
        }
        return { status: EFileNameResult.error, msg: t('sendFiles.fileFormatError') };
    } catch (err) {
        return { status: EFileNameResult.error, msg: t('sendFiles.emptyError') };
    }
};

export const normalizeCalendaringFrequency = (recurrences: IRecurrenceById[]): any[] => {
    if (Array.isArray(recurrences) && recurrences.length > 0) {
        let type: EFrequency | undefined = get(recurrences, '[0].recurrenceType', undefined);
        if (type) {
            switch (type) {
                case EFrequency.monthly:
                    return recurrences.map((m, index) => ({
                        value: new Date(m.recurrenceDate).getDate(),
                        label: `months.${new Date(m.recurrenceDate).getMonth()}`,
                        id: m.recurrenceId,
                        month: new Date(m.recurrenceDate).getMonth() + 1,
                    }));
                case EFrequency.weekly:
                    return recurrences.map((m, index) => ({ value: new Date(m.recurrenceDate).getDay(), label: `day.${index}`, id: m.recurrenceId }));
                case EFrequency.quarterly:
                case EFrequency.yearly:
                    return recurrences.map((m, index) => {
                        return {
                            value: new Date(m.recurrenceDate).toISOString().slice(0, 10),
                            label: `calendaring.selectDay`,
                            id: m.recurrenceId,
                        };
                    });
            }
        }
    }
    return [];
};

export const normalizeRepetitions = (dates: any[], frequency: EFrequency): any[] => {
    if (Array.isArray(dates) && dates.length > 0) {
        switch (frequency) {
            case EFrequency.monthly:
                // check on null is done to prevent the BE receiving null values. Empty input are set automatically to 1
                return dates.map(d => (d.value === null ? `${'1'}/${d.month}` : `${d.value}/${d.month}`));
            case EFrequency.weekly:
            case EFrequency.yearly:
            case EFrequency.quarterly:
                return dates.filter(d => !!d && !!d.value).map(d => d.value);
            default:
                return [];
        }
    }
    return [];
};

export const getNegativeRandom = () => {
    return -Math.floor(Math.random() * 1000000000000000);
};

export const filterByUserName = (data: ShippingDetail, userName: string | null) => {
    return !userName || data.userName.toLowerCase().includes(userName.toLowerCase());
};

export const filterByStatus = (data: ShippingDetail, status: number | null) => {
    return !status || status < 0 || checkStatus(status, data.shippingStatus);
};

export const filterByDate = (data: ShippingDetail, date: string | null) => {
    return !date || data.receiptDate.substring(0, 10) === date;
};

export const filterBySped = (data: ShippingDetail, sped: string | null) => {
    if (!sped) return true;
    const lowerSped = sped.toLowerCase();
    return data.id === parseInt(sped) || data.fileNameAnag.toLowerCase().includes(lowerSped) || data.fileNameClin.toLowerCase().includes(lowerSped);
};

export const filterByUserNameGeneric = (data: IShippingDetailGeneric, userName: string | null) => {
    //Il controllo dei filtri non viene fatto direttamente su il campo username, ma sui nomi della spedizione.
    //Questo perché dal BE arriva sempre username = synapse_adm
    if (!userName) return true;
    const lowerUsername = userName.toLowerCase();
    return (
        data.fileName1.toLowerCase().includes(lowerUsername) ||
        data.fileName2.toLowerCase().includes(lowerUsername) ||
        data.fileName3.toLowerCase().includes(lowerUsername) ||
        data.fileName4.toLowerCase().includes(lowerUsername) ||
        data.fileName5.toLowerCase().includes(lowerUsername) ||
        data.fileName6.toLowerCase().includes(lowerUsername)
    );
};

export const filterByUserNameSdor = (data: ShippingDetailSdor, userName: string | null) => {
    return !userName || data.userName.toLowerCase().includes(userName.toLowerCase());
};

export const filterByStatusGeneric = (data: IShippingDetailGeneric, status: number | null) => {
    return !status || status < 0 || checkStatusSiar(status, data.shippingStatus);
};
export const filterByStatusSdor = (data: ShippingDetailSdor, status: number | null) => {
    return !status || status < 0 || checkStatus(status, data.shippingStatus);
};

export const filterByDateGeneric = (data: IShippingDetailGeneric, date: string | null) => {
    return !date || data.receiptDate.substring(0, 10) === date;
};
export const filterByDateSdor = (data: ShippingDetailSdor, date: string | null) => {
    return !date || data.receiptDate.substring(0, 10) === date;
};

export const filterBySpedGeneric = (data: IShippingDetailGeneric, sped: string | null) => {
    if (!sped) return true;
    const lowerSped = sped.toLowerCase();
    return (
        data.id === parseInt(sped) ||
        data.fileName1.toLowerCase().includes(lowerSped) ||
        data.fileName2.toLowerCase().includes(lowerSped) ||
        data.fileName3.toLowerCase().includes(lowerSped) ||
        data.fileName4.toLowerCase().includes(lowerSped) ||
        data.fileName5.toLowerCase().includes(lowerSped) ||
        data.fileName6.toLowerCase().includes(lowerSped)
    );
};
export const filterBySpedSdor = (data: ShippingDetailSdor, sped: string | null) => {
    if (!sped) return true;
    const lowerSped = sped.toLowerCase();
    return data.id === parseInt(sped) || data.fileNameRiab.toLowerCase().includes(lowerSped);
};

export const checkStatusSiar = (status: number, shippingStatus: number | null): boolean =>
    (status === 1 && (shippingStatus === EShippingStatus.CheckControlsPerformed || shippingStatus === EShippingStatus.ImportRequest || shippingStatus === EShippingStatus.AdminAuthorizesImport)) ||
    (status === 2 && shippingStatus === EShippingStatus.ShippingLoaded) ||
    (status === 4 && (shippingStatus === EShippingStatus.ShippingCancelled || shippingStatus === EShippingStatus.CancellationRequest)) ||
    (status === 3 && shippingStatus === EShippingStatus.ShippingImportedInFinal);

export const checkStatus = (status: number, shippingStatus: number): boolean =>
    (status === 1 && (shippingStatus === EShippingStatus.CheckControlsPerformed || shippingStatus === EShippingStatus.ImportRequest || shippingStatus === EShippingStatus.AdminAuthorizesImport)) ||
    (status === 2 && shippingStatus === EShippingStatus.ShippingLoaded) ||
    (status === 4 && (shippingStatus === EShippingStatus.ShippingCancelled || shippingStatus === EShippingStatus.CancellationRequest)) ||
    (status === 3 && shippingStatus === EShippingStatus.ShippingImportedInFinal);

export const checkFilters = (data: ShippingDetail, sped: string | null, date: string | null, status: number | null, userName: string | null) =>
    filterByUserName(data, userName) && filterByStatus(data, status) && filterByDate(data, date) && filterBySped(data, sped);

export const checkFiltersGeneric = (data: IShippingDetailGeneric, sped: string | null, date: string | null, status: number | null, userName: string | null) =>
    filterByUserNameGeneric(data, userName) && filterByStatusGeneric(data, status) && filterByDateGeneric(data, date) && filterBySpedGeneric(data, sped);

export const checkFiltersSdor = (data: ShippingDetailSdor, sped: string | null, date: string | null, status: number | null, userName: string | null) =>
    filterByUserNameSdor(data, userName) && filterByStatusSdor(data, status) && filterByDateSdor(data, date) && filterBySpedSdor(data, sped);

export const formatDate = (date?: string, removeHours?: boolean): string => {
    try {
        if (!date) return '';
        let dateTime = new Date(date);
        if (isNaN(dateTime.getTime())) {
            return '';
        }
        if (removeHours) {
            dateTime.setHours(dateTime.getHours() + 2);
        }
        return format(dateTime, 'dd/MM/yyyy HH:mm');
    } catch (err) {
        console.log(err);
        return '';
    }
};

export const downloadFile = (url: string) => {
    const hiddenLink = document.createElement('a');
    hiddenLink.href = url;
    let download = url.split('/').pop();
    hiddenLink.setAttribute('download', download ? download : '');
    hiddenLink.setAttribute('target', '_blank');
    document.body.appendChild(hiddenLink);
    hiddenLink.click();
    document.body.removeChild(hiddenLink);
};

export const displayFileName = (filename: string) => {
    let fileType: string = '';
    const fileNameSegments: string[] = ['ANA', 'CLI', 'RIA'];

    fileNameSegments.forEach(item => {
        if (filename.includes(item)) {
            fileType = item;
        }
    });

    switch (fileType) {
        case '':
            return '';
        case 'ANA':
            return t('generic.anagrafic');
        case 'CLI':
            return t('generic.clinical');
        case 'RIA':
            return t('generic.rehabilitative');
    }

    return '';
};

export const isAxiosError = (error: any): boolean => {
    if (error && error.isAxiosError) {
        return true;
    }
    return false;
};

export const getMonthName = (monthNumber: string): string => {
    if (!!monthNumber) {
        let trimmedParam = monthNumber.trim();
        let month;
        if (trimmedParam.length === 1) {
            if (trimmedParam !== '0') {
                month = '0'.concat(trimmedParam);
            }
        } else {
            month = trimmedParam;
        }

        switch (month) {
            case '01':
                return t('months.0');
            case '02':
                return t('months.1');
            case '03':
                return t('months.2');
            case '04':
                return t('months.3');
            case '05':
                return t('months.4');
            case '06':
                return t('months.5');
            case '07':
                return t('months.6');
            case '08':
                return t('months.7');
            case '09':
                return t('months.8');
            case '10':
                return t('months.9');
            case '11':
                return t('months.10');
            case '12':
                return t('months.11');
            default:
                return '0';
        }
    }
    return '';
};

export const cryptData = (data: any): string => {
    if (!!data) {
        const dataStringfied = JSON.stringify(data);
        // const key = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_KEY' ?? '');
        // const iv = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_IV' ?? '');
        const key = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_KEY');
        const iv = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_IV');
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(dataStringfied), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
    return '';
};

export const decryptData = (data: any): any => {
    if (!!data) {
        // const key = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_KEY' ?? '');
        // const iv = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_IV' ?? '');
        const key = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_KEY');
        const iv = CryptoJS.enc.Utf8.parse('process.env.REACT_APP_CRYPT_IV');
        const de = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        let decryptedData = de.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }
    return '';
};

export const isSiag = (role: string) => !!role && role === 'SIAG';
export const isPab = (role: string) => !!role && role === 'PAB';

export const isValidDate = (dateStr: string) => {
    if (dateStr.length !== 8) return false;

    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10);
    const day = parseInt(dateStr.substring(6, 8), 10);
    if (month < 1 || month > 12) return false;

    const date = new Date(year, month - 1, day);

    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

export const isValidDateShortFormat = (dateStr: string) => {
    if (dateStr.length !== 6) return false;

    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10);

    if (month < 1 || month > 12) return false;

    const date = new Date(year, month - 1);

    return date.getFullYear() === year && date.getMonth() === month - 1;
};

export const allEqual = (data: string[]) => new Set(data).size === 1;

export const computeTotalsAndDifference = (data: IOverviewFim[]): { totalSdoHosps: number; totalFimHosps: number; totalDifference: number } => {
    let totalSdoHosps = 0;
    let totalFimHosps = 0;

    data.forEach(item => {
        totalSdoHosps += item.sdoHosps;
        totalFimHosps += item.fimHosps;
    });

    const totalDifference = totalSdoHosps - totalFimHosps;

    return {
        totalSdoHosps,
        totalFimHosps,
        totalDifference,
    };
};

export const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;

export const getCurrentMonthAndYear = (): { currentMonth: number; currentYear: number } => {
    const now: Date = new Date();
    const currentMonth: number = now.getMonth() + 1;
    const currentYear: number = now.getFullYear();
    return { currentMonth, currentYear };
};

export const sortByInstitute = (arr: IOverviewSps[]) => {
    return arr.sort((a, b) => {
        const nameA = a.institute.toUpperCase();
        const nameB = b.institute.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};

export const removeUnderscore = (str: string): string => {
    if (str.includes('_')) {
        return str.split('_').join('');
    }
    return str;
};

export const BELL = 'show-bell';

export const showBell = () => {
    const date = localStorage.getItem(BELL);
    if (!date) {
        return true;
    }
    const today = new Date().toISOString().split('T')[0];
    return !(today === date);
};

export const sortMeasureByInstitute = (arr: IMeasure[]) => {
    const sortedArr = [...arr];
    return sortedArr.sort((a, b) => {
        const nameA = a.institute.toUpperCase();
        const nameB = b.institute.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};

export const getMonthNameByApi = (month: string): string => {
    const monthKey = month.toUpperCase() as keyof typeof Months;
    return MonthsByApi[Months[monthKey]];
};
