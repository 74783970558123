import { Container, PageTitle } from '../../style';

import { AlertPromise } from '../../componets/Alert/Alert';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Btn } from '../../componets/Buttons/Buttons';
import { Formik } from 'formik';
import { IActionsBE } from '../../model';
import { TextInput } from '../../componets/Input/Input';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface INewActionProps {
    actionToEdit?: IActionsBE;
}

const NewAction = (props: INewActionProps) => {
    const { t } = useTranslation();
    const { actionToEdit } = props;
    const navigate = useNavigate();
    let initialValues = actionToEdit ? { descriptionDe: actionToEdit.descriptionDe, descriptionIt: actionToEdit.descriptionIt } : { descriptionDe: '', descriptionIt: '' };
    const { trigger } = useSWRMutation('Action/Edit', httpClient.putRequest);
    const editAction = useCallback(async (values: { descriptionIt: string; descriptionDe: string }) => {
        if (!!actionToEdit) {
            AlertPromise(t('generic.editOingoing'), trigger({ ...values, id: actionToEdit.id }), t('actions.editOk'), t('actions.editKo'), () => navigate('/' + route.actions));
        }
    }, []);

    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.actions'), url: '/' + route.actions },
                    { nameToShow: actionToEdit ? t('actions.edit') : t('actions.new'), url: '' },
                ]}
            />
            <PageTitle>{actionToEdit ? t('actions.edit') : t('actions.new')}</PageTitle>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async values => {
                    await editAction(values);
                }}>
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <TextInput name='descriptionIt' label={t('actions.editIt')} />
                        <TextInput name='descriptionDe' label={t('actions.editDe')} />
                        <Btn type='submit' disabled={isSubmitting} text={t('btns.save')} />
                    </form>
                )}
            </Formik>
        </Container>
    );
};

export default NewAction;
