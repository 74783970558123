import { Container, Div, FlexCenter, HeaderTh, MyPaginate, PageTitle, StyledTable } from '../../style';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { IFilterSendFile, IShippingDetailGeneric } from '../../model';
import { ITEMS_PER_PAGE, initialDataSendFile } from '../../utils/constants';
import { checkFiltersGeneric, getNegativeRandom, removeUnderscore } from '../../utils';

import BreadCrumb from '../Breadcrumb/BreadCrumb';
import { EFlowType } from '../../model/enum';
import GenericError from '../GenericError/GenericError';
import GenericFlowFilters from '../Filter/GenericFlowFilters';
import GenericFlowUpload from '../SendFilesSection/GenericFlowUpload';
import Loading from '../Loading/Loading';
import { LoggedUserContext } from '../../App';
import { Table } from 'react-bootstrap';
import UploadTableGenericFlow from '../CustomTable/UploadTableGenericFlow';
import { get } from 'lodash';
import { getAllShippingsByFlow } from '../../api/fetch';
import { route } from '../../route';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

interface IFlowFactory {
    flowType: string;
}
interface IGenericFlowContext {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    freezedShippings: IShippingDetailGeneric[];
    shippings: IShippingDetailGeneric[];
    setShippings: Dispatch<SetStateAction<IShippingDetailGeneric[]>>;
    itemOffset: number;
    filter: IFilterSendFile;
    setFilter: Dispatch<SetStateAction<IFilterSendFile>>;
    setItemOffset: Dispatch<SetStateAction<number>>;
    setFreezedShippings: Dispatch<SetStateAction<IShippingDetailGeneric[]>>;
    flowType: string;
}
export const GenericFlowContext = createContext<IGenericFlowContext>({
    filter: initialDataSendFile,
    setFilter: () => {},
    itemOffset: 0,
    setItemOffset: () => {},
    page: 0,
    setPage: () => {},
    freezedShippings: [],
    shippings: [],
    setShippings: () => {},
    setFreezedShippings: () => {},
    flowType: '',
});

const GenericFlow = ({ flowType }: IFlowFactory) => {
    const { t } = useTranslation();
    const { loggedUser } = useContext(LoggedUserContext);
    const { instance } = useMsal();
    const { shippings, isLoading, isError, isValidating } = getAllShippingsByFlow(flowType as unknown as string, loggedUser.userId);
    const { pendingShipping, permissions } = shippings;

    const [refresh, setRefresh] = useState<boolean>(false);

    const [uploadedFiles, setUploadedFiles] = useState<IShippingDetailGeneric[]>(pendingShipping);
    const [freezedFiles, setFreezedFiles] = useState<IShippingDetailGeneric[]>(pendingShipping);

    const [filter, setFilter] = useState<IFilterSendFile>(initialDataSendFile);

    const [itemOffset, setItemOffset] = useState(0);
    const [page, setPage] = useState(0);

    const lastName = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.family_name', 'username'));
    const name = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.given_name', 'username'));

    const updateCall = async (files: string[]) => {
        let data = [
            {
                userName: `${name.charAt(0) + lastName}`,
                receiptDate: new Date().toString(),
                id: getNegativeRandom(),
                fileName1: files[0] ?? '',
                fileName2: files[1] ?? '',
                fileName3: files[2] ?? '',
                fileName4: files[3] ?? '',
                fileName5: files[4] ?? '',
                fileName6: files[5] ?? '',
                fileName1Path: '',
                fileName2Path: '',
                fileName3Path: '',
                fileName4Path: '',
                fileName5Path: '',
                fileName6Path: '',
                logFileName: '',
                logFilePath: '',
                processingDate: undefined,
                error: false,
                queued: true,
                shippingStatus: 0,
                changeStatusDate: null,
                csvSogeiFilePath: '',
                pdfSogeiFilePath: '',
            } as IShippingDetailGeneric,
            ...uploadedFiles,
        ];

        setUploadedFiles(data);
        setFreezedFiles([
            {
                userName: `${name.charAt(0) + lastName}`,
                receiptDate: new Date().toString(),
                id: getNegativeRandom(),
                fileName1: files[0] ?? '',
                fileName2: files[1] ?? '',
                fileName3: files[2] ?? '',
                fileName4: files[3] ?? '',
                fileName5: files[4] ?? '',
                fileName6: files[5] ?? '',
                fileName1Path: '',
                fileName2Path: '',
                fileName3Path: '',
                fileName4Path: '',
                fileName5Path: '',
                fileName6Path: '',
                logFileName: '',
                logFilePath: '',
                processingDate: undefined,
                error: false,
                queued: true,
                shippingStatus: 0,
                changeStatusDate: null,
                csvSogeiFilePath: '',
                pdfSogeiFilePath: '',
            } as IShippingDetailGeneric,
            ...freezedFiles,
        ]);
        setPage(0);
        setItemOffset(0);
        setFilter(initialDataSendFile);
        setRefresh(true);
    };

    useEffect(() => {
        if (!isValidating && !!pendingShipping) {
            if (!!filter.date || !!filter.sped || !!filter.userName || (!!filter.status && filter.status > 0)) {
                setUploadedFiles(pendingShipping.filter(data => checkFiltersGeneric(data, filter.sped, filter.date, filter.status, filter.userName)));
            } else {
                setUploadedFiles([...pendingShipping]);
                setFreezedFiles([...pendingShipping]);
            }
        }
    }, [isValidating]);

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * ITEMS_PER_PAGE) % uploadedFiles.length;
        setItemOffset(newOffset);
        setPage(event.selected);
    };

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    const pageCount = Math.ceil(uploadedFiles.length / ITEMS_PER_PAGE);

    const clm =
        flowType.toUpperCase() === EFlowType.Spa.toUpperCase()
            ? [
                  { name: t('sdoOverview.clmDateUp'), id: 0 },
                  { name: t('sdoOverview.fileNames'), id: 1 },
                  { name: t('sdoOverview.clmNameShip'), id: 2 },
                  { name: t('sdoOverview.clmUsername'), id: 3 },
                  { name: t('sdoOverview.clmLog'), id: 4 },
                  { name: t('sdoOverview.clmStaSp'), id: 5 },
                  { name: 'SOGEI', id: 6 },
                  { name: '', id: 7 },
                  { name: '', id: 8 },
              ]
            : [
                  { name: t('sdoOverview.clmDateUp'), id: 0 },
                  { name: t('sdoOverview.fileNames'), id: 1 },
                  { name: t('sdoOverview.clmNameShip'), id: 2 },
                  { name: t('sdoOverview.clmUsername'), id: 3 },
                  { name: t('sdoOverview.clmLog'), id: 4 },
                  { name: t('sdoOverview.clmStaSp'), id: 5 },
                  { name: '', id: 6 },
              ];

    return (
        <Container>
            <GenericFlowContext.Provider
                value={{
                    page,
                    setPage,
                    itemOffset,
                    setItemOffset,
                    setFreezedShippings: setFreezedFiles,
                    freezedShippings: freezedFiles,
                    shippings: uploadedFiles,
                    setShippings: setUploadedFiles,
                    filter,
                    setFilter,
                    flowType: `${flowType}`,
                }}>
                <BreadCrumb paths={[{ nameToShow: t('routes.sendFile'), url: route.sendFilesSiar }]} />
                <PageTitle>{flowType}</PageTitle>
                {!!permissions && permissions.canUpload && <GenericFlowUpload updateCall={updateCall} />}
                <PageTitle>{t('generic.sendFileTitle')}</PageTitle>
                {isError && <GenericError />}
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        {!refresh && <GenericFlowFilters />}
                        {freezedFiles.length > 0 && (
                            <StyledTable>
                                <Table size='sm' responsive='xl'>
                                    <thead>
                                        <tr>
                                            {clm.map(data => (
                                                <HeaderTh key={data.id} className={'table-dark'}>
                                                    {data.name}
                                                </HeaderTh>
                                            ))}
                                        </tr>
                                    </thead>

                                    <UploadTableGenericFlow permissions={permissions} />
                                </Table>
                            </StyledTable>
                        )}
                        <FlexCenter>{!freezedFiles.length && <p>{t('generic.noShipping')}</p>}</FlexCenter>
                        <Div>
                            {pageCount > 0 && (
                                <MyPaginate
                                    breakLabel='...'
                                    nextLabel='>'
                                    forcePage={page}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel={'<'}
                                    renderOnZeroPageCount={null}
                                />
                            )}
                        </Div>
                    </>
                )}
            </GenericFlowContext.Provider>
        </Container>
    );
};

export default GenericFlow;
