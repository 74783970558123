import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ExcelSvg from '../Svg/ExcelSvg';
import { IOverviewFarmosp } from '../../model';
import { IconBtn } from '../Buttons/Buttons';
import { t } from 'i18next';

interface IExportExcelFarmospprops {
    overviewData: IOverviewFarmosp[];
}

const ExportExcelFarmosp = (props: IExportExcelFarmospprops) => {
    const { overviewData } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'FARMOSP-Overview';

    const exportToExcel = () => {
        const finalRows = [
            ...overviewData.map(row => ({
                [t('genericOverview.healthCompany')]: row.structureCode,
                [t('months.0')]: row.jan,
                [t('months.1')]: row.feb,
                [t('months.2')]: row.mar,
                [t('months.3')]: row.apr,
                [t('months.4')]: row.may,
                [t('months.5')]: row.jun,
                [t('months.6')]: row.jul,
                [t('months.7')]: row.aug,
                [t('months.8')]: row.sep,
                [t('months.9')]: row.oct,
                [t('months.10')]: row.nov,
                [t('months.11')]: row.dec,
                [t('generic.total')]: row.tot,
            })),
        ];

        const ws = XLSX.utils.json_to_sheet(finalRows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: 'FARMOSP' })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelFarmosp;
