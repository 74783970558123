import { AlertError, AlertPromise } from '../Alert/Alert';
import { FieldArray, Formik } from 'formik';
import { FileInfoBox, FormatContainer, StyledFilesList, StyledLabel, StyledLi, StyledUpload } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkFiles, removeUnderscore } from '../../utils';
import { useContext, useRef, useState } from 'react';

import CustomSvg from '../Svg/CustomSvg';
import { EFileNameResult } from '../../model/enum';
import { IconBtn } from '../Buttons/Buttons';
import { LoggedUserContext } from '../../App';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import { useMsal } from '@azure/msal-react';
import useSWRMutation from 'swr/mutation';
import { useTranslation } from 'react-i18next';

interface IFormSendFile {
    files: { file: File; id: string }[];
}

const initialFormData: IFormSendFile = { files: [] };

interface ISendFilesSectionProps {
    handleClose?: () => void;
    updateCall?: (files: string[]) => void;
}
const Sdo = (props: ISendFilesSectionProps) => {
    const { t } = useTranslation();
    const { handleClose, updateCall } = props;
    const { trigger } = useSWRMutation('Sdo/StartControls', httpClient.sendFileRequestOld);
    const refInput = useRef<HTMLInputElement | null>(null);
    const { loggedUser } = useContext(LoggedUserContext);
    const { instance } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            <p>{t('sendFiles.description')}</p>
            <Formik
                initialValues={initialFormData}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                    let files = values.files.map(f => f.file);
                    const now = new Date();

                    const hh = now.getHours();
                    const min = now.getMinutes();
                    const sec = now.getSeconds();
                    now.setDate(now.getDate());
                    let stringDate = ('0' + now.getDate()).slice(-2) + '' + ('0' + (now.getMonth() + 1)).slice(-2) + '' + now.getFullYear();
                    let hoursDate = ('0' + hh).slice(-2) + '' + ('0' + min).slice(-2) + '' + ('0' + sec).slice(-2);
                    const formData = new FormData();
                    const lastName = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.family_name', 'username'));
                    const name = removeUnderscore(get(instance.getActiveAccount(), 'idTokenClaims.given_name', 'username'));
                    files?.forEach((file: any) => formData.append(`files`, file, `${name.charAt(0) + lastName}_${stringDate}_${hoursDate}_${file.name}`));
                    setFieldValue('files', []);
                    setLoading(true);
                    formData.append('userId', `${loggedUser.userId}`);

                    AlertPromise('', trigger(formData), 'Success', 'Error', () => {
                        if (!!handleClose) handleClose();
                        setFieldValue('files', []);
                        if (!!updateCall) updateCall(files?.map((file: any) => `${name.charAt(0) + lastName}_${stringDate}_${hoursDate}_${file.name}`));
                    })
                        .catch(error => {
                            const message = get(error, 'response.data', 'error');
                            console.log(message);
                        })
                        .finally(() => setLoading(false));
                }}>
                {({ handleSubmit: handleSubmit, values, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit} id='form2'>
                            <input
                                form='form2'
                                type='file'
                                title='filesSdo'
                                name='filesSdo'
                                id='filesSdo'
                                className='upload'
                                ref={refInput}
                                multiple
                                accept='.txt'
                                onChange={event => {
                                    let files = get(event, 'target.files', []);
                                    if (files.length === 2) {
                                        let filesArray = Array.from(files);
                                        let first = filesArray[0];
                                        let second = filesArray[1];
                                        let control = checkFiles(first, second);
                                        if (control.length !== 2) {
                                            AlertError(t('sendFiles.filesFormatError'));
                                            event.target.value = '';
                                        } else if (control[0].status === 'success' && control[1].status === 'success') {
                                            event.target.value = '';
                                            setFieldValue(
                                                'files',
                                                filesArray.map(file => ({ file: file, id: crypto.randomUUID() })),
                                            );
                                        } else {
                                            AlertError(t('sendFiles.filesFormatError'));
                                            event.target.value = '';
                                        }
                                    } else {
                                        AlertError(t('sendFiles.errorSelect'));
                                        event.target.value = '';
                                    }
                                }}
                            />
                            <StyledLabel htmlFor='filesSdo'>
                                <CustomSvg iconName='it-file' aria-hidden='true' className='icon icon-md' />
                                <span>{t('btns.select')}</span>
                            </StyledLabel>
                            <div className='upload-file-list'>
                                {!!values.files && (
                                    <FieldArray
                                        name='filesSdo'
                                        render={arrayHelpers => (
                                            <>
                                                {!!values.files && Array.from(values.files).length > 0 && (
                                                    <OverlayTrigger key={'delete'} placement='top' transition={false} i18nIsDynamicList overlay={<Tooltip>{t('generic.tooltipDelete')}</Tooltip>}>
                                                        <div style={{ width: 'fit-content' }}>
                                                            <IconBtn
                                                                style={{ width: 'auto', padding: 0 }}
                                                                onClick={() => {
                                                                    if (refInput.current) {
                                                                        refInput.current.value = '';
                                                                        setFieldValue('files', []);
                                                                    }
                                                                }}>
                                                                <CustomSvg iconName='it-delete' className='icon icon-sm icon-danger' />
                                                            </IconBtn>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                                {!!values.files &&
                                                    Array.from(values.files).map((obj, index) => {
                                                        const { name, size } = obj.file;
                                                        const sizeToShow = (size / (1024 * 1024)).toFixed(2);
                                                        return (
                                                            <StyledFilesList key={obj.id}>
                                                                <StyledLi className={`upload-file ${EFileNameResult.success}`}>
                                                                    <CustomSvg iconName='it-file' aria-hidden='true' className='icon icon-sm' />
                                                                    <p>
                                                                        {name} <b className='upload-file-weight'>{sizeToShow} MB</b>
                                                                    </p>
                                                                </StyledLi>
                                                            </StyledFilesList>
                                                        );
                                                    })}
                                            </>
                                        )}
                                    />
                                )}
                            </div>

                            <StyledUpload type='submit' className='btn btn-primary btn-icon' disabled={values.files.length !== 2 || loading}>
                                <CustomSvg iconName='it-upload' aria-hidden='true' className='icon icon-sm icon-white' />
                                <span>{t('btns.upload')}</span>
                            </StyledUpload>
                        </form>
                    );
                }}
            </Formik>
            <FileInfoBox>
                <FormatContainer>
                    <p>{t('sendFiles.anaFormat')}</p>
                    <div>N_XX_YYYYYY_AAAAMMGG_SDO_ANA.txt</div>
                </FormatContainer>
                <FormatContainer>
                    <p>{t('sendFiles.cliFormat')}</p>
                    <div>N_XX_YYYYYY_AAAAMMGG_SDO_CLI.txt</div>
                </FormatContainer>
                <FormatContainer>
                    <p>(YYYYYY = {t('sendFiles.code')})</p>
                </FormatContainer>
            </FileInfoBox>
        </>
    );
};

export default Sdo;
