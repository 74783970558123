import { useId } from 'react';
import { useTranslation } from 'react-i18next';

interface IErrorText {
    text?: string;
}
const ErrorText = (props: IErrorText) => {
    const { t } = useTranslation();
    const { text } = props;
    const id = useId();
    return (
        <div className='form-feedback just-validate-error-label formik-error' id={id + 'error-input'}>
            {!!text ? text : t('errors.fieldRequired')}
        </div>
    );
};
export default ErrorText;
