import { Btn, IconBtn, UnderlinedBtn } from '../../componets/Buttons/Buttons';
import { Container, FlexCenter, PageTitle, RowInputsWrapper } from '../../style';
import { FieldArray, Formik } from 'formik';
import { normalizeCalendaringFrequency, normalizeRepetitions } from '../../utils';
import { useFlows, useRoles, useSchedulerById } from '../../api/fetch';
import { useNavigate, useParams } from 'react-router-dom';

import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import CustomSelect from '../../componets/Select/CustomSelect';
import CustomSvg from '../../componets/Svg/CustomSvg';
import { EFrequency } from '../../model/enum';
import { ELanguages } from '../../localization';
import GenericError from '../../componets/GenericError/GenericError';
import { ISelectOptions } from '../../model';
import { Input } from 'design-react-kit';
import Loading from '../../componets/Loading/Loading';
import Table from 'react-bootstrap/Table';
import { TextInput } from '../../componets/Input/Input';
import { get } from 'lodash';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DatesContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-top: 2rem;
    & .form-group,
    .select-wrapper {
        min-width: 50%;
    }
`;
const FrequencyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;
const CustomTd = styled.td`
    vertical-align: baseline;
    & .form-group {
        margin-top: 1rem;
        margin-bottom: 0 !important;
    }
`;

interface ICalendaringForm {
    reminderNameIt: string;
    reminderNameDe: string;
    frequency: EFrequency | null;
    dates: ISelectOptions[];
    messages: ISelectOptions[];
    flowId?: string;
}

const initialCalendaringForm: ICalendaringForm = { reminderNameIt: '', reminderNameDe: '', frequency: null, dates: [], messages: [], flowId: '' };
const EditCalendaring = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id, flowId, roleId } = useParams();

    const mesi = Array.from({ length: 12 }, (_, i) => `months.${i}`);
    const trimestri = [t('quarterly.1'), t('quarterly.2'), t('quarterly.3'), t('quarterly.4')];
    const [tab, setTab] = useState<number>(1);
    let isNew = !flowId;
    const { data, isLoading, isError, isValidating } = useSchedulerById({ recurrenceId: id, flowId, roleId });
    const { flows, isLoading: loadingFlows, isError: flowError } = useFlows(!isNew);
    const { roles, isLoading: loadingRoles, isError: roleError } = useRoles(!isNew);

    const { trigger: triggerEdit } = useSWRMutation('Scheduler/Edit', httpClient.putRequest);

    const name = get(data, 'recurrences[0].reminderNameIt', '');
    const nameDe = get(data, 'recurrences[0].reminderNameDe', '');
    const type = get(data, 'recurrences[0].recurrenceType', 0);

    return (
        <Container>
            <BreadCrumb
                paths={[
                    { nameToShow: t('routes.calendaring'), url: '/' + route.calendaring },
                    { nameToShow: t('calendaring.edit'), url: '' },
                ]}
            />
            {(isLoading || isValidating) && <Loading />}
            {isError && <GenericError />}
            {(isNew || !!data) && !(isLoading || isValidating) && (
                <Formik
                    initialValues={
                        isNew
                            ? {
                                  ...initialCalendaringForm,

                                  messages: !!roles ? roles.map(r => ({ id: r.id, messageIt: '', messageDe: '', nameIt: r.nameIt, nameDe: r.nameDe })) : [],
                              }
                            : {
                                  reminderNameIt: name,
                                  reminderNameDe: nameDe,
                                  frequency: type,

                                  dates: normalizeCalendaringFrequency(get(data, 'recurrences', [])),
                                  messages: get(data, 'rolesWithMessages', []).map(r => ({
                                      id: r.roleId,
                                      messageIt: r.messageTextIt,
                                      messageDe: r.messageTextDe,
                                      nameIt: r.roleNameIt,
                                      nameDe: r.roleNameDe,
                                  })),
                              }
                    }
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        const flowIdFromValues = get(values, 'flowId');
                        if ((!flowIdFromValues && !flowId) || !values.frequency) {
                            toast.error(t(!values.frequency ? 'generic.frequency' : 'calendaring.errorFlow'));
                        } else {
                            toast.promise(
                                !isNew
                                    ? triggerEdit({
                                          recurrenceType: values.frequency,
                                          flowId: !!flowId ? parseInt(flowId) : 0,
                                          reminderNameIt: values.reminderNameIt,
                                          roleId: !!roleId ? parseInt(roleId) : 0,
                                          recurrenceId: get(data, 'recurrences[0].recurrenceId', 0),
                                          reminderNameDe: values.reminderNameDe,
                                          messages: values.messages.map(d => ({ roleId: d.id, messageIt: d.messageIt, messageDe: d.messageDe })),
                                          repetitions: normalizeRepetitions(values.dates, values.frequency as EFrequency),
                                      })
                                    : triggerEdit({
                                          recurrenceType: values.frequency,
                                          flowId: parseInt(get(values, 'flowId', '0')),
                                          roleId: !!roleId ? parseInt(roleId) : 0,
                                          recurrenceId: get(data, 'recurrences[0].recurrenceId', 0),
                                          reminderNameIt: values.reminderNameIt,
                                          reminderNameDe: values.reminderNameDe,
                                          messages: values.messages.map(d => ({ roleId: d.id, messageIt: d.messageIt, messageDe: d.messageDe })),
                                          repetitions: normalizeRepetitions(values.dates, values.frequency as EFrequency),
                                      }),
                                {
                                    loading: !isNew ? t('generic.editOingoing') : t('generic.createOingoing'),
                                    success: data => {
                                        setSubmitting(false);
                                        navigate('/' + route.calendaring);
                                        return t('generic.success');
                                    },
                                    error: e => {
                                        setSubmitting(false);
                                        const text = get(e, 'response.data', '');
                                        if (!!text && typeof text === 'string' && text.includes('already exists for flow')) {
                                            return t('generic.errorCalendaring');
                                        }
                                        return t('generic.error');
                                    },
                                },
                            );
                        }
                    }}>
                    {({ handleSubmit, setFieldValue, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                {isNew ? (
                                    <div className='d-flex mt-4 mb-4 justify-content-between align-middle'>
                                        {!!flows && flows.length > 0 && (
                                            <CustomSelect
                                                required
                                                name='flowId'
                                                firstOption={t('calendaring.selectFlows')}
                                                parentOnChange={(value: string) => {
                                                    setFieldValue('flowId', value);
                                                }}
                                                options={flows.map(f => ({ value: f.id, label: i18n.language === ELanguages.it ? f.nameIt : f.nameDe, id: f.id }))}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <PageTitle className='mb-4'>{i18n.language === ELanguages.it ? data?.flowNameIt : data?.flowNameDe}</PageTitle>
                                )}
                                <div className='d-flex mb-4 justify-content-between align-middle'>
                                    <FlexCenter className='d-flex mb-4 '>
                                        <UnderlinedBtn text={t('calendaring.durationTab')} className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)} />
                                        <UnderlinedBtn text={t('calendaring.msgTab')} className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)} />
                                    </FlexCenter>
                                    <Btn style={{ display: 'flex', alignItems: 'center', height: '2.5rem' }} type='submit' text={t('btns.save')} />
                                </div>
                                {tab === 1 && (
                                    <>
                                        <RowInputsWrapper className='mb-2'>
                                            <TextInput name='reminderNameIt' label={t('calendaring.actionNameIt')} required />
                                            <TextInput name='reminderNameDe' label={t('calendaring.actionNameDe')} required />
                                        </RowInputsWrapper>
                                        <RowInputsWrapper className='mb-4'>
                                            <CustomSelect
                                                required
                                                name='frequency'
                                                firstOption={t('calendaring.frequency')}
                                                parentOnChange={(value: string) => {
                                                    setFieldValue('frequency', parseInt(value));
                                                    switch (parseInt(value)) {
                                                        case EFrequency.yearly:
                                                            setFieldValue(
                                                                'dates',
                                                                ['calendaring.selectDate'].map((m, index) => ({ value: null, label: m, id: crypto.randomUUID() })),
                                                            );
                                                            break;
                                                        case EFrequency.monthly:
                                                            setFieldValue(
                                                                'dates',
                                                                mesi.map((m, index) => ({ value: null, label: m, id: crypto.randomUUID(), month: index + 1 })),
                                                            );
                                                            break;
                                                        case EFrequency.weekly:
                                                            setFieldValue(
                                                                'dates',
                                                                ['calendaring.selectDay'].map((m, index) => ({ value: null, label: m, id: crypto.randomUUID() })),
                                                            );
                                                            break;
                                                        case EFrequency.quarterly:
                                                            setFieldValue(
                                                                'dates',
                                                                trimestri.map((m, index) => ({ value: null, label: m, id: crypto.randomUUID() })),
                                                            );
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                options={[
                                                    { value: EFrequency.weekly, label: t('calendaring.weekly'), id: EFrequency.weekly },
                                                    { value: EFrequency.monthly, label: t('calendaring.monthly'), id: EFrequency.monthly },
                                                    { value: EFrequency.quarterly, label: t('calendaring.quarterly'), id: EFrequency.quarterly },
                                                    { value: EFrequency.yearly, label: t('calendaring.annual'), id: EFrequency.yearly },
                                                ]}
                                            />
                                            {values.frequency === EFrequency.monthly && (
                                                <Input
                                                    id='example-steps'
                                                    type='number'
                                                    label={t('calendaring.duration')}
                                                    name='duration'
                                                    min={1}
                                                    max={31}
                                                    onChange={event => {
                                                        setFieldValue('duration', event.target.value);
                                                        if (values.dates.length >= 1) {
                                                            setFieldValue(
                                                                'dates',
                                                                values.dates.map(data => ({ ...data, value: event.target.value, label: data.label, id: data.id })),
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </RowInputsWrapper>
                                        <FrequencyContainer>
                                            <FieldArray
                                                name='dates'
                                                render={arrayHelpers => (
                                                    <>
                                                        {values.dates && values.dates.length > 0 && (
                                                            <>
                                                                {values.dates.length === 1 && values.frequency === EFrequency.yearly && (
                                                                    <DatesContainer key={values.dates[0].id}>
                                                                        <Input
                                                                            type='date'
                                                                            label={t(`${values.dates[0].label}`)}
                                                                            defaultValue={get(values, `dates.[0].value`)}
                                                                            name={`dates[0]}`}
                                                                            className='active'
                                                                            onChange={e => setFieldValue(`dates.0.value`, e.target.value)}
                                                                        />
                                                                    </DatesContainer>
                                                                )}
                                                                {values.dates.length === 1 && values.frequency === EFrequency.weekly && (
                                                                    <DatesContainer key={values.dates[0].id}>
                                                                        <CustomSelect
                                                                            name='dates[0]'
                                                                            firstOption={t('calendaring.frequency')}
                                                                            parentOnChange={(value: string) => {
                                                                                setFieldValue(`dates.0.value`, value);
                                                                            }}
                                                                            options={[
                                                                                { value: 1, label: t('day.1'), id: 1 },
                                                                                { value: 2, label: t('day.2'), id: 2 },
                                                                                { value: 3, label: t('day.3'), id: 3 },
                                                                                { value: 4, label: t('day.4'), id: 4 },
                                                                                { value: 5, label: t('day.5'), id: 5 },
                                                                            ]}
                                                                        />
                                                                    </DatesContainer>
                                                                )}

                                                                {values.dates.length >= 1 &&
                                                                    values.frequency === EFrequency.monthly &&
                                                                    values.dates.map((date, index) => (
                                                                        <DatesContainer key={date.id}>
                                                                            <Input
                                                                                id={date.id}
                                                                                type='number'
                                                                                label={t(`${date.label}`)}
                                                                                value={date.value || ''}
                                                                                name={`dates.${index}`}
                                                                                min={1}
                                                                                max={31}
                                                                                onChange={e => setFieldValue(`dates.${index}.value`, e.target.value)}
                                                                            />
                                                                            <IconBtn
                                                                                withOutline
                                                                                type='button'
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index);
                                                                                }}>
                                                                                <CustomSvg iconName='it-minus' className='icon icon-primary' />
                                                                            </IconBtn>
                                                                        </DatesContainer>
                                                                    ))}
                                                                {values.dates.length >= 1 &&
                                                                    values.frequency === EFrequency.quarterly &&
                                                                    values.dates.map((date, index) => {
                                                                        return (
                                                                            <DatesContainer key={date.id}>
                                                                                <Input
                                                                                    type='date'
                                                                                    id={date.id}
                                                                                    label={trimestri[index]}
                                                                                    value={get(values, `dates.${index}.value`, '') || ''}
                                                                                    name={`dates.${index}`}
                                                                                    className='active'
                                                                                    onChange={e => {
                                                                                        const updatedDate = e.target.value;
                                                                                        if (index === 0) {
                                                                                            let currentDate = new Date(updatedDate);
                                                                                            const newDates = values.dates.map((date, index) => {
                                                                                                if (index === 0) {
                                                                                                    return { ...date, value: updatedDate };
                                                                                                } else {
                                                                                                    currentDate.setMonth(currentDate.getMonth() + 3);
                                                                                                    const newDateValue = currentDate.toISOString().split('T')[0];
                                                                                                    return { ...date, value: newDateValue };
                                                                                                }
                                                                                            });
                                                                                            setFieldValue('dates', newDates);
                                                                                        }
                                                                                        setFieldValue(`dates.${index}.value`, updatedDate);
                                                                                    }}
                                                                                />
                                                                                <IconBtn withOutline type='button' onClick={() => arrayHelpers.remove(index)}>
                                                                                    <CustomSvg iconName='it-minus' className='icon icon-primary' />
                                                                                </IconBtn>
                                                                            </DatesContainer>
                                                                        );
                                                                    })}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </FrequencyContainer>
                                    </>
                                )}
                                {tab === 2 && (
                                    <Table size='xl'>
                                        <FieldArray
                                            name='msgs'
                                            render={arrayHelpers => (
                                                <>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('calendaring.role')}</th>
                                                            <th>{t('calendaring.msgIt')}</th>
                                                            <th>{t('calendaring.msgDe')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.messages &&
                                                            values.messages.length > 0 &&
                                                            values.messages.map((msg, index) => (
                                                                <tr key={msg.id}>
                                                                    <CustomTd className='align-center'>{i18n.language === ELanguages.it ? msg.nameIt : msg.nameDe}</CustomTd>
                                                                    <CustomTd className='align-center'>
                                                                        <TextInput name={`messages.${index}.messageIt`} label={t('calendaring.msgIt')} />
                                                                    </CustomTd>
                                                                    <CustomTd className='align-center'>
                                                                        <TextInput name={`messages.${index}.messageDe`} label={t('calendaring.msgDe')} />
                                                                    </CustomTd>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </>
                                            )}
                                        />
                                    </Table>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            )}
        </Container>
    );
};

export default EditCalendaring;
