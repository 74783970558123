import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Drawer = styled.div`
    height: calc(100vh - 168px);
    display: flex;
    flex-direction: column;
    width: 24rem;
    background-color: #f5f5f5;
    gap: 0.875rem;
    overflow-y: auto;
    @media (max-width: 993px) {
        height: calc(100vh - 134px);
    }
    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

export const StyledAccordion = styled(Accordion)`
    border: none;
    & .accordion-button {
        padding: 1.5rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        background-color: #f5f5f5;
        border-top: 0;
        color: black !important;
    }
    .accordion-button::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='hsl(210deg, 1%, 40%)' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E");
    }
    .accordion-button:hover {
        background: none;
        text-decoration: none;
    }
    .accordion-body {
        background-color: #f5f5f5;
        display: flex;
        padding: 0;
        flex-direction: column;
    }
`;
export const LinksContainer = styled.div<{ $forMobile?: boolean }>`
    display: flex;
    width: 100%;
    height: 3.125rem;
    border: none;
    background-color: ${props => (props.$forMobile ? '#fff' : '#f5f5f5')};
    align-items: center;
    &:hover {
        > button {
            font-weight: 600;
            background-color: #fff;
        }
        > div {
            background-color: #17324d;
        }
    }
`;

export const DrawerBtnLink = styled.button<{ $active?: boolean; $forMobile?: boolean }>`
    display: flex;
    width: 100%;
    height: 3.125rem;
    border: none;
    background-color: ${props => (props.$forMobile ? '#fff' : props.$active ? '#fff' : '#f5f5f5')};
    font-weight: ${props => (props.$active ? 600 : 'normal')};
    padding-left: 1.75rem;
    align-items: center;
    font-size: 0.875rem;
`;

export const ExternalLink = styled(Link)`
    display: flex;
    width: 100%;
    height: 3.125rem;
    border: none;
    background-color: '#f5f5f5';
    color: black;
    font-weight: normal;
    text-decoration: none;
    padding-left: 1.75rem;
    align-items: center;
    font-size: 0.875rem;
`;
export const Line = styled.div<{ $active?: boolean }>`
    width: 0.25rem;
    height: 100%;
    background-color: ${props => (props.$active ? '#17324d' : '#f5f5f5')};
`;

export const StyledAccordionHeader = styled(Accordion.Header)``;
export const ButtonLink = styled.button<{ $active?: boolean; $forMobile?: boolean }>`
    padding: 1.5rem;
    display: flex;
    width: 100%;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    border-top: 0;
    color: ${props => (props.$forMobile ? '#06c' : 'black !important')};
    background-color: ${props => (props.$forMobile ? '#fff' : props.$active ? '#fff' : '#f5f5f5')};
    &:hover {
        background-color: #fff;
    }
`;
