import { ChangeEvent, useId } from 'react';

import ErrorText from '../ErrorText/ErrorText';
import { Input } from 'design-react-kit';
import { get } from 'lodash';
import styled from 'styled-components';
import { t } from 'i18next';
import { useFormikContext } from 'formik';

declare type InputType =
    | 'text'
    | 'email'
    | 'select'
    | 'file'
    | 'radio'
    | 'checkbox'
    | 'textarea'
    | 'button'
    | 'reset'
    | 'submit'
    | 'date'
    | 'datetime-local'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'range'
    | 'search'
    | 'tel'
    | 'url'
    | 'week'
    | 'password'
    | 'datetime'
    | 'time'
    | 'color'
    | 'adaptive'
    | 'currency'
    | 'percentage';

interface ITextInputProps {
    name: string;
    label: string;
    readOnly?: boolean;
    required?: boolean;
    type?: InputType;
}
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TextInput = (props: ITextInputProps) => {
    const { values, setFieldValue, errors } = useFormikContext();
    const { name, label, readOnly, required, type } = props;
    const id = useId();
    const value = get(values, `${name}`);
    const error = get(errors, `[${name}]`);

    let className = !!value ? 'active ' : '';
    return (
        <InputWrapper>
            <Input
                name={name}
                label={label}
                required={required}
                onInvalid={(event: React.InvalidEvent<HTMLInputElement>) => {
                    event.target.setCustomValidity(t('generic.invalidInput'));
                }}
                onInput={(event: React.FormEvent<HTMLInputElement>) => {
                    event.currentTarget.setCustomValidity('');
                }}
                type={type || 'text'}
                readOnly={readOnly}
                id={id}
                value={value}
                className={!!error ? 'is-invalid ' + className : className}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)}
            />
            {!!error && <ErrorText text={error} />}
        </InputWrapper>
    );
};
