import { FileInfoBox, FlexCenter, FormatContainer } from '../../style';

import { EFlowType } from '../../model/enum';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';

const WideFormatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 17rem;
    justify-content: center;
    & p {
        color: #003366;
        font-size: 1rem;
        margin-bottom: 0;
    }
    & div {
        font-weight: 600;
        color: #003366;
        font-size: 1rem;
    }
`;

const fileMappings = {
    [EFlowType.Fim.toUpperCase()]: ['AA_0410xx_yyyymmdd_FIM_ANA.txt', 'AA_0410xx_yyyymmdd_FIM_CLI.txt'],
    [EFlowType.Cedp.toUpperCase()]: ['AA_0410xx_yyyymmdd_CAPM01A.txt', 'AA_0410xx_yyyymmdd_CAPM01B.txt'],
    [EFlowType.Sderia.toUpperCase()]: ['AA_0410xx_yyyymmdd_SDERIA_ANA.txt', 'AA_0410xx_yyyymmdd_SDERIA_CLI.txt'],
    [EFlowType.Hosp.toUpperCase()]: ['AA_0410xx_yyyymmdd_HOSP_ANA.txt', 'AA_0410xx_yyyymmdd_HOSP_CLI.txt'],
    [EFlowType.Labo.toUpperCase()]: ['AA_0410xx_yyyymmdd_LABO_ANA.txt', 'AA_0410xx_yyyymmdd_LABO_CLI.txt'],
    [EFlowType.Spa.toUpperCase()]: ['AA_0410xx_yyyymmdd_SPE_ANA.txt', 'AA_0410xx_yyyymmdd_SPE_CLI.txt'],
    [EFlowType.Farmosp.toUpperCase()]: ['AA_0410xx_yyyymmdd_FARMOSP.txt'],
    [EFlowType.Farmter.toUpperCase()]: ['AA_041xxx_yyyymm_FARMTER_T4.txt', 'AA_041xxx_yyyymm_FARMTER_T5.txt', 'AA_041xxx_yyyymm_FARMTER_T6.txt'],
    [EFlowType.Sps.toUpperCase()]: ['AA_0410xx_yyyymmdd_SPS_ANA.txt', 'AA_0410xx_yyyymmdd_SPS_CLI.txt'],
    [EFlowType.X.toUpperCase()]: ['AA_0410xx_yyyymmdd_118_A.txt', 'AA_0410xx_yyyymmdd_118_B.txt', 'AA_0410xx_yyyymmdd_118_C.txt'],
    [EFlowType.Rpa.toUpperCase()]: ['AA_0410xx_yyyymmdd_RPA_CLI.txt'],
    [EFlowType.Rpg.toUpperCase()]: ['AA_0410xx_yyyymmdd_RPG_CLI.txt'],
    [EFlowType.Diab.toUpperCase()]: ['AA_0410xx_yyyymmdd_DIAB_ANA.txt', 'AA_0410xx_yyyymmdd_DIAB_CLI.txt'],
    [EFlowType.Spi.toUpperCase()]: ['AA_0410xx_yyyymmdd_SPI_ANA.txt', 'AA_0410xx_yyyymmdd_SPI_CLI.txt'],
    [EFlowType.Sind.toUpperCase()]: ['AA_0410xx_yyyymmdd_FARMDIR01C.txt'],
};

const InfoBoxFactory = ({ type }: { type: EFlowType }) => {
    const { t } = useTranslation();

    switch (type.toUpperCase()) {
        case EFlowType.Siar.toUpperCase(): {
            return (
                <FileInfoBox>
                    <FormatContainer>
                        <p>{t('sendFiles.siarFormat')}</p>
                        <div>N_XX_YYYYYY_AAAAMMGG_SIAR_1.txt</div>
                        <FlexCenter>
                            <div dangerouslySetInnerHTML={{ __html: `&vellip;` }}></div>
                        </FlexCenter>
                        <div>N_XX_YYYYYY_AAAAMMGG_SIAR_6.txt</div>
                    </FormatContainer>
                    <FormatContainer>
                        <p>(YYYYYY = {t('sendFiles.code')})</p>
                    </FormatContainer>
                </FileInfoBox>
            );
        }
        case EFlowType.Dm.toUpperCase(): {
            return (
                <FileInfoBox>
                    <FormatContainer>
                        <p>{t('sendFiles.siarFormat')}</p>
                        <div>0410xx_yyyymm_DM_CNR.txt</div>
                        <p>{t('generic.or')}</p>
                        <div>0410xx_yyyymm_DM_CNS.txt</div>
                    </FormatContainer>
                </FileInfoBox>
            );
        }
        case EFlowType.Farm.toUpperCase(): {
            return (
                <FileInfoBox>
                    <WideFormatContainer>
                        <p>{t('sendFiles.siarFormat')}</p>
                        <div>AA_0410xx_yyyymmdd_FARMDIR01A.txt</div>
                        <div>AA_0410xx_yyyymmdd_FARMDIR01B.txt</div>
                        <div>AA_0410xx_yyyymmdd_FARMDIR01C.txt</div>
                        <p>{t('generic.or')}</p>
                        <div>AA_0410xx_yyyymmdd_FARMCON01A.txt</div>
                        <div>AA_0410xx_yyyymmdd_FARMCON01B.txt</div>
                        <div>AA_0410xx_yyyymmdd_FARMCON01C.txt</div>
                        <p>{t('generic.or')}</p>
                        <div>AA_0410xx_yyyymmdd_FARMCONTAR.txt</div>
                    </WideFormatContainer>
                </FileInfoBox>
            );
        }
        default: {
            if (fileMappings[type.toUpperCase()]) {
                return (
                    <FileInfoBox>
                        <FormatContainer>
                            <p>{t('sendFiles.siarFormat')}</p>
                            {fileMappings[type.toUpperCase()].map(exmp => (
                                <div key={exmp}>{exmp}</div>
                            ))}
                        </FormatContainer>
                    </FileInfoBox>
                );
            }
            return <></>;
        }
    }
};

export default InfoBoxFactory;
