import styled from 'styled-components';

export const IconButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #0066cc;
    background-color: #fff;
    border-radius: 0.25rem;
`;

export const IconButtonNoStyle = styled.button<{ $border?: boolean; $borderProps?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
    border: ${props => (props.$border ? (props.$borderProps ? props.$borderProps : '1px solid #0066cc') : 'none')};
    background-color: transparent;
    &:hover {
        opacity: 0.4;
    }
    & svg {
        width: 2rem;
        height: 2rem;
    }
`;
export const IconButtonUnderlined = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 2.5rem;
    border: none;
    color: #06c;
    background-color: #fff;
    margin-bottom: 1rem;
    &.active {
        border-bottom: 1px solid #06c;
    }
    &:hover {
        opacity: 0.8;
    }
`;
export const IconButtonUnderlinedLink = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #06c;
    background-color: #fff;
    border-bottom: 1px solid #06c;

    &:hover {
        opacity: 0.8;
    }
`;
