import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import NewAction from './NewAction';
import { useActionById } from '../../api/fetch';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IEditActionProps {}

const EditAction = (props: IEditActionProps) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { action, isLoading, isError } = useActionById(id);

    return (
        <Container>
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!!action && <NewAction actionToEdit={action} />}
        </Container>
    );
};

export default EditAction;
