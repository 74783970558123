import { ButtonLink, Drawer, DrawerBtnLink, ExternalLink, Line, LinksContainer, StyledAccordion, StyledAccordionHeader } from '../style/layout/drawer';
import { useContext, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import { LoggedUserContext } from '../App';
import { get } from 'lodash';
import { getLinkByRole } from '../route';
import { isSiag } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ILeftDrawerProps {
    forMobile?: boolean;
    setExpanded?: any;
}

const LeftDrawer = (props: ILeftDrawerProps) => {
    const { forMobile, setExpanded } = props;
    const navigate = useNavigate();
    const [active, setActive] = useState<string>('');
    const { loggedUser } = useContext(LoggedUserContext);
    const { t, i18n } = useTranslation();
    const linksByRole = getLinkByRole(i18n.language, get(loggedUser, `allowedFlows`, []), isSiag(loggedUser.role));

    return (
        <DrawerForDesktop forMobile={!!forMobile}>
            {linksByRole.map((data, index) => (
                <Accordion.Item eventKey={`${index}`} key={data.mainName + index}>
                    {!!data.children && data.children.length > 0 ? (
                        <>
                            <StyledAccordionHeader>{t(data.mainName)}</StyledAccordionHeader>
                            <Accordion.Body>
                                {data.children.map(c => (
                                    <LinksContainer $forMobile={!!forMobile} key={c.key}>
                                        {get(c, 'isExternal', false) ? (
                                            <ExternalLink to={c.path} target='_blank'>
                                                {t(c.name)}
                                            </ExternalLink>
                                        ) : (
                                            <>
                                                <Line $active={active === c.key} />
                                                <DrawerBtnLink
                                                    $forMobile={!!forMobile}
                                                    $active={active === c.key}
                                                    onClick={() => {
                                                        setActive(c.key);
                                                        navigate(c.path);
                                                        if (!!setExpanded) setExpanded(false);
                                                    }}>
                                                    {t(c.name)}
                                                </DrawerBtnLink>
                                            </>
                                        )}
                                    </LinksContainer>
                                ))}
                            </Accordion.Body>
                        </>
                    ) : (
                        <ButtonLink
                            $forMobile={!!forMobile}
                            $active={active === data.path}
                            onClick={() => {
                                if (data.path) {
                                    setActive(data.path);
                                    navigate(data.path);
                                    if (!!setExpanded) setExpanded(false);
                                }
                            }}>
                            {t(data.mainName)}
                        </ButtonLink>
                    )}
                </Accordion.Item>
            ))}
        </DrawerForDesktop>
    );
};

const DrawerForDesktop = ({ children, forMobile }: { children: any; forMobile: boolean }) => (
    <>
        {forMobile ? (
            <Accordion>{children}</Accordion>
        ) : (
            <Drawer>
                <div className='link-list-wrapper'>
                    <ul className='link-list d-flex flex-column'>
                        <StyledAccordion defaultActiveKey='0' flush>
                            {children}
                        </StyledAccordion>
                    </ul>
                </div>
            </Drawer>
        )}
    </>
);

export default LeftDrawer;
