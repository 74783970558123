import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { CurrentYearDetail, IOverviewBE, LastYearDetail, YearsDifference } from '../../model';
import { ExlBtnWrapper, OverlayChild } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { EFlowType } from '../../model/enum';
import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { get } from 'lodash';
import { t } from 'i18next';

interface IExportToExcelRecovery {
    overviewTableRows: IOverviewBE[];
    flow: EFlowType;
}

const ExportExcelRecovery = (props: IExportToExcelRecovery) => {
    const { overviewTableRows, flow } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = flow ? `${flow.toUpperCase() + '-Overview'}` : 'excel-detail';

    const exportToExcel = () => {
        const customHeadings = overviewTableRows.map(row => {
            const currentYear: CurrentYearDetail = get(row, 'currentYearRow', {} as CurrentYearDetail);
            const lastYear: LastYearDetail = get(row, 'lastYearRow', {} as LastYearDetail);
            const yearsDifferences: YearsDifference = get(row, 'yearsDifferences', {} as YearsDifference);

            const overviewData =
                flow !== EFlowType.Sdo && flow !== EFlowType.Sdor
                    ? [
                          {
                              [t('sdoOverview.clmYear')]: currentYear.year,
                              [t('sdoOverview.clmStructureCode')]: currentYear.structureCode,
                              [t('sdoOverview.jan')]: currentYear.jan,
                              [t('sdoOverview.feb')]: currentYear.feb,
                              [t('sdoOverview.mar')]: currentYear.mar,
                              [t('sdoOverview.apr')]: currentYear.apr,
                              [t('sdoOverview.may')]: currentYear.may,
                              [t('sdoOverview.jun')]: currentYear.jun,
                              [t('sdoOverview.jul')]: currentYear.jul,
                              [t('sdoOverview.aug')]: currentYear.aug,
                              [t('sdoOverview.sep')]: currentYear.sep,
                              [t('sdoOverview.oct')]: currentYear.oct,
                              [t('sdoOverview.nov')]: currentYear.nov,
                              [t('sdoOverview.dec')]: currentYear.dec,
                              [t('sdoOverview.clmTotal')]: currentYear.total,
                          },
                          {
                              [t('sdoOverview.clmYear')]: lastYear.year,
                              [t('sdoOverview.clmStructureCode')]: lastYear.structureCode,
                              [t('sdoOverview.jan')]: lastYear.jan,
                              [t('sdoOverview.feb')]: lastYear.feb,
                              [t('sdoOverview.mar')]: lastYear.mar,
                              [t('sdoOverview.apr')]: lastYear.apr,
                              [t('sdoOverview.may')]: lastYear.may,
                              [t('sdoOverview.jun')]: lastYear.jun,
                              [t('sdoOverview.jul')]: lastYear.jul,
                              [t('sdoOverview.aug')]: lastYear.aug,
                              [t('sdoOverview.sep')]: lastYear.sep,
                              [t('sdoOverview.oct')]: lastYear.oct,
                              [t('sdoOverview.nov')]: lastYear.nov,
                              [t('sdoOverview.dec')]: lastYear.dec,
                              [t('sdoOverview.clmTotal')]: lastYear.total,
                          },
                          {
                              '': yearsDifferences.year,
                              [t('sdoOverview.clmStructureCode')]: yearsDifferences.structureCode,
                              [t('sdoOverview.jan')]: yearsDifferences.jan,
                              [t('sdoOverview.feb')]: yearsDifferences.feb,
                              [t('sdoOverview.mar')]: yearsDifferences.mar,
                              [t('sdoOverview.apr')]: yearsDifferences.apr,
                              [t('sdoOverview.may')]: yearsDifferences.may,
                              [t('sdoOverview.jun')]: yearsDifferences.jun,
                              [t('sdoOverview.jul')]: yearsDifferences.jul,
                              [t('sdoOverview.aug')]: yearsDifferences.aug,
                              [t('sdoOverview.sep')]: yearsDifferences.sep,
                              [t('sdoOverview.oct')]: yearsDifferences.oct,
                              [t('sdoOverview.nov')]: yearsDifferences.nov,
                              [t('sdoOverview.dec')]: yearsDifferences.dec,
                              [t('sdoOverview.clmTotal')]: yearsDifferences.total,
                          },
                      ]
                    : [
                          {
                              [t('sdoOverview.clmYear')]: currentYear.year,
                              [t('sdoOverview.clmStructureCode')]: currentYear.structureCode,
                              [t('sdoOverview.clmNameStructure')]: currentYear.structureNameIt,
                              [t('sdoOverview.jan')]: currentYear.jan,
                              [t('sdoOverview.feb')]: currentYear.feb,
                              [t('sdoOverview.mar')]: currentYear.mar,
                              [t('sdoOverview.apr')]: currentYear.apr,
                              [t('sdoOverview.may')]: currentYear.may,
                              [t('sdoOverview.jun')]: currentYear.jun,
                              [t('sdoOverview.jul')]: currentYear.jul,
                              [t('sdoOverview.aug')]: currentYear.aug,
                              [t('sdoOverview.sep')]: currentYear.sep,
                              [t('sdoOverview.oct')]: currentYear.oct,
                              [t('sdoOverview.nov')]: currentYear.nov,
                              [t('sdoOverview.dec')]: currentYear.dec,
                              [t('sdoOverview.clmTotal')]: currentYear.total,
                          },
                          {
                              [t('sdoOverview.clmYear')]: lastYear.year,
                              [t('sdoOverview.clmStructureCode')]: lastYear.structureCode,
                              [t('sdoOverview.clmNameStructure')]: lastYear.structureNameIt,
                              [t('sdoOverview.jan')]: lastYear.jan,
                              [t('sdoOverview.feb')]: lastYear.feb,
                              [t('sdoOverview.mar')]: lastYear.mar,
                              [t('sdoOverview.apr')]: lastYear.apr,
                              [t('sdoOverview.may')]: lastYear.may,
                              [t('sdoOverview.jun')]: lastYear.jun,
                              [t('sdoOverview.jul')]: lastYear.jul,
                              [t('sdoOverview.aug')]: lastYear.aug,
                              [t('sdoOverview.sep')]: lastYear.sep,
                              [t('sdoOverview.oct')]: lastYear.oct,
                              [t('sdoOverview.nov')]: lastYear.nov,
                              [t('sdoOverview.dec')]: lastYear.dec,
                              [t('sdoOverview.clmTotal')]: lastYear.total,
                          },
                          {
                              '': yearsDifferences.year,
                              [t('sdoOverview.clmStructureCode')]: yearsDifferences.structureCode,
                              [t('sdoOverview.clmNameStructure')]: yearsDifferences.structureNameIt,
                              [t('sdoOverview.jan')]: yearsDifferences.jan,
                              [t('sdoOverview.feb')]: yearsDifferences.feb,
                              [t('sdoOverview.mar')]: yearsDifferences.mar,
                              [t('sdoOverview.apr')]: yearsDifferences.apr,
                              [t('sdoOverview.may')]: yearsDifferences.may,
                              [t('sdoOverview.jun')]: yearsDifferences.jun,
                              [t('sdoOverview.jul')]: yearsDifferences.jul,
                              [t('sdoOverview.aug')]: yearsDifferences.aug,
                              [t('sdoOverview.sep')]: yearsDifferences.sep,
                              [t('sdoOverview.oct')]: yearsDifferences.oct,
                              [t('sdoOverview.nov')]: yearsDifferences.nov,
                              [t('sdoOverview.dec')]: yearsDifferences.dec,
                              [t('sdoOverview.clmTotal')]: yearsDifferences.total,
                          },
                      ];

            return overviewData;
        });

        const ws = XLSX.utils.json_to_sheet(customHeadings.flat());
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: flow.toUpperCase() })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};
export default ExportExcelRecovery;
