import ActionTable from '../../componets/CustomTable/ActionTable';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import { route } from '../../route';
import { useRoles } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

interface IRolesProps {}
const Roles = (props: IRolesProps) => {
    const { t } = useTranslation();

    const { roles, isLoading, isError } = useRoles();

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.roles'), url: route.roles }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!!roles && roles.length > 0 && <ActionTable header={t('roles.tableName')} rows={roles} />}
        </Container>
    );
};

export default Roles;
