import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IBreadCrumb {
    paths: { nameToShow: string; url: string }[];
}
const CustomNav = styled.nav`
    margin: 0 !important;
    & ol {
        margin: 0;
        padding: 0;
    }
`;
const BreadCrumb = (props: IBreadCrumb) => {
    const { t } = useTranslation();
    const { paths } = props;
    return (
        <CustomNav className='breadcrumb-container' aria-label='Percorso di navigazione'>
            <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                    <Link to={'/'}>{t('routes.home')}</Link>
                    <span className='separator' aria-hidden='true'>
                        /
                    </span>
                </li>

                {paths.length > 0 &&
                    paths.map((p, index) => {
                        if (paths.length - 1 === index)
                            return (
                                <li className='breadcrumb-item active' aria-current='page' key={p.url + index}>
                                    {p.nameToShow}
                                </li>
                            );
                        else {
                            return (
                                <li className='breadcrumb-item' key={p.url}>
                                    <Link to={p.url}>{p.nameToShow}</Link>
                                    <span className='separator' aria-hidden='true'>
                                        /
                                    </span>
                                </li>
                            );
                        }
                    })}
            </ol>
        </CustomNav>
    );
};

export default BreadCrumb;
