import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { computeTotalsAndDifference, getCurrentMonthAndYear } from '../../utils';

import ExcelSvg from '../Svg/ExcelSvg';
import { IOverviewFim } from '../../model';
import { IconBtn } from '../Buttons/Buttons';
import { t } from 'i18next';

interface IExportToExcelFim {
    overviewData: IOverviewFim[];
}

const ExportExcelFim = (props: IExportToExcelFim) => {
    const { overviewData } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'FIM-Overview';

    const exportToExcel = () => {
        const rows = overviewData.map(row => ({
            [t('fimOverview.district')]: row.area,
            [t('fimOverview.institute')]: row.institute,
            [t('fimOverview.department')]: row.dept,
            [t('fimOverview.sdoHosps')]: row.sdoHosps,
            [t('fimOverview.fimHosps')]: row.fimHosps,
            [t('fimOverview.difference')]: row.difference,
            [`${t('fimOverview.jan')}${getCurrentMonthAndYear().currentYear - 1}`]: row.jan,
            [`${t('fimOverview.feb')}${getCurrentMonthAndYear().currentYear - 1}`]: row.feb,
            [`${t('fimOverview.mar')}${getCurrentMonthAndYear().currentYear - 1}`]: row.mar,
            [`${t('fimOverview.apr')}${getCurrentMonthAndYear().currentYear - 1}`]: row.apr,
            [`${t('fimOverview.may')}${getCurrentMonthAndYear().currentYear - 1}`]: row.may,
            [`${t('fimOverview.jun')}${getCurrentMonthAndYear().currentYear - 1}`]: row.jun,
            [`${t('fimOverview.jul')}${getCurrentMonthAndYear().currentYear - 1}`]: row.jul,
            [`${t('fimOverview.aug')}${getCurrentMonthAndYear().currentYear - 1}`]: row.aug,
            [`${t('fimOverview.sep')}${getCurrentMonthAndYear().currentYear - 1}`]: row.sep,
            [`${t('fimOverview.oct')}${getCurrentMonthAndYear().currentYear - 1}`]: row.oct,
            [`${t('fimOverview.nov')}${getCurrentMonthAndYear().currentYear - 1}`]: row.nov,
            [`${t('fimOverview.dec')}${getCurrentMonthAndYear().currentYear - 1}`]: row.dec,
        }));

        const totalSdoHosps = computeTotalsAndDifference(overviewData).totalSdoHosps;
        const totalFimHosps = computeTotalsAndDifference(overviewData).totalFimHosps;
        const totalDifference = computeTotalsAndDifference(overviewData).totalDifference;

        const finalRows = [
            ...rows,
            {
                [t('fimOverview.district')]: '',
                [t('fimOverview.institute')]: '',
                [t('fimOverview.department')]: '',
            },
            {
                [t('fimOverview.district')]: t('fimOverview.totalSdoHosps'),
                [t('fimOverview.institute')]: t('fimOverview.totalFimHosps'),
                [t('fimOverview.department')]: t('fimOverview.totalDifferences'),
            },
            { [t('fimOverview.district')]: totalSdoHosps, [t('fimOverview.institute')]: totalFimHosps, [t('fimOverview.department')]: totalDifference },
        ];

        const ws = XLSX.utils.json_to_sheet(finalRows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: 'FIM' })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelFim;
