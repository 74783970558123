import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { ExlBtnWrapper, OverlayChild } from '../../style';
import { IOverviewSps, IOverviewSpsPercentage } from '../../model';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ExcelSvg from '../Svg/ExcelSvg';
import { IconBtn } from '../Buttons/Buttons';
import { t } from 'i18next';

interface IExportExcelSpsProps {
    currentYearRecs: IOverviewSps[];
    lastYearRecs: IOverviewSps[];
    percIndexes: IOverviewSpsPercentage[];
}

const ExportExcelSps = (props: IExportExcelSpsProps) => {
    const { currentYearRecs, lastYearRecs, percIndexes } = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let fileName = 'SPS-Overview';

    const exportToExcel = () => {
        const emptyRow = () => {
            return {
                [t('genericOverview.clmYear')]: '',
                [t('generic.istitute')]: '',
                [t('months.0')]: '',
                [t('months.1')]: '',
                [t('months.2')]: '',
                [t('months.3')]: '',
                [t('months.4')]: '',
                [t('months.5')]: '',
                [t('months.6')]: '',
                [t('months.7')]: '',
                [t('months.8')]: '',
                [t('months.9')]: '',
                [t('months.10')]: '',
                [t('months.11')]: '',
                [t('generic.total')]: '',
            };
        };

        const titleRow = () => {
            return {
                [t('genericOverview.clmYear')]: t('genericOverview.clmYear'),
                [t('generic.istitute')]: t('generic.istitute'),
                [t('months.0')]: t('months.0'),
                [t('months.1')]: t('months.1'),
                [t('months.2')]: t('months.2'),
                [t('months.3')]: t('months.3'),
                [t('months.4')]: t('months.4'),
                [t('months.5')]: t('months.5'),
                [t('months.6')]: t('months.6'),
                [t('months.7')]: t('months.7'),
                [t('months.8')]: t('months.8'),
                [t('months.9')]: t('months.9'),
                [t('months.10')]: t('months.10'),
                [t('months.11')]: t('months.11'),
                [t('generic.total')]: t('generic.total'),
            };
        };

        const finalRows = [
            ...currentYearRecs.map(row => ({
                [t('genericOverview.clmYear')]: row.year,
                [t('generic.istitute')]: row.institute,
                [t('months.0')]: row.jan,
                [t('months.1')]: row.feb,
                [t('months.2')]: row.mar,
                [t('months.3')]: row.apr,
                [t('months.4')]: row.may,
                [t('months.5')]: row.jun,
                [t('months.6')]: row.jul,
                [t('months.7')]: row.aug,
                [t('months.8')]: row.sep,
                [t('months.9')]: row.oct,
                [t('months.10')]: row.nov,
                [t('months.11')]: row.dec,
                [t('generic.total')]: row.tot,
            })),
            emptyRow(),
            titleRow(),
            ...lastYearRecs.map(row => ({
                [t('genericOverview.clmYear')]: row.year,
                [t('generic.istitute')]: row.institute,
                [t('months.0')]: row.jan,
                [t('months.1')]: row.feb,
                [t('months.2')]: row.mar,
                [t('months.3')]: row.apr,
                [t('months.4')]: row.may,
                [t('months.5')]: row.jun,
                [t('months.6')]: row.jul,
                [t('months.7')]: row.aug,
                [t('months.8')]: row.sep,
                [t('months.9')]: row.oct,
                [t('months.10')]: row.nov,
                [t('months.11')]: row.dec,
                [t('generic.total')]: row.tot,
            })),
            emptyRow(),
            titleRow(),
            ...percIndexes.map(row => ({
                [t('genericOverview.clmYear')]: row.year,
                [t('generic.istitute')]: row.institute,
                [t('months.0')]: row.jan,
                [t('months.1')]: row.feb,
                [t('months.2')]: row.mar,
                [t('months.3')]: row.apr,
                [t('months.4')]: row.may,
                [t('months.5')]: row.jun,
                [t('months.6')]: row.jul,
                [t('months.7')]: row.aug,
                [t('months.8')]: row.sep,
                [t('months.9')]: row.oct,
                [t('months.10')]: row.nov,
                [t('months.11')]: row.dec,
                [t('generic.total')]: row.tot,
            })),
        ];

        const ws = XLSX.utils.json_to_sheet(finalRows);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <ExlBtnWrapper>
            <OverlayTrigger transition={false} i18nIsDynamicList placement='top' overlay={<Tooltip>{t('generic.downloadExcel', { flow: 'SPS' })}</Tooltip>}>
                <OverlayChild>
                    <IconBtn withOutline onClick={exportToExcel}>
                        <ExcelSvg />
                    </IconBtn>
                </OverlayChild>
            </OverlayTrigger>
        </ExlBtnWrapper>
    );
};

export default ExportExcelSps;
