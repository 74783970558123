import { IActionsBE, ICalendaringBe, IDetailFlowBE, IEditStructureBE, IFlowsBE, IGetRecurrenceBeById, IGetUsersBE, ILoggedUserBE, IProgressData, IRoleBE, IRoleByIdBE, IStructureBE } from '../model';
import {
    deserializeActions,
    deserializeFlows,
    deserializeGetAllShippings,
    deserializeGetAllShippingsGeneric,
    deserializeGetAllShippingsSdor,
    deserializeRoles,
    deserializeStructures,
    deserializeUse118Overview,
    deserializeUseDiabOverview,
    deserializeUseDmOverview,
    deserializeUseFarmOverview,
    deserializeUseFarmospOverview,
    deserializeUseFimOverview,
    deserializeUseLaboOverview,
    deserializeUsePatologyOverview,
    deserializeUseRecoveryOverview,
    deserializeUseSpaOverview,
    deserializeUseSpsOverview,
} from '../utils/deserializer';

import { EFlowType } from '../model/enum';
import httpClient from './httpClient';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

export function useUsers() {
    const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(`User`, httpClient.fetcher, { revalidateOnMount: true });
    return {
        isValidating,
        mutate,
        users: data as IGetUsersBE[],
        isLoading,
        isError: error,
    };
}

export function checkUser(cf?: string) {
    const { data, error } = useSWRImmutable(!!cf ? `User/check?fiscalCode=${cf}` : null, httpClient.fetcher);
    return {
        user: data as ILoggedUserBE,
        isError: error,
    };
}

export function useUser(id?: string | number) {
    const { data, error, isLoading, isValidating } = useSWRImmutable(`User/Get?id=${id}`, httpClient.fetcher, { revalidateOnMount: true });
    return {
        user: data as IGetUsersBE,
        isLoading,
        isError: error,
        isValidating,
    };
}

export function useAction() {
    const { data, error, isLoading } = useSWR<IActionsBE[]>('Action', httpClient.fetcher);

    return {
        actions: deserializeActions(data),
        isLoading,
        isError: error,
    };
}
export function useActionById(id?: string | number) {
    const { data, error, isLoading } = useSWR<IActionsBE>(!!id ? `Action/Get?id=${id}` : null, httpClient.fetcher);

    return {
        action: data,
        isLoading,
        isError: error,
    };
}
export function useRoles(forCalendaring?: boolean) {
    const { data, error, isLoading } = useSWR<IRoleBE[]>(!forCalendaring ? 'Role' : null, httpClient.fetcher);

    return {
        roles: deserializeRoles(data),
        isLoading,
        isError: error,
    };
}

export function useRoleById(id?: string | number) {
    const { data, error, isLoading, isValidating } = useSWRImmutable(`Role/Get?id=${id}`, httpClient.fetcher, { revalidateOnMount: true });

    return {
        isValidating,
        role: data as IRoleByIdBE,
        isLoading,
        isError: error,
    };
}

export function useFlows(forCalendaring?: boolean) {
    const { data, error, isLoading } = useSWR<IFlowsBE[]>(!forCalendaring ? 'Flow' : null, httpClient.fetcher);

    return {
        flows: deserializeFlows(data),
        isLoading,
        isError: error,
    };
}
export function useFlowsById(id?: string | number) {
    const { data, error, isLoading } = useSWR<IDetailFlowBE>(!!id ? `Flow/Get?id=${id}` : null, httpClient.fetcher);

    return {
        flow: data,
        isLoading,
        isError: error,
    };
}

export function useStructures(id?: number) {
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable<IStructureBE[]>(!!id && id > 0 ? `Structures?userId=${id}` : null, httpClient.fetcher, { revalidateOnMount: true });
    return {
        mutate,
        isValidating,
        structures: deserializeStructures(data),
        isLoading,
        isError: error,
    };
}
export function useStructuresById(id?: string) {
    const { data, error, isLoading, isValidating } = useSWR<IEditStructureBE>(!!id ? `Structures/Get?id=${id}` : null, httpClient.fetcher);

    return {
        structure: data,
        isLoading,
        isError: error,
        isValidating,
    };
}

export function useScheduler(id?: number) {
    const { data, error, isLoading, mutate } = useSWR<ICalendaringBe[]>(!!id && id > 0 ? `Scheduler?userId=${id}` : null, httpClient.fetcher);

    return {
        data: data,
        isLoading,
        isError: error,
        mutate,
    };
}
export function useSchedulerById({ roleId, flowId, recurrenceId }: { roleId?: string; recurrenceId?: string; flowId?: string }) {
    const { data, error, isLoading, isValidating } = useSWRImmutable<IGetRecurrenceBeById>(
        !!roleId && !!flowId && !!recurrenceId ? `Scheduler/Get?roleId=${roleId}&flowId=${flowId}&recurrenceId=${recurrenceId}` : null,
        httpClient.fetcher,
        {
            refreshInterval: 2400000,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: true,
        },
    );

    return {
        data: data,
        isLoading,
        isValidating,
        isError: error,
    };
}

export function useLogFile(shippingId?: number, userId?: number, isSdo?: boolean) {
    const { data, error, isLoading, mutate, isValidating } = useSWR(shippingId && userId ? `${isSdo ? 'Sdo' : 'SdoR'}/GetLog?shippingId=${shippingId}&userId=${userId}` : null, httpClient.fetcher, {
        shouldRetryOnError: false,
        refreshInterval: 30000,
    });

    return {
        log: typeof data === 'string' ? data : undefined,
        isLoading,
        isValidating,
        logError: error,
        mutate,
        progressData: Array.isArray(data) ? (data as IProgressData[]) : undefined,
    };
}
export function getAllShippings(userId?: number) {
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(!!userId && userId > 0 ? `Sdo/GetAllShippings?userId=${userId}` : null, httpClient.fetcher, {
        refreshInterval: 240000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        shippings: deserializeGetAllShippings(data),
        isLoading,
        isError: error,
        mutate,
        isValidating,
    };
}

export function getAllShippingsSdor(userId?: number) {
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(!!userId && userId > 0 ? `SdoR/GetAllShippings?userId=${userId}` : null, httpClient.fetcher, {
        refreshInterval: 240000,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    return {
        shippings: deserializeGetAllShippingsSdor(data),
        isLoading,
        isError: error,
        mutate,
        isValidating,
    };
}

export function getAllShippingsByFlow(flow: string, userId?: number) {
    const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(
        !!userId && userId > 0 ? `api/ExternalManagedFlow/GetAllShippings?userId=${userId}&flowName=${flow}` : null,
        httpClient.fetcher,
        {
            refreshInterval: 240000,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: true,
        },
    );

    return {
        shippings: deserializeGetAllShippingsGeneric(data),
        isLoading,
        isError: error,
        mutate,
        isValidating,
    };
}

export function useSdoOverview(userId: number) {
    const { data, error, isLoading } = useSWRImmutable(userId > 0 ? `Sdo/GetDefinitiveDataSummary?userId=${userId}` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseRecoveryOverview(data),
        isLoading,
        isError: error,
    };
}

export function useSdoROverview(userId: number) {
    const { data, error, isLoading } = useSWRImmutable(userId > 0 ? `SdoR/GetDefinitiveDataSummary?userId=${userId}` : null, httpClient.fetcher, { revalidateOnMount: true });
    return {
        overview: deserializeUseRecoveryOverview(data),
        isLoading,
        isError: error,
    };
}

export function useRecoveryOverview(userId?: number, flow?: EFlowType) {
    const { data, error, isLoading } = useSWRImmutable(
        !!userId && userId > 0 && !!flow ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=${flow}` : null,
        httpClient.fetcher,
        {
            revalidateOnMount: true,
        },
    );

    return {
        overview: deserializeUseRecoveryOverview(data),
        isLoading,
        isError: error,
    };
}

export function usePatologyOverview(userId?: number, flow?: EFlowType) {
    const { data, error, isLoading } = useSWRImmutable(
        !!userId && userId > 0 && !!flow ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=${flow}` : null,
        httpClient.fetcher,
        {
            revalidateOnMount: true,
        },
    );

    return {
        overview: deserializeUsePatologyOverview(data),
        isLoading,
        isError: error,
    };
}

export function useFimOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=FIM` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseFimOverview(data),
        isLoading,
        isError: error,
    };
}

export function useDmOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=DM` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseDmOverview(data),
        isLoading,
        isError: error,
    };
}

export function useFarmOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=FARM` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseFarmOverview(data),
        isLoading,
        isError: error,
    };
}

export function useFarmospOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=FARMOSP` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseFarmospOverview(data),
        isLoading,
        isError: error,
    };
}

export function useSpsOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=SPS` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseSpsOverview(data),
        isLoading,
        isError: error,
    };
}
export function useDiabOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=DIAB` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseDiabOverview(data),
        isLoading,
        isError: error,
    };
}

export function use118Overview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=118` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUse118Overview(data),
        isLoading,
        isError: error,
    };
}

export function useLaboOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=LABO` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseLaboOverview(data),
        isLoading,
        isError: error,
    };
}

export function useSpaOverview(userId?: number) {
    const { data, error, isLoading } = useSWRImmutable(!!userId && userId > 0 ? `api/ExternalManagedFlow/GetDefinitiveDataSummary?userId=${userId}&flowName=SPA` : null, httpClient.fetcher, {
        revalidateOnMount: true,
    });

    return {
        overview: deserializeUseSpaOverview(data),
        isLoading,
        isError: error,
    };
}
