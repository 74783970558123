import { RightTd, RightTh, TableRow } from '../../style';
import i18n, { ELanguages } from '../../localization';

import { EFlowType } from '../../model/enum';
import ExportExcelRecovery from '../ExportExcel/ExportExcelRecovery';
import { Fragment } from 'react';
import GenericError from '../GenericError/GenericError';
import { IOverviewRecoveryWithPermissions } from '../../model';
import Loading from '../Loading/Loading';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

interface IOverviewTableRecoveryProps {
    overview: IOverviewRecoveryWithPermissions | undefined;
    isLoading: boolean;
    isError: any;
    flow: EFlowType;
}

const OverviewTableRecovery = (props: IOverviewTableRecoveryProps) => {
    const { overview, isError, isLoading, flow } = props;
    const { t } = useTranslation();

    const clms =
        // the condition is set to avoid showing the third coloumn, whose data is not available ofr some of the recovery area flows
        flow !== EFlowType.Sdo && flow !== EFlowType.Sdor
            ? [
                  { name: t('sdoOverview.clmYear'), id: 1 },
                  { name: t('sdoOverview.clmStructureCode'), id: 2 },
                  { name: t('sdoOverview.jan'), id: 4 },
                  { name: t('sdoOverview.feb'), id: 5 },
                  { name: t('sdoOverview.mar'), id: 6 },
                  { name: t('sdoOverview.apr'), id: 7 },
                  { name: t('sdoOverview.may'), id: 8 },
                  { name: t('sdoOverview.jun'), id: 9 },
                  { name: t('sdoOverview.jul'), id: 10 },
                  { name: t('sdoOverview.aug'), id: 11 },
                  { name: t('sdoOverview.sep'), id: 12 },
                  { name: t('sdoOverview.oct'), id: 13 },
                  { name: t('sdoOverview.nov'), id: 14 },
                  { name: t('sdoOverview.dec'), id: 15 },
                  { name: t('sdoOverview.clmTotal'), id: 16 },
              ]
            : [
                  { name: t('sdoOverview.clmYear'), id: 1 },
                  { name: t('sdoOverview.clmStructureCode'), id: 2 },
                  { name: t('sdoOverview.clmNameStructure'), id: 3 },
                  { name: t('sdoOverview.jan'), id: 4 },
                  { name: t('sdoOverview.feb'), id: 5 },
                  { name: t('sdoOverview.mar'), id: 6 },
                  { name: t('sdoOverview.apr'), id: 7 },
                  { name: t('sdoOverview.may'), id: 8 },
                  { name: t('sdoOverview.jun'), id: 9 },
                  { name: t('sdoOverview.jul'), id: 10 },
                  { name: t('sdoOverview.aug'), id: 11 },
                  { name: t('sdoOverview.sep'), id: 12 },
                  { name: t('sdoOverview.oct'), id: 13 },
                  { name: t('sdoOverview.nov'), id: 14 },
                  { name: t('sdoOverview.dec'), id: 15 },
                  { name: t('sdoOverview.clmTotal'), id: 16 },
              ];

    return (
        <Fragment>
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {overview && (
                <div>
                    {overview.permissionSet.canExportOverview ? <ExportExcelRecovery flow={flow} overviewTableRows={overview.overviewTableRows} /> : <></>}
                    <div className='tableFixHead'>
                        <Table size='xl'>
                            <thead className='sticky'>
                                <tr className='sticky'>
                                    {clms.map((item, index) => {
                                        if (index === 0) {
                                            return (
                                                <th key={item.id} className={'table-dark'}>
                                                    {item.name}
                                                </th>
                                            );
                                        } else {
                                            return (
                                                <RightTh key={item.id} className={'table-dark'}>
                                                    {item.name}
                                                </RightTh>
                                            );
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {flow !== EFlowType.Sdo && flow !== EFlowType.Sdor
                                    ? overview.overviewTableRows.map((data, index) => (
                                          <Fragment key={index}>
                                              <TableRow>
                                                  <td>{data.currentYearRow.year}</td>
                                                  <RightTd>{data.currentYearRow.structureCode}</RightTd>
                                                  <RightTd>{data.currentYearRow.jan.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.feb.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.mar.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.apr.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.may.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.jun.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.jul.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.aug.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.sep.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.oct.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.nov.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.dec.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.total.toLocaleString('de-DE')}</RightTd>
                                              </TableRow>
                                              <TableRow>
                                                  <td>{data.lastYearRow.year}</td>
                                                  <RightTd>{data.lastYearRow.structureCode}</RightTd>
                                                  <RightTd>{data.lastYearRow.jan.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.feb.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.mar.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.apr.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.may.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.jun.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.jul.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.aug.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.sep.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.oct.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.nov.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.dec.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.total.toLocaleString('de-DE')}</RightTd>
                                              </TableRow>
                                              <tr style={{ borderBottom: '3px solid black' }}>
                                                  <td>{data.yearsDifferences.year}</td>
                                                  <RightTd>{data.yearsDifferences.structureCode}</RightTd>
                                                  <RightTd>{data.yearsDifferences.jan.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.feb.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.mar.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.apr.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.may.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.jun.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.jul.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.aug.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.sep.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.oct.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.nov.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.dec.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.total}</RightTd>
                                              </tr>
                                          </Fragment>
                                      ))
                                    : overview.overviewTableRows.map((data, index) => (
                                          <Fragment key={index}>
                                              <TableRow>
                                                  <td>{data.currentYearRow.year}</td>
                                                  <RightTd>{data.currentYearRow.structureCode}</RightTd>
                                                  <RightTd>{i18n.language === ELanguages.it ? data.currentYearRow.structureNameIt : data.currentYearRow.structureNameDe}</RightTd>
                                                  <RightTd>{data.currentYearRow.jan.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.feb.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.mar.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.apr.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.may.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.jun.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.jul.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.aug.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.sep.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.oct.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.nov.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.dec.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.currentYearRow.total.toLocaleString('de-DE')}</RightTd>
                                              </TableRow>
                                              <TableRow>
                                                  <td>{data.lastYearRow.year}</td>
                                                  <RightTd>{data.lastYearRow.structureCode}</RightTd>
                                                  <RightTd>{i18n.language === ELanguages.it ? data.lastYearRow.structureNameIt : data.lastYearRow.structureNameDe}</RightTd>
                                                  <RightTd>{data.lastYearRow.jan.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.feb.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.mar.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.apr.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.may.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.jun.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.jul.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.aug.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.sep.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.oct.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.nov.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.dec.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.lastYearRow.total.toLocaleString('de-DE')}</RightTd>
                                              </TableRow>
                                              <tr style={{ borderBottom: '3px solid black' }}>
                                                  <td>{data.yearsDifferences.year}</td>
                                                  <RightTd>{data.yearsDifferences.structureCode}</RightTd>
                                                  <RightTd>{i18n.language === ELanguages.it ? data.yearsDifferences.structureNameIt : data.yearsDifferences.structureNameDe}</RightTd>
                                                  <RightTd>{data.yearsDifferences.jan.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.feb.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.mar.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.apr.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.may.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.jun.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.jul.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.aug.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.sep.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.oct.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.nov.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.dec.toLocaleString('de-DE')}</RightTd>
                                                  <RightTd>{data.yearsDifferences.total}</RightTd>
                                              </tr>
                                          </Fragment>
                                      ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default OverviewTableRecovery;
