import toast, { Renderable, ValueOrFunction } from 'react-hot-toast';

import CustomSvg from '../Svg/CustomSvg';
import { get } from 'lodash';
import i18n from '../../localization/index';
import { t } from 'i18next';

export const AlertSuccess = (msg: string) => {
    return toast.success(msg, { style: { backgroundColor: '#CAFFC4' } });
};
export const AlertError = (msg: string) => {
    return toast.error(msg, { style: { backgroundColor: '#FFCCDB' } });
};
export const AlertWarning = (msg: string) => {
    return toast(msg, { icon: <CustomSvg iconName=' it-warning-circle' />, style: { backgroundColor: '#FFE2B7' } });
};
export const AlertPromise = (msg: string, promise: Promise<unknown>, success: ValueOrFunction<Renderable, unknown>, error: ValueOrFunction<Renderable, unknown>, withNavigate?: any) => {
    return toast.promise(promise, {
        loading: msg ? msg : 'Loading...',
        success: withNavigate
            ? data => {
                  withNavigate();
                  return i18n.t('generic.success');
              }
            : success,
        error: err => {
            const message = get(err, 'response.data', '');
            const status = get(err, 'response.status', 0);
            if (!!message && message.length > 0 && message === 'User has no rights to upload these institutes' && status === 401) {
                return t('structures.userHasNoRights');
            }
            const response = !!message && message.length > 0 ? message : 'Error';
            return response;
        },
    });
};
