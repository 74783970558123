import ActionTable from '../../componets/CustomTable/ActionTable';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Container } from '../../style';
import GenericError from '../../componets/GenericError/GenericError';
import Loading from '../../componets/Loading/Loading';
import { route } from '../../route';
import { useFlows } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

interface IFlowsProps {}
const Flows = (props: IFlowsProps) => {
    const { t } = useTranslation();

    const { flows, isLoading, isError } = useFlows();

    return (
        <Container>
            <BreadCrumb paths={[{ nameToShow: t('routes.flows'), url: route.flows }]} />
            {isLoading && <Loading />}
            {isError && <GenericError />}
            {!isLoading && flows.length > 0 && <ActionTable header={t('flows.tableName')} rows={flows} withEye />}
        </Container>
    );
};

export default Flows;
